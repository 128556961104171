import React, { useState } from 'react'
import { Backdrop, Modal, Fade, Typography, Button, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import Store from '../../../Store/Store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/system'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '709px',
    // height: '210px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const RequestRepairModal = observer(({ open, handleClose, handleRequestRepair, loading }) => {
  const [comment, setComment] = useState('')
  const classes = useStyles()
  const { t } = useTranslation()
  const commentLimit = 500

  const handleChange = event => {
    setComment(event.target.value)
  }

  const handleAddComment = comment => {
    // Add API call for adding a comment on requested repair
    Store.testingDataStore.setRepairComment(comment)
    handleRequestRepair()
    setComment('')
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={(event, reason) => handleClose(event, reason)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <Stack p={2} pt={0}>
              <Typography id="transition-modal-title" variant="h4" align="center" style={{ color: '#6CB86A' }} pb={2}>
                {t('RequestRepair')}
              </Typography>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" width="100%" p={2}>
                <Typography variant="subtitle1">{t('Comments')}</Typography>
                <TextField
                  multiline
                  rows={6}
                  sx={{ width: '80%' }}
                  label={t('Comments')}
                  value={comment}
                  onChange={event => handleChange(event)}
                  inputProps={{ maxLength: commentLimit }}
                  helperText={`${comment.length}/${commentLimit}`}
                />
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center" p={2}>
                <Button onClick={handleClose} variant="contained" color="greyGreen">
                  {t('Cancel')}
                </Button>
                <LoadingButton variant="contained" onClick={() => handleAddComment(comment)} loading={loading}>
                  {t('Sendrequest')}
                </LoadingButton>
              </Stack>
            </Stack>
          </div>
        </Fade>
      </Modal>
    </div>
  )
})

export default RequestRepairModal
