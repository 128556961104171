import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class OnboardingDataStore {
  // Active step - needed for reloading the page
  activeStep = 0
  contentSize = 'sm'
  isBlocked = false

  // First step robot info data
  test = ''
  selectedTest = {
    id: '',
    key: '',
    summary: '',
    status: {},
  }

  // Helper variables for checking if user changed test or robot
  selectedTestHelper = ''
  selectedRobotHelper = ''

  selectedRobot = {
    id: '',
    key: '',
    summary: '',
    status: {},
  }

  robotData = {
    BLID: '',
    CreatedDate: '',
    DisplayName: '',
    DisplayOrder: 0,
    FK_RobotModel: 0,
    FK_User: 0,
    LastModifiedDate: '',
    ModelName: '',
    Notes: '',
    Onboarded: false,
    PK_Robot: 1,
    RobotName: '',
    RobotSN: '',
  }

  // Second step
  bitFilePath = ''
  bitFileComments = ''

  // Third step
  component = ''
  subcomponent = ''
  supplier = ''
  componentsData = []
  subcomponentsData = {}
  suppliersData = []
  onboardedData = []

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'OnboardingDataStore',
      properties: [
        'activeStep',
        'contentSize',
        'isBlocked',
        'test',
        'selectedTest',
        'selectedRobot',
        'robotData',
        'selectedTestHelper',
        'selectedRobotHelper',
        'bitFilePath',
        'bitFileComments',
        'component',
        'componentsData',
        'subcomponentsData',
        'suppliersData',
        'onboardedData',
      ],
      storage: window.localStorage,
    })
  }

  // action
  // Active step - position
  setActiveStep = step => {
    this.activeStep = step
  }
  setContentSize = size => {
    this.contentSize = size
  }
  setIsBlocked = value => {
    this.isBlocked = value
  }

  // First step
  setRobotInfoTest = testData => {
    this.test = testData
  }
  setRobotInfoSelectedTest = testData => {
    this.selectedTest = testData
  }
  setRobotInfoSelectedRobot = robotData => {
    this.selectedRobot = robotData
  }
  setRobotInfoData = robotData => {
    this.robotData = robotData
  }
  setRobotBlid = blid => {
    this.robotData[0].BLID = blid
  }

  setRobotSN = sn => {
    this.robotData[0].RobotSN = sn
  }

  setRobotName = name => {
    this.robotData[0].RobotName = name
  }

  setTestHelper = test => {
    this.selectedTestHelper = test
  }

  setRobotHelper = robot => {
    this.selectedRobotHelper = robot
  }

  // Second step
  setBitFile = bitfile => {
    this.bitFilePath = bitfile
  }
  setComments = comments => {
    this.bitFileComments = comments
  }

  // Third step
  setComponent = component => {
    this.component = component
  }
  setSubcomponent = subcomponent => {
    this.subcomponent = subcomponent
  }
  setSupplier = supplier => {
    this.supplier = supplier
  }
  setSuppliersData = suppliersData => {
    this.suppliersData = suppliersData
  }
  setComponentsData = componentsData => {
    this.componentsData = componentsData
  }
  setSubcomponentData = (component, subcomponentData) => {
    this.subcomponentsData[component] = subcomponentData
  }
  setOnboardedData = data => {
    this.onboardedData = data
  }

  // Clear persisted store
  clearPersistedStore = () => {
    this.activeStep = 0
    this.contentSize = 'sm'
    this.isBlocked = false

    // First step
    this.test = ''
    this.selectedTest = {
      id: '',
      key: '',
      summary: '',
      status: {},
    }
    this.selectedRobot = {
      id: '',
      key: '',
      summary: '',
      status: {},
    }
    this.selectedTestHelper = ''
    this.selectedRobotHelper = ''
    this.robotData = [
      {
        BLID: '',
        CreatedDate: '',
        DisplayName: '',
        DisplayOrder: 0,
        FK_RobotModel: 0,
        FK_User: 0,
        LastModifiedDate: '',
        ModelName: '',
        Notes: '',
        Onboarded: false,
        PK_Robot: 99999999,
        RobotName: '',
        RobotSN: '',
      },
    ]
    this.selectedTest = {}
    // Second step
    this.bitFilePath = ''
    this.bitFileComments = ''

    // Third step
    this.component = ''
    this.subcomponent = ''
    this.supplier = ''
    this.componentsData = []
    this.subcomponentsData = {}
    this.suppliersData = []
    this.onboardedData = []

    clearPersistedStore(this)
  }
}

export default OnboardingDataStore
