// Create unique tracktable because both robot and testbed track table are very similar
import React, { useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Button,
} from '@mui/material'
// import { useHistory } from 'react-router-dom'
//import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { MyRepairsData } from '../../../../Store/MOCK_DATA'
import Store from '../../../../Store/Store'
import './Repairs.css'

const MyRepairsTable = props => {
  // const MyRepairsTable = observer(() => {
  // const history = useHistory()
  const [page, setPage] = useState(0)
  // const [rows, setRows] = useState(MyRepairsData)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const { t } = useTranslation()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleRepair = robotName => {
    props.onRepair(robotName)
  }

  // MOCK DATA
  let rows = MyRepairsData

  const columns = [
    { id: 'robot_name', label: 'Robot Name', minWidth: 170 },
    { id: 'error_name', label: 'Error Name', minWidth: 170 },
    {
      id: 'date_reported',
      label: 'Date Reported',
      minWidth: 170,
    },
  ]

  const dateFormater = cell => {
    // const date = new Date(cell);
    // console.log(JSON.stringify(date));
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleDateString()

    return formatedDate
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  return (
    <Box>
      <Box sx={{ p: '10px 20px' }}>
        <div className="repair-detail-row">
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
            {t('Technicianname')}:
          </Typography>
          <Typography variant="subtitle2">{Store.accountInfo.currentUser.name}</Typography>
        </div>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    sortDirection={orderBy === column.id ? order : false}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.robot_name} sx={{ cursor: 'pointer' }}>
                      {columns.map(column => {
                        let value = row[column.id]
                        if (column.id === 'date_reported') {
                          value = dateFormater(value)
                        }
                        return <TableCell key={column.id}>{value}</TableCell>
                      })}
                      <TableCell>
                        <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                          <Button
                            variant="contained"
                            onClick={() => handleRepair(row.robot_name)}
                            color="alertPurple"
                            sx={{ mr: '12px', width: '84px' }}
                          >
                            {t('Repair')}
                          </Button>
                          <Button variant="contained" color="greyGreen" sx={{ width: '84px' }}>
                            {t('Bit')}
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  )
  // })
}

export default MyRepairsTable
