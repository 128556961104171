import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class RobotStatusStore {
  workstationID = ''
  /*
  robotStatusObjects is a collection of the robot statuses 
  associated with this wkstation, 
  When populated, should look something like this:
  {
    "absasdjkf123":
      {
        actual data from the robot
      },
    "whasnjs4231":
      {
        actual data from another robot
      }, etc
  }
  */
  robotStatusObjects = {}

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'RobotStatusStore',
      properties: ['workstationID', 'robotStatusObjects'],
      storage: window.localStorage,
    })
  }

  setWorkstationID = id => {
    this.workstationID = id
  }

  setRobotStatusObject = (blid, status_obj) => {
    this.robotStatusObjects[blid] = status_obj
  }

  setRobotStatusObjectStats = (blid, status_obj) => {
    this.robotStatusObjects[blid].bbchg = status_obj.bbchg;
    this.robotStatusObjects[blid].bbchg3 = status_obj.bbchg3;
    this.robotStatusObjects[blid].bbmssn = status_obj.bbmssn;
    this.robotStatusObjects[blid].bbrun = status_obj.bbrun;
    this.robotStatusObjects[blid].bbsys = status_obj.bbsys;
  }

  getRobotStatusObject = blid => {
    return this.robotStatusObjects[blid]
  }

  removeRobotStatusObject = blid => {
    if (this.robotStatusObjects[blid]) {
      delete this.robotStatusObjects.blid
    }
  }

  clearRobotStatusStore = () => {
    this.workstationID = ''
    this.robotStatusObjects = {}

    clearPersistedStore(this)
  }
}

export default RobotStatusStore
