import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'
import { useSnackbar } from 'notistack'

const BatteryOnboardSuccess = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }
  const idToken = authState.idToken.idToken

  const [fetching, setFetching] = useState(false)
  const pk_battery = Store.batteryDataStore.pk_battery
  const [batteryInfo, setBatteryInfo] = useState({})

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      const res = await client
        .get(`/battery/${pk_battery}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        })
      setBatteryInfo(res.data[0])
      setFetching(false)
    }
    getData()
  }, [idToken, pk_battery, enqueueSnackbar, t])

  const handleAnother = () => {
    history.push('/app/onboarding/battery')
  }

  const handleDone = () => {
    history.push('/app')
  }

  const content = () => {
    return fetching ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size={30} sx={{ m: '2em' }} />
      </Box>
    ) : (
      <Grid container direction="column" sx={{ p: '45px', m: '10px' }}>
        <Typography variant="modal1" align="center" gutterBottom>
          {t('BatteryOnboardSucces')}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px', marginTop: '26px' }}>
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('Serialnumber')}
          </Typography>
          <Typography variant="modal2" component="div">
            {batteryInfo.BatterySN ? batteryInfo.BatterySN : ''}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px' }}>
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('Variant')}
          </Typography>
          <Typography variant="modal2" component="div">
            {batteryInfo.Batteryvariant ? batteryInfo.Batteryvariant[0].DisplayName : ''}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '9px 35px' }}>
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('Supplier')}
          </Typography>
          <Typography variant="modal2" component="div">
            {batteryInfo.Supplier ? batteryInfo.Supplier[0].DisplayName : ''}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '29px' }}>
          <Button variant="contained" color="greyGreen" sx={{ margin: '0px 4px' }} onClick={handleAnother}>
            {t('OnboardAnother')}
          </Button>
          <Button variant="contained" sx={{ margin: '0px 4px' }} onClick={handleDone}>
            {t('Done')}
          </Button>
        </div>
      </Grid>
    )
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('Success')}
      </Typography>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Paper>{content()}</Paper>
      </Container>
    </Box>
  )
}

export default BatteryOnboardSuccess
