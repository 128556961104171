import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import client from '../../../Config/axiosClient'
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import './WorkstationSelection.css'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/system'

const WorkstationSelection = observer(() => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [workstationList, setWorkstationList] = useState([])
  const [fetchingWkst, setFetchingWkst] = useState(false)
  const [workstation, setWorkstation] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  // Workstation select menu
  useEffect(() => {
    const getData = async () => {
      setFetchingWkst(true)
      const res = await client
        .get('/workstation/', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingWkst(false)
        })
      setWorkstationList(res.data)
      if (Store.testingDataStore.workstationID) {
        // Default selection is the previously-selected workstation
        setWorkstation(res.data.find(o => o.PK_Workstation === Store.testingDataStore.workstationID))
      }
      setFetchingWkst(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleWorkstationChange = e => {
    setWorkstation(e.target.value)
  }

  // Submit button
  useEffect(() => {
    if (workstation) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [workstation])

  function onSubmitClick() {
    setSubmitLoading(true)
    // Save everything to the store
    Store.testingDataStore.setWorkstationID(workstation.PK_Workstation)
    Store.testingDataStore.setWorkstationName(workstation.Name)
    Store.testingDataStore.setWorkstationDisplayName(workstation.DisplayName)
    setSubmitLoading(false)
    history.push('/app/testing/workstation')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('SelectWorkstation')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4} pt={4.5}>
            <FormControl fullWidth required={fetchingWkst ? false : true}>
              {fetchingWkst ? (
                <InputLabel id="workstation-label">
                  <CircularProgress size={25} />
                </InputLabel>
              ) : (
                <InputLabel id="workstation-label">{t('Workstation')}</InputLabel>
              )}
              <Select
                id="workstation-select"
                label={t('Workstation')}
                value={workstation}
                onChange={handleWorkstationChange}
                disabled={fetchingWkst}
              >
                {workstationList.map(wkst => {
                  return (
                    <MenuItem key={`workstation_${wkst.PK_Workstation}`} value={wkst}>
                      {wkst.DisplayName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <LoadingButton
                variant="contained"
                onClick={onSubmitClick}
                disabled={submitDisabled}
                loading={submitLoading}
              >
                {t('Go')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
})

export default WorkstationSelection
