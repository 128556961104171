import { makeAutoObservable } from 'mobx'

class RowDataStore {
  // observables
  rowData = [
    {
      location: '',
      code: '',
      status: '',
      rut: '',
      testbed_id: '',
    },
  ]

  constructor() {
    makeAutoObservable(this)
    // makePersistable(this, { name: 'RowDataStore', properties: ['rowData'], storage: window.localStorage })
  }

  // action
  setRowData = rowData => {
    this.rowData = rowData
  }

  resetRowData = () => {
    this.setRowData([])
  }

  // actions change observables!
}

export default RowDataStore
