import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  FormControl,
  TextField,
  Button,
  InputLabel,
  CircularProgress,
  Select,
  MenuItem,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'
import { useSnackbar } from 'notistack'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/system'

const AddBattery = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }
  const idToken = authState.idToken.idToken

  const [serial, setSerial] = useState('')

  const user = Store.accountInfo.userPK
  const [submitLoading, setSubmitLoading] = useState(false)

  const [variantList, setVariantList] = useState([])
  const [fetchingVariant, setFetchingVariant] = useState(false)
  const [variant, setVariant] = useState('')

  const [supplierList, setSupplierList] = useState([])
  const [fetchingSupplier, setFetchingSupplier] = useState(false)
  const [supplier, setSupplier] = useState('')

  const [error, setError] = useState({
    serialError: false,
    variantError: false,
    supplierError: false,
  })

  const [submitDisabled, setSubmitDisabled] = useState(true)

  // const handleTypeChange = event => {
  //   setType(event.target.value)
  // }

  // const handleVariantChange = event => {
  //   setVariant(event.target.value)
  // }

  // Serial number
  const handleSerial = event => {
    setSerial(event.target.value)
  }

  // Battery variant
  useEffect(() => {
    const getData = async () => {
      setFetchingVariant(true)
      const res = await client
        .get('/battery/variant', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingVariant(false)
        })
      setVariantList(res.data)
      setFetchingVariant(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleVariant = event => {
    setVariant(event.target.value)
  }

  // Supplier
  useEffect(() => {
    const getData = async () => {
      setFetchingSupplier(true)
      const res = await client
        .get('/supplier', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingSupplier(false)
        })
      setSupplierList(res.data)
      setFetchingSupplier(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleSupplier = event => {
    setSupplier(event.target.value)
  }

  // Error handling
  const handleError = (event, from) => {
    if (from === 'serial-text') {
      const errorType = 'serialError'
      checkValidation(event, errorType)
    } else if (from === 'variant-select') {
      const errorType = 'variantError'
      checkValidation(event, errorType)
    } else if (from === 'supplier-select') {
      const errorType = 'supplierError'
      checkValidation(event, errorType)
    }
  }

  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  // Cancel Button
  const handleCancel = () => {
    history.push('/')
  }

  // Submit Button
  const clearForm = () => {
    setSerial('')
    setVariant('')
    setSupplier('')
  }

  const serialExists = async () => {
    setSubmitLoading(true)
    const res = await client
      .get(`/battery/serial/${serial}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setSubmitLoading(false)
        enqueueSnackbar(t('SerialLookupError'), { variant: 'error' })
      })
    if (res?.status === 200) {
      setSubmitLoading(false)
      if (res.data.length > 0) {
        enqueueSnackbar(t('SerialLookupFound'), { variant: 'error' })
        setError({ ...error, serialError: true })
        return
      } else {
        setSubmitLoading(false)
        enqueueSnackbar(t('SerialLookupNotFound'), { variant: 'success' })
        create_battery()
      }
    }
  }

  const create_battery = async () => {
    setSubmitLoading(true)
    let body = {
      battery_sn: serial,
      fk_battery_variant: variant,
      fk_supplier: supplier,
      // voltage: 14.8,
      // current: 120,
      // capacity: 2130,
      brad_status: 1,
      fk_user: user,
      display_order: 0,
    }
    const res = await client
      .post(`/battery`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setSubmitLoading(false)
        enqueueSnackbar(t('BatteryCreateError'), { variant: 'error' })
      })
    if (res?.status === 200) {
      setSubmitLoading(false)
      enqueueSnackbar(t('BatteryCreated'), { variant: 'success' })
      Store.batteryDataStore.setPK_Battery(res.data.generatedFields[0].longValue)
      clearForm()
      history.push(window.location.pathname + '/success')
    }
  }

  useEffect(() => {
    if (serial && variant && supplier) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [serial, variant, supplier])

  const handleSubmit = () => {
    serialExists()
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('OnboardABattery')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{t('BatterySN')}</Typography>
              <TextField
                required
                id="outlined"
                label="Enter Serial Number"
                value={serial}
                onChange={event => handleSerial(event)}
                error={error.serialError}
                onBlur={event => handleError(event, 'serial-text')}
              />

              <Typography variant="subtitle1">{t('BatteryVariant')}</Typography>
              <FormControl
                fullWidth
                required={fetchingVariant ? false : true}
                error={error.variantError}
                onBlur={event => handleError(event, 'variant-select')}
              >
                {fetchingVariant ? (
                  <InputLabel id="variant-label">
                    <CircularProgress size={25} />
                  </InputLabel>
                ) : (
                  <InputLabel id="variant-label">{t('BatteryVariant')}</InputLabel>
                )}
                <Select
                  id="variant-select"
                  value={variant}
                  label={t('BatteryVariant')}
                  onChange={handleVariant}
                  // onClose=""
                  disabled={fetchingVariant}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200, maxWidth: '320px' } } }}
                >
                  {variantList.map(variant => {
                    return (
                      <MenuItem key={variant.Name} value={variant.PK_Batteryvariant}>
                        {variant.DisplayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Typography variant="subtitle1">{t('Supplier')}</Typography>
              <FormControl
                fullWidth
                required={fetchingSupplier ? false : true}
                error={error.supplierError}
                onBlur={event => handleError(event, 'supplier-select')}
              >
                {fetchingSupplier ? (
                  <InputLabel id="supplier-label">
                    <CircularProgress size={25} />
                  </InputLabel>
                ) : (
                  <InputLabel id="supplier-label">{t('Supplier')}</InputLabel>
                )}
                <Select
                  id="supplier-select"
                  value={supplier}
                  label={t('Supplier')}
                  onChange={handleSupplier}
                  disabled={fetchingSupplier}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200, maxWidth: '320px' } } }}
                >
                  {supplierList.map(supplier => {
                    return (
                      <MenuItem key={supplier.Name} value={supplier.PK_Supplier}>
                        {supplier.DisplayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" color="greyGreen" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                disabled={submitDisabled}
                loading={submitLoading}
                variant="contained"
              >
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
}

export default AddBattery
