// Create unique tracktable because both robot and testbed track table are very similar
import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import client from '../../../../Config/axiosClient'
import Store from '../../../../Store/Store'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

// import { ActivityHistoryData } from '../../../../Store/MOCK_DATA'
import CustomSearch from '../../../Search/Search'
import './ActivityHistory.css'

const ActivityHistory = observer(() => {
  const { authState } = useOktaAuth()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    Test: false,
    Robot: false,
    Battery: false,
    'Test Bed': false,
    Cleaning: false,
    Repairs: false,
  })
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [loading, setLoading] = useState(false)
  const [activityHistory, setActivityHistory] = useState(
    Store.userProfileStore.activityHistoryData ? Store.userProfileStore.activityHistoryData : []
  )
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const user = Store.accountInfo.userPK

  useEffect(() => {
    const getActivityData = async () => {
      const res = await client.get(`/user/activity/${user}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      Store.userProfileStore.setActivityHistoryData(res.data)
      if (Store.userProfileStore.activityHistoryData) {
        setActivityHistory(Store.userProfileStore.activityHistoryData)
      }
      setActivityHistory(res.data)
      setLoading(false)
    }

    if (idToken) {
      setLoading(true)
      getActivityData()
    }
  }, [idToken, user])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // MOCK DATA
  // let rows = ActivityHistoryData
  let rows = activityHistory

  const columns = [
    { id: 'type', label: 'Type', minWidth: 170 },
    { id: 'action', label: 'Description', minWidth: 170 },
    {
      id: 'date',
      label: t('DateTime'),
      minWidth: 170,
    },
  ]

  const onFilterChanged = name => event => {
    setFilters({ ...filters, [name]: event.target.checked })
  }

  const filterArray = Object.keys(filters)
  const filteredRows = rows.filter(row => {
    const filterKeyTrue = filterArray.some(filterKey => {
      return filterKey === row.type && filters[filterKey] === true
    })
    if (filterKeyTrue) {
      return row
    } else {
      return null
    }
  })

  if (filteredRows.length > 0) {
    rows = filteredRows
  }

  function handleSearchUpdate(value) {
    setSearch(value)
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const dateFormater = cell => {
    // const date = new Date(cell);
    // console.log(JSON.stringify(date));
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatedDate
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', mb: '8px' }}>
        <Grid container direction="row" columnSpacing={3} alignItems="center" sx={{ ml: '0px', mt: '3px' }}>
          <Grid item sx={{ mr: 3 }}>
            <Typography variant="modal2">{t('Filterby')}: </Typography>
          </Grid>
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox checked={filters['Test']} onChange={onFilterChanged('Test')} value="Test" />}
                label={t('Test')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={<Checkbox checked={filters['Robot']} onChange={onFilterChanged('Robot')} value="Robot" />}
                label={t('Robot')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox checked={filters['Battery']} onChange={onFilterChanged('Battery')} value="Battery" />
                }
                label={t('Battery')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox checked={filters['Test Bed']} onChange={onFilterChanged('Test Bed')} value="Test Bed" />
                }
                label={t('Testbed')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox checked={filters['Cleaning']} onChange={onFilterChanged('Cleaning')} value="Cleaning" />
                }
                label={t('Cleaning')}
                labelPlacement="end"
              />
              <FormControlLabel
                value="end"
                control={
                  <Checkbox checked={filters['Repairs']} onChange={onFilterChanged('Repairs')} value="Repairs" />
                }
                label={t('Repairs')}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <div style={{ width: '370px', position: 'relative' }}>
          {loading && <CircularProgress size={24} sx={{ position: 'absolute', left: '-32px', top: '12px' }} />}
          <CustomSearch search={search} handleSearchUpdate={handleSearchUpdate} />
        </div>
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden', mb: '18px' }}>
        <TableContainer sx={{ maxHeight: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    sortDirection={orderBy === column.id ? order : false}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter(row => {
                  if (search === '') {
                    return row
                  }
                  return (
                    row.type.toLowerCase().includes(search.toLowerCase()) ||
                    row.action.toLowerCase().includes(search.toLowerCase()) ||
                    dateFormater(row.date).includes(search.toLowerCase()) ||
                    row.object.toLowerCase().includes(search.toLowerCase())
                  )
                })
                .map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        let value = row[column.id]

                        let id_name = ''
                        if (column.id === 'action') {
                          id_name = row['object']
                        }

                        if (column.id === 'date') {
                          value = dateFormater(value)
                        }

                        return (
                          <TableCell key={column.id}>
                            <span>{value}</span>
                            {id_name && (
                              <>
                                <span> - </span>
                                <span className="id_name"> {id_name}</span>
                              </>
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
})

export default ActivityHistory
