import { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Button, TextField, Stack, styled } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import './SwapBattery.css'
import BitFileError from '../Modal/BitFileError'
import client from '../../../Config/axiosClient'
import { useSnackbar } from 'notistack'
import Store from '../../../Store/Store'

const InputButton = styled('input')({
  display: 'none',
})

const UploadBiTResults = () => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [robot, setRobot] = useState('')
  const [fileName, setFileName] = useState(t('Filename'))
  const [uploadFile, setUploadFile] = useState([])
  const [comments, setComments] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [failLoading, setFailLoading] = useState(false)
  const [passLoading, setPassLoading] = useState(false)
  const [error, setError] = useState({
    robotError: false,
    bitError: false,
  })
  const [openBitError, setOpenBitError] = useState(false)
  const [status, setStatus] = useState(0)

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const userPK = Store.accountInfo.userPK

  // Error handling
  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  const handleError = (event, from) => {
    if (from === 'robot-text' || from === 'getRobotPK') {
      const errorType = 'robotError'
      checkValidation(event, errorType)
    }
    if (from === 'bitUpload') {
      const errorType = 'bitError'
      checkValidation(event, errorType)
    }
  }

  // Input fields
  const handleRobotChange = value => {
    setRobot(value)
  }

  const handleFileChange = event => {
    if (event.target.value !== '' && event.target.files) {
      setUploadFile(event.target.files[0])
      setFileName(event.target.files[0].name)
    }
  }

  const handleCommentsChange = event => {
    setComments(event.target.value)
  }

  // Enable/disable buttons
  useEffect(() => {
    if (robot && fileName) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [robot, fileName])

  // API calls
  const getRobotPK = async robotSN => {
    const res = await client
      .get(`/robot/sn2pk/${robotSN}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotPK')
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        // RobotSN not in database
        enqueueSnackbar(t('RobotNotFound'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotPK')
        throw new Error(`RobotSN ${robotSN} not found in database`)
      }
      if (res.data.length === 1) {
        // Only one robot with RobotSN
        return res.data[0].Robot.PK_Robot
      }
      if (res.data.length > 1) {
        // Multiple robots with RobotSN
        enqueueSnackbar(t('RobotNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotPK')
        throw new Error(`Multiple entries for RobotSN ${robotSN}`)
      }
    } else {
      // shouldn't get here
      enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
      throw new Error('Bad response from get_testbed')
    }
  }

  const getRobotBlid = async robotSN => {
    const res = await client
      .get(`/robot/sn2rid/${robotSN}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotBlid')
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        // RobotSN not in database
        enqueueSnackbar(t('RobotNotFound'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotBlid')
        throw new Error(`RobotSN ${robotSN} not found in database`)
      }
      if (res.data) {
        // Only one robot with RobotSN
        return res.data.RobotID
      }
      if (res.data.length > 1) {
        // Multiple robots with RobotSN
        enqueueSnackbar(t('RobotNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotBlid')
        throw new Error(`Multiple entries for RobotSN ${robotSN}`)
      }
    } else {
      // shouldn't get here
      enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
      throw new Error('Bad response from get_testbed')
    }
  }

  const handleUploadFile = async (passorfail, robotPK, blid) => {
    let formData = new FormData()
    formData.append('upload_file', uploadFile)
    formData.append('fk_robot', robotPK)
    formData.append('blid', blid)
    formData.append('userPK', userPK)
    formData.append('filename', fileName)
    formData.append('passorfail', passorfail)
    formData.append('comments', comments)
    const res = await client({
      method: 'post',
      url: `/bitfile`,
      data: formData,
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'multipart/form-data',
      },
    }).catch(error => {
      setStatus(error.request.status)
      setOpenBitError(true)
      const event = { target: { value: '' } }
      handleError(event, 'bitUpload')
      throw new Error(error)
    })
    return res
  }

  const handleReportError = () => {
    // Error type can be 'robot', 'battery', or 'testbed'
    // Only using 'robot' for now
    const errorType = 'robot'
    Store.reportData.setErrorType(errorType)
    // let row = []
    // errorData.map(error => {
    //   if (error.type === errorType) {
    //     row = {
    //       title: `${error.title}`,
    //       description: `${error.description}`,
    //     }
    //     Store.reportData.setReportData(row)
    //   }
    // })

    history.push('/app/testing/error', { from: '/app/testing/workstation' })
  }

  const goBack = () => {
    history.push(history.location.state.from, { from: 'bit' })
  }

  // Cancel button
  const handleCancel = () => {
    goBack()
  }

  // Fail button
  const handleFail = async () => {
    try {
      setFailLoading(true)
      const robotPK = await getRobotPK(robot)
      await handleUploadFile('fail', robotPK)
      enqueueSnackbar(t('BitSaveSuccess'), { variant: 'success' })
      setFailLoading(false)
      handleReportError()
    } catch (error) {
      console.log(error)
      setFailLoading(false)
    }
  }

  // Pass button
  const handlePass = async () => {
    try {
      setPassLoading(true)
      const robotPK = await getRobotPK(robot)
      const blid = await getRobotBlid(robot)
      await handleUploadFile('pass', robotPK, blid)
      enqueueSnackbar(t('BitSaveSuccess'), { variant: 'success' })
      setPassLoading(false)
      goBack()
    } catch (error) {
      console.log(error)
      setPassLoading(false)
    }
  }

  // Bit Error Modal
  const handleClose = () => {
    setOpenBitError(false)
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('UploadBiTResults')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box
              display="grid"
              gridTemplateColumns="max-content 1fr"
              columnGap={2}
              rowGap={1}
              width="100%"
              alignItems="center"
            >
              <Typography variant="subtitle1">{t('RobotSN')}</Typography>
              <TextField
                autoFocus
                required
                id="robot-text"
                label={t('RobotSN')}
                value={robot}
                onChange={e => handleRobotChange(e.target.value)}
                error={error.robotError}
                onBlur={event => handleError(event, 'robot-text')}
              />

              <Typography variant="subtitle1">{t('BiTResults')}</Typography>
              <Stack direction="row" spacing={0} alignItems="center">
                <label htmlFor="contained-button-file">
                  <InputButton
                    // BitFile is expected to be .7z only, others included just in case
                    // accept=".7z, .zip, .rar, .tar, .wim, .gzip, .bzip"
                    accept="text/*" // using text/* for testing purposes
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button variant="contained" component="span" color="greyGreen">
                    {t('Browse')}
                  </Button>
                </label>
                <TextField
                  id="bit-file"
                  value={fileName}
                  // label={t('BiTResults')}
                  variant="outlined"
                  sx={{ flexGrow: '1', '& fieldset': { border: 'none' } }}
                  error={error.bitError}
                  disabled
                />
              </Stack>

              <Typography variant="subtitle1">{t('Comments')}</Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={3}
                label={t('Comments')}
                value={comments}
                sx={{ minWidth: '75%' }}
                onChange={handleCommentsChange}
              />
            </Box>
            {/* </Stack> */}

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" color="greyGreen" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                color="alertPurple"
                onClick={handleFail}
                disabled={submitDisabled}
                loading={failLoading}
              >
                {t('Fail')}
              </LoadingButton>
              <LoadingButton variant="contained" onClick={handlePass} disabled={submitDisabled} loading={passLoading}>
                {t('Pass')}
              </LoadingButton>
            </Stack>
          </Stack>
          <BitFileError open={openBitError} handleClose={handleClose} status={status} />
        </Paper>
      </Container>
    </Box>
  )
}

export default UploadBiTResults
