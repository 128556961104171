import { useState } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { globalStatus } from '../../../../Constants/global'

const RobotStatus = observer(props => {
  const { t } = useTranslation()
  const rows = props.rows
  const fetching = props.fetching
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('priority')

  const columns = [
    { id: 'status', label: t('Status'), minWidth: 'max-content', align: 'left' },
    { id: 'priority', label: t('Priority'), minWidth: 'max-content', align: 'center' },
    { id: 'createdDate', label: t('CreatedDate'), minWidth: 'max-content', align: 'left' },
    { id: 'createdBy', label: t('CreatedBy'), minWidth: 'max-content', align: 'left' },
  ]

  const getStatusColor = value => {
    if (globalStatus.warning.values.robot.includes(value)) {
      return globalStatus.warning.themeColor
    }
    if (globalStatus.caution.values.robot.includes(value)) {
      return globalStatus.caution.themeColor
    }
    if (globalStatus.info.values.robot.includes(value)) {
      return globalStatus.info.themeColor
    }
    if (globalStatus.success.values.robot.includes(value)) {
      return globalStatus.success.themeColor
    }
    return 'black'
  }

  const setTextBold = column => {
    if (column === 'status') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const dateFormater = cell => {
    // console.log(JSON.stringify(date));
    if (!cell) {
      return null
    }
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatedDate
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows.sort(getComparator(order, orderBy)).map((row, index) => {
    let statusColor = ''
    let boldText = ''
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={`active-status-${index}`}>
        {columns.map(column => {
          let value = row[column.id]
          if (column.id === 'createdDate') {
            value = dateFormater(value)
          }
          statusColor = getStatusColor(value)
          boldText = setTextBold(column.id)
          return (
            <TableCell key={column.id} align={column.align} sx={{ color: `${statusColor}`, fontWeight: `${boldText}` }}>
              {t(value)}
            </TableCell>
          )
        })}
      </TableRow>
    )
  })

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                sortDirection={orderBy === column.id ? order : false}
                key={`active-status-${column.id}`}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
      </Table>
    </TableContainer>
  )
})

export default RobotStatus
