import { createTheme } from '@mui/material'

export default createTheme({
  palette: {
    background: {
      default: '#F5F5F5',
    },
    primary: {
      main: '#6CB86A',
      contrastText: '#fff',
    },
    error: {
      main: '#ef5350',
      contrastText: '#fff',
    },
    warning: {
      main: '#ff9800',
      contrastText: '#fff',
    },
    info: {
      main: '#03a9f4',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
      contrastText: '#fff',
    },
    navbarGreen: {
      main: '#4caf50',
      contrastText: '#fff',
    },
    boxCenter: {
      justifyContent: 'center',
    },
    greyGreen: {
      main: '#87A28E',
      contrastText: '#fff',
    },
    alertPurple: {
      main: '#AE72A3',
      contrastText: '#fff',
    },
    lightPurple: {
      main: '#EDE0EB',
      contrastText: '#000000',
    },
    lightOrange: {
      main: '#F9ECD4',
      contrastText: '#000000',
    },
    lightGreen: {
      main: '#ECF6EC',
      contrastText: '#000000',
    },
    switchBlue: {
      main: '#6A768C',
      contrastText: '#fff',
    },
  },
  typography: {
    modal1: {
      font: 'normal normal 900 16px/24px Roboto',
      letterSpacing: '0.15px',
      color: '#000000BC',
      // fontSize: '16px',
      // fontWeight: 'bold'
    },
    modal2: {
      font: 'normal normal normal 16px/24px Roboto',
      letterSpacing: '0.15px',
      color: '#000000BC',
    },
    modal3: {
      font: 'normal normal normal 30px/24px Roboto',
      letterSpacing: '0.23px',
      color: '#6CB86A',
    },
    modal4: {
      font: 'normal normal normal 30px/24px Roboto',
      letterSpacing: '0.23px',
      color: '#212121',
    },
    modal5: {
      font: 'normal normal 900 15px/23px Roboto',
      letterSpacing: '0.15px',
      color: '#000000BC',
    },
    modal6: {
      font: 'normal normal normal 15px/23px Roboto',
      letterSpacing: '0.15px',
      color: '#000000BC',
    },
    detail4: {
      font: 'normal normal medium 18px/22px Roboto',
      letterSpacing: '0.51px',
      color: '#212121',
    },
    settings: {
      font: 'normal normal bold 24px/36px Roboto',
      letterSpacing: '0px',
      color: '#4B4B4B',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'primary',
        position: 'relative',
        elevation: 2,
      },
      // styleOverrides: {
      //   root: {
      //     border: 'none',
      //   },
      // },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: { width: '100%' },
        filledSuccess: { backgroundColor: '#6CB86A' },
        filledInfo: { backgroundColor: '#6A768C' },
        filledWarning: { backgroundColor: '#AE72A3' },
        filledError: { backgroundColor: '#AE72A3' },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid #E0E0E0',
          borderRadius: '4px',
        },
      },
    },
    // MuiTable: {
    //   styleOverrides: {
    //     stickyHeader: {
    //       '& th': {
    //         backgroundColor: '#E0E0E0',
    //       },
    //     },
    //   },
    // },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            border: 0,
          },
        },
      },
    },
    MuiTableCell: {
      // variants: [
      //   {
      //     props: { variant: 'dark-header' },
      //     style: {
      //       fontWeight: '500',
      //       backgroundColor: '#6A768C',
      //       color: 'white',
      //     },
      //   },
      // ],
      styleOverrides: {
        head: {
          fontWeight: '500',
          backgroundColor: '#6A768C',
          color: '#FFFFFFDE',
          borderColor: '#6A768C',
        },
      },
    },
    MuiTableSortLabel: {
      // variants: [
      //   {
      //     props: { variant: 'dark-header' },
      //     style: {
      //       '&:hover': {
      //         color: 'white',
      //       },
      //       "&.Mui-active": {
      //         color: 'white',
      //       },
      //     },
      //   },
      // ],
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#FFFFFFDE',
          },
          '&.Mui-active': {
            color: '#FFFFFFDE',
          },
        },
        icon: {
          color: 'inherit !important',
        },
      },
    },
  },
})
