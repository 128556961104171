export const TRANSLATIONS_EN = {
  ActiveStatuses: 'Active Statuses',
  ActivityHistory: 'Activity History',
  Add: 'Add',
  AddBattery: 'Add Battery',
  AddBatteryVariant: 'Add Battery Variant',
  AddBatteryVariantLong: 'Add a new battery variant to the database',
  AddRobot: 'Add Robot',
  Addacomment: 'Add a comment',
  Addcommentforcomponent: 'Add comment for component',
  AddTestbed: 'Add Testbed',
  AddTestbedLong: 'Add a new testbed to the database',
  AddonTests: 'Add-on Tests',
  AllRepairs: 'All Repairs',
  AlternativeText1: 'Alternative text - include a link ',
  AlternativeText2: 'to the PDF!',
  AssetTracking: 'Asset Tracking',
  Assign: 'Assign',
  AssignRUTToTehnician: 'Assign RUT to a repair techinician',
  Assigned: 'Assigned',
  available: 'Available',
  Available: 'Available',
  BLID: 'BLID',
  Back: 'Back',
  Battery: 'Battery',
  battery: 'Battery',
  BatteryCapacity: 'Battery Capacity',
  BatteryCapacityUnit: 'Capacity (mAh)',
  BatteryCreated: 'Battery added to database',
  BatteryCreateError: 'Error adding battery to database',
  BatteryHistory: 'Battery History',
  BatteryInUse: 'Battery already in use',
  BatteryLookupError: 'Battery lookup failed',
  BatteryNotOnboarded: 'Battery not onboarded',
  BatteryNotUnique: 'Battery is not unique',
  BatteryOldBlank: 'Old battery not provided',
  BatteryOldBlankContinue: 'Old Battery field is blank. Do you want to continue anyway?',
  BatteryOldBlankTitle: 'Old Battery Not Provided',
  BatteryOldMismatch: 'Old battery does not match battery assigned to robot',
  BatteryOldNewSame: 'Old battery and new battery are the same',
  BatteryOldNewSameContinue: 'Old battery and new battery are the same. Do you want to continue anyway?',
  BatteryOldNewSameTitle: 'Both Batteries are Identical',
  BatteryOnboardSucces: 'Battery was onboarded successfully',
  BatteryProfileLookupError: 'No battery assigned to robot',
  BatterySN: 'Battery S/N',
  BatterySOC: 'Battery SOC',
  BatterySwapSuccessful: 'Battery swap successful',
  BatteryVariant: 'Battery Variant',
  BatteryVariantAvailable: 'Battery variant available',
  BatteryVariantCreated: 'Battery variant added to database',
  BatteryVariantCreateError: 'Error adding battery variant to database',
  BatteryVariantExists: 'Battery variant already exists',
  being_cleaned: 'Being Cleaned',
  'Being Cleaned': 'Being Cleaned',
  BiT: 'BiT',
  BitSaveSuccess: 'BiT result saved successfully',
  BiTResults: 'BiT Results',
  Bit: 'Bit',
  bit: 'BiT',
  BitRequired: 'Built-in-Test required',
  BitRobotUpload: 'Perform BiT on robot and upload results',
  BitTest: 'Bit Test',
  BitUploadError: 'BiT file upload Error',
  Browse: 'Browse',
  BuildSerialLot: 'Build/Lot/Serial Number',
  Cancel: 'Cancel',
  ChangeBattery: 'Please change the battery',
  ChangesIveMade: 'Changes I have made',
  CheckComment: 'Check if the comments have only allowed characters (ASCII).',
  CheckComponentDeletion: 'Are you sure you want to delete this component?',
  CheckConnection: 'Please check your connection and try again.',
  CheckUpload: 'Check if you have uploaded the correct file',
  Cleaning: 'Cleaning',
  ClearRoomba: "Clear Roomba's debris extractors, then press CLEAN to restart",
  Close: 'Close',
  Comment: 'Comment',
  Comments: 'Comments',
  CompleteCleaning: 'Complete Cleaning',
  'CompleteCleaning?': 'Complete Cleaning?',
  CompleteRepair: 'Complete Repair',
  'CompleteRepair?': 'Complete Repair?',
  Component: 'Component',
  Components: 'Components',
  ContinueTesting: 'Continue Testing',
  CreatedBy: 'Created By',
  CreatedDate: 'Created Date',
  current: 'Current',
  Current: 'Current',
  DatabaseEntryCreated: 'Database entry created',
  DatabaseEntryFail: 'Failed to create database entry',
  DatabaseError: 'Database Error',
  DatabaseMismatch: 'Database Mismatch',
  Date: 'Date',
  DateTime: 'Date/Time',
  Debrisextractorsstuck: 'Debris extractors stuck',
  Defined: 'Defined',
  Delete: 'Delete',
  Deletingcomponent: 'Deleting component!',
  Description: 'Description',
  Dismiss: 'Dismiss',
  Done: 'Done',
  Duedate: 'Due date',
  Edit: 'Edit',
  Editcommentforcomponent: 'Edit comment for component',
  Editcomponents: 'Edit components',
  EditTestbed: 'Edit Testbed',
  Empty: 'Empty',
  EndDate: 'End Date',
  EndedBy: 'Ended By',
  EndingTest: 'Ending Test', 
  EndTest: 'End Test',
  EndTestAreYouSure: 'Are you sure you would like to end test ',
  EndTestFailed: 'Could not end test.',
  EndTestSuccess: 'Successfully ended test.',
  English: 'English',
  error: 'Error',
  Error: 'Error',
  Error2: 'Error 2',
  ErrorCode: 'Error Code',
  ErrorCreateSuccess: 'Error created successfully',
  ErrorDescription: 'Error Description',
  ErrorDetails: 'Error Details',
  ErrorDetailsMissing: 'Error details are missing from the database',
  ErrorReport: 'Error Report',
  Errors: 'Errors',
  ErrorTitle: 'Error Title',
  Evaluation: 'Evaluation',
  ExitOnboardingError: 'You are about to exit onboarding process. All entered data will be lost.',
  Fail: 'Fail',
  fail: 'Fail',
  Failuredescription: 'Failure description',
  FetchFailed: 'Failed to fetch data',
  Filename: 'Filename',
  Filterby: 'Filter by',
  Filterbystatus: 'Filter by status',
  Finish: 'Finish',
  Go: 'Go',
  Goback: 'Go back',
  History: 'History',
  Horizontallayout: 'Horizontal layout',
  InProgress: 'In Progress',
  InReview: 'In Review',
  InspectNote:
    'If the inspection failed click on the button "Fail" and if the inspection passed click on the button "Pass"',
  InspectRUT: 'Inspect RUT',
  InspectRutTesting: 'Inspect the RUT prior to testing',
  InstallDate: 'Install Date',
  InstalledBy: 'Installed By',
  InstallNewBattery: 'Install a new battery in the RUT.',
  JiraUpdateFail: 'Failed to update Jira issue',
  JiraUpdateSuccess: 'Jira issue updated',
  Label: 'Label',
  Language: 'Language',
  Leavingthepage: 'Leaving the page',
  ListOfModules: 'List of modules/components',
  Loading: 'Loading',
  Location: 'Location',
  LocationAvailable: 'Location is available',
  LocationCreateError: 'Error looking up location',
  LocationOccupied: 'Location is already occupied',
  LogIn: 'Log In',
  LogOut: 'Log Out',
  Lowbattery: 'Low Battery',
  Malay: 'Malay',
  Missions: 'Missions',
  Model: 'Model',
  Module: 'Module',
  MyRepairs: 'My Repairs',
  Name: 'Name',
  NeedsCleaning: 'Needs Cleaning',
  needs_cleaning: 'Needs Cleaning',
  'Needs Cleaning': 'Needs Cleaning',
  NeedsRepair: 'Needs Repair',
  needs_repair: 'Needs Repair',
  'Needs Repair': 'Needs Repair',
  Networkerror: 'Network error',
  NewBattery: 'New Battery',
  NewBatteryAdded: 'New battery added',
  Next: 'Next',
  No: 'No',
  none: 'none',
  NoNotes: 'No notes',
  NotConnected: 'It seems that you are not connected to internet',
  NOTE: 'NOTE',
  Notes: 'Notes',
  Notifications: 'Notifications',
  Notificationsettings: 'Notification settings',
  OK: 'OK',
  Occupied: 'Occupied',
  OldBattery: 'Old Battery',
  OnHold: 'On Hold',
  OnboardABattery: 'Onboard a Battery',
  OnboardAnother: 'Onboard another',
  OnboardedDate: 'Onboarded Date',
  Onboarding: 'Onboarding',
  OnboardingReadiness: 'Onboarding Readiness',
  Outlook: 'Outlook',
  Pass: 'Pass',
  pass: 'Pass',
  Paused: 'Paused',
  Photos: 'Photos',
  previous: 'Previous',
  Previous: 'Previous',
  Print: 'Print',
  PrintAndAffix: 'Print the label and affix it to the robot',
  PrintLabel: 'Print Label',
  PrintLabels: 'Print Labels',
  Priority: 'Priority',
  PriorToOnboarding: 'You will need',
  Profile: 'Profile',
  Progress: 'Progress',
  ProvisionAnother: 'Provision Another',
  Provisioning: 'Provisioning',
  Rack: 'Rack',
  RUT: 'RUT',
  RUTAlreadyOnboard: 'RUT Already Onboard',
  RUTalreadyonboarded: 'RUT already onboarded',
  RutInfo: 'RUT Info',
  ready: 'Ready',
  Ready: 'Ready',
  ReadyforInvestigation: 'Ready for Investigation',
  ReliabilityGrowthTesting: 'Reliability Growth Testing',
  RemovalDate: 'Removal Date',
  RemovedBy: 'Removed By',
  Removefromtestbed: 'Remove from test bed',
  Repair: 'Repair',
  RepairDetails: 'Repair Details',
  RepairRobot: 'Repair Robot',
  Repairs: 'Repairs',
  RepairsOverview: 'Repairs Overview',
  ReportError: 'Report Error',
  Reporting: 'Reporting',
  RequestCleaning: 'Request Cleaning',
  'RequestCleaning?': 'Request Cleaning?',
  RequestRepair: 'Request Repair',
  Resolved: 'Resolved',
  Result: 'Result',
  Retire: 'Retire',
  RetireRobot: 'Retire Robot',
  RetiringRobot: 'Retiring Robot',
  RetireRobotAreYouSure: 'Are you sure you would like to retire robot ',
  RetireRobotFailed: 'Could not retire robot.',
  RetireRobotSuccess: 'Successfully retired robot.',
  retired: 'Retired',
  Retired: 'Retired',
  Robot: 'Robot',
  RobotAlreadyOnboarded: 'The robot has already been onboarded.',
  RobotAlreadyUnderTest: 'Robot is already under test',
  RobotImageAlt: 'Robot image showing where to put the label',
  RobotLookupError: 'Robot lookup failed',
  RobotMultipleLocations: 'Robot is assigned to multiple locations',
  RobotName: 'Robot Name',
  RobotNameMustMatch: "The robot's name must match the one selected below.",
  RobotNameNoBreak: 'Robot Name',
  RobotNeedsRepair: 'Robot needs to be repaired',
  RobotNeedsRepairTitle: 'Robot Needs Repair',
  RobotNotFound: 'Robot Not Found',
  RobotNotOnboarded: 'Robot is not onboarded',
  RobotNotUnique: 'Robot is not unique',
  RobotNotProvisioned: 'Robot has not been provisioned yet',
  RobotOnboardSuccess: 'Robot was onboarded successfully.',
  RobotOnboardingSuccess: 'Robot was onboarded successfully.',
  RobotProvisionedSuccess: 'Robot was provisioned successfully.',
  RobotRetired: 'Robot has been retired from service',
  RobotUnderRepair: 'Robot is being repaired',
  Robots: 'Robots',
  RobotSN: 'Robot S/N',
  RobotStatus: 'Robot Status',
  RobotconnectedtoWiFi: 'Robot connected to WiFi',
  Robotname: 'Robot name',
  Robotnamedcorrectly: 'Robot named correctly',
  run: 'Running',
  running: 'Running',
  Running: 'Running',
  Runtime: 'Runtime',
  Save: 'Save',
  SaveAndExit: 'Save & Exit',
  Savechanges: 'Save changes',
  Savepassword: 'Save password',
  SavingRobotInformation: 'Saving Robot Information...',
  ScanRobotAndBattery: 'Scan the robot, old battery, and new battery.',
  ScanRobotSerial: "Scan the robot's serial number.",
  ScanRobotTestbed: 'Please scan the robot and battery',
  'Search…': 'Search…',
  SelectDifferentRobot: 'Please select a different robot to onboard.',
  SelectHowToReceiveNotifications: 'Select how you would like to receive your notifications',
  SelectNotifyAlerts: 'Select for which alerts you would like to get notified.',
  SelectTestAndRobot: 'Select the test and robot name that correspond to the robot being onboarded.',
  SelectWorkstation: 'Select a Workstation',
  SelectWorkstationLayout: 'Select your preferred workstation screen layout.',
  Sendrequest: 'Send request',
  SerialLookupError: 'Serial number lookup failed',
  SerialLookupFound: 'Serial number already exists in database',
  SerialLookupNotFound: 'Serial number is not a duplicate',
  SerialNotUnique: 'Serial number is not unique',
  Serialnumber: 'Serial Number',
  SettingsNotSaved: 'Error: Settings not saved',
  Settingsnotsaved: 'Settings not saved!',
  Shelf: 'Shelf',
  Show: 'Show',
  SignIn: 'Sign In',
  Slack: 'Slack',
  Snackbar: 'Snackbar',
  Software: 'Software',
  SomeError: 'Some error happened.',
  Somethingwentwrong: 'Something went wrong',
  SpareParts: 'Spare Parts',
  Squarelayout: 'Square layout',
  StartDate: 'Start Date',
  StartTest: 'Start Test',
  StartTestFailed: 'Failed to start test',
  StartTestSuccess: 'Test started successfully',
  Status: 'Status',
  stop: 'Stopped',
  stopped: 'Stopped',
  Stopped: 'Stopped',
  StoppedRobot: 'Robot has stopped',
  Subcomponent: 'Subcomponent',
  Submit: 'Submit',
  Success: 'Success',
  Supplier: 'Supplier',
  SwapBattery: 'Swap Battery',
  Technician: 'Technician',
  Technicianname: 'Technician name',
  Test: 'Test',
  Testbed: 'Testbed',
  TestbedBeingCleaned: 'Testbed is being cleaned',
  TestbedCreated: 'Testbed created',
  TestbedCreateError: 'Error creating testbed',
  TestbedCreateSuccess: 'Testbed created successfully',
  TestbedID: 'Testbed ID',
  TestbedInfo: 'Testbed Info',
  TestbedLocationError: 'Error creating testbed location',
  TestbedLocationSuccess: 'Testbed location created',
  TestbedLookupError: 'Testbed lookup failed',
  TestbedName: 'Testbed Name',
  TestbedNeedsCleaning: 'Testbed needs cleaning',
  TestbedNeedsRepair: 'Testbed needs to be repaired',
  TestbedNeedsRepairTitle: 'Testbed Needs Repair',
  TestbedNotFound: 'Testbed not found',
  TestbedNotUnique: 'Testbed is not unique',
  TestbedOccupied: 'Testbed is already occupied',
  TestbedRetired: 'Testbed has been retired from service',
  Testbeds: 'Testbeds',
  TestbedSN: 'Testbed Serial Number',
  TestbedSNshort: 'Testbed S/N',
  TestbedStatus: 'Testbed Status',
  TestbedStatusError: 'Error creating testbed status',
  TestbedStatusSuccess: 'Testbed status created',
  TestbedStatusUndefined: 'Testbed has no status',
  TestbedUnderRepair: 'Testbed is being repaired',
  TestIndividualComponents: 'Test individual components by adding them to a robot',
  TestModalScreens: 'Test Modal Screens',
  TestNameNoBreak: 'Test Name',
  Testing: 'Testing',
  Tests: 'Tests',
  Therobotnamewasnotfound: 'The robot name was not found.',
  ThisActionCannotBeUndone: 'This action cannot be undone.',
  Title: 'Title',
  TopStatus: 'Top Status',
  TrackRobots: 'Track Robots',
  TrackSpareParts: 'Track Spare Parts',
  TrackTestbeds: 'Track Testbeds',
  TrainingAvailable: 'Available Trainings',
  TrainingCompleted: 'Completed Trainings',
  Unassigned: 'Unassigned',
  UnderRepair: 'Under Repair',
  under_repair: 'Under Repair',
  'Under Repair': 'Under Repair',
  UnderTest: 'Under Test',
  under_test: 'Under Test',
  'Under Test': 'Under Test',
  UnexpectedName: 'Unexpected name',
  UploadBiTResults: 'Upload BiT Results',
  UseApp: 'Use the iRobot app to connect the robot to WiFi.',
  User: 'User',
  UserProfile: 'User Profile',
  UserSettings: 'User Settings',
  UserSettingsError: 'You are about to exit user settings without saving your changes',
  Userdetails: 'User details',
  Username: 'User name',
  Userrole: 'User role',
  Variant: 'Variant',
  VariantName: 'Variant Name',
  VerifyRobotData: 'Verify that the information provided is correct.',
  VerifyRobotName: 'Verify that the robot name is correct.',
  VoltageUnit: 'Voltage (V)',
  WhenAnyErrors: 'When any errors of a certain type occur',
  WiFiPassword: 'WiFi Password',
  WiFipassword: 'WiFi password',
  WifiNetwork: 'WiFi Network',
  WifiNetworkname: 'WiFi Network name',
  Workstation: 'Workstation',
  WorkstationLayout: 'Workstation Layout',
  Yes: 'Yes',
  YesRobotDataIsCorrect: 'Yes, the information is correct.',
  Youarenotsignedin: 'You are not signed in',
  hours: 'hours',
}
