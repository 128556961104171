import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button } from '@mui/material'
import './AddRobot.css'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'

const AddRobot = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleNext = () => {
    history.push(window.location.pathname + '/process')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('OnboardingReadiness')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4} alignItems="center">
            <Typography variant="modal2">
              {t('PriorToOnboarding')}:
              <ul style={{ marginBottom: 0 }}>
                <li>{t('RobotconnectedtoWiFi')}</li>
                <li>{t('Robotnamedcorrectly')}</li>
                <li>{t('BiTResults')}</li>
                <li>{t('ListOfModules')}</li>
              </ul>
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" onClick={handleNext}>
                {t('Next')}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
}

export default AddRobot
