import React from 'react'
import { Search } from '@mui/icons-material'
import { styled, alpha, InputBase } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SearchDiv = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha('#0000001F', 0.15),
  '&:hover': {
    backgroundColor: alpha('#0000001F', 0.2),
  },
  marginLeft: '1px',
  marginRight: '1px',
  marginTop: '5px',
  marginBottom: '5px',
  [theme.breakpoints.up('sm')]: {
    // width: '96%',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}))
const CustomSearch = ({ search, handleSearchUpdate }) => {
  const { t } = useTranslation()

  return (
    <SearchDiv>
      <SearchIconWrapper>
        <Search />
      </SearchIconWrapper>
      <StyledInputBase
        onChange={event => handleSearchUpdate(event.target.value)}
        value={search}
        placeholder={t('Search…')}
        inputProps={{ 'aria-label': 'search' }}
      />
    </SearchDiv>
  )
}

export default CustomSearch
