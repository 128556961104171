import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import './PrintLabels.css'

const PrintLabels = () => {
  const [robot, setRobot] = useState('Daredevil-EP1')
  const [label, setLabel] = useState('DD-EP1-05')
  const [choice, setChoice] = useState('all')
  const [disableDropdown, setDisableDropdown] = useState(true)
  const [disableText, setDisableText] = useState(true)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleChange = event => {
    setRobot(event.target.value)
  }

  const handleLabelChange = event => {
    setLabel(event.target.value)
  }

  const handleRadioButtonChange = event => {
    setChoice(event.target.value)
    setDisableDropdown(true)
    setDisableText(true)
    if (event.target.value === 'single_label') {
      setDisableDropdown(false)
    }
    if (event.target.value === 'custom_range') {
      setDisableText(false)
    }
  }

  return (
    <Container maxWidth="sm">
      <Box>
        <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
          {t('PrintLabels')}
        </Typography>
        <Paper>
          <Box sx={{ mt: 4, p: '10px' }}>
            <Grid
              container
              direction="row"
              columnSpacing={3}
              alignItems="center"
              justifyContent="space-evenly"
              sx={{ mt: '15px', m: 0, width: '100%' }}
            >
              <Grid>
                <Typography variant="h6" sx={{ mt: '40px', fontWeight: 'normal' }}>
                  {t('Test')}
                </Typography>
              </Grid>
              <Grid>
                <Box sx={{ minWidth: '380px', mt: '40px' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Robot</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={robot}
                      label="Robot"
                      onChange={handleChange}
                    >
                      <MenuItem value={'Daredevil-EP1'}>Daredevil-EP1</MenuItem>
                      <MenuItem value={'Daredevil-EP2'}>Daredevil-EP2</MenuItem>
                      <MenuItem value={'Daredevil-EP3'}>Daredevil-EP3</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="space-evenly" sx={{ pl: '35px', pr: '35px' }}>
              <RadioGroup
                aria-label="choice"
                name="controlled-radio-buttons-group"
                value={choice}
                onChange={handleRadioButtonChange}
                sx={{ mt: '24px', minHeight: '230px', justifyContent: 'space-evenly' }}
              >
                <div>
                  <FormControlLabel value="all" label="All not onboarded" control={<Radio />} />
                </div>
                <div className="print-content">
                  <FormControlLabel value="single_label" label="Single label" control={<Radio />} />
                  <Box sx={{ minWidth: '250px' }}>
                    <FormControl fullWidth disabled={disableDropdown}>
                      <InputLabel id="demo-simple-select-label">{t('Label')}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={label}
                        label="Label"
                        onChange={handleLabelChange}
                      >
                        <MenuItem value={'DD-EP1-05'}>DD-EP1-05</MenuItem>
                        <MenuItem value={'DD-EP1-02'}>DD-EP1-02</MenuItem>
                        <MenuItem value={'DD-EP1-04'}>DD-EP1-04</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div className="print-content">
                  <FormControlLabel value="custom_range" label="Custom range" control={<Radio />} />
                  <Box sx={{ minWidth: '250px' }}>
                    <FormControl fullWidth>
                      <TextField id="outlined" label="e.g. 1-5, 8, 11-13" disabled={disableText} />
                    </FormControl>
                  </Box>
                </div>
              </RadioGroup>
            </Grid>
            <div className="print-button">
              <Button variant="contained">{t('Print')}</Button>
            </div>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}

export default PrintLabels
