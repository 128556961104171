import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, TextField, styled } from '@mui/material'
import './ErrorReport.css'
import { observer } from 'mobx-react'
import imageCompression from 'browser-image-compression'
import { LoadingButton } from '@mui/lab'
import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/system'

const InputButton = styled('input')({
  display: 'none',
})

const ErrorReport = observer(() => {
  const [robot, setRobot] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const descriptionLimit = 500
  const [errorCode, setErrorCode] = useState(0)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const history = useHistory()
  // Report Type can be 'robot', 'battery', or 'testbed'
  // Only using 'robot' for now
  const reportType = Store.reportData.errorType
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [error, setError] = useState({
    robotError: false,
    titleError: false,
    descriptionError: false,
    errorCodeError: false,
    photosError: false,
  })
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const user = Store.accountInfo.userPK

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // Error handling
  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  const handleError = (event, from) => {
    let errorType
    if (from === 'robot-text' || from === 'getRobotPK') {
      errorType = 'robotError'
    } else if (from === 'title-text') {
      errorType = 'titleError'
    } else if (from === 'description-text') {
      errorType = 'descriptionError'
    } else if (from === 'error-code-text') {
      errorType = 'errorCodeError'
    } else if (from === '') {
      errorType = 'photosError'
    }
    checkValidation(event, errorType)
  }

  // Inputs
  const assetID = errorType => {
    if (errorType == 'robot') {
      return t('RobotSN')
    }
    if (errorType == 'battery') {
      return t('BatterySN')
    }
    if (errorType == 'testbed') {
      return t('Testbed')
    }
    return errorType
  }

  const handleRobotChange = value => {
    setRobot(value)
  }

  const handleTitleChange = value => {
    setTitle(value)
  }

  const handleDescriptionChange = value => {
    setDescription(value)
  }

  // const handleErrorCodeChange = value => {
  //   setErrorCode(value)
  // }

  const handlePhotosChange = async event => {
    if (event.target.value !== '' && event.target.files) {
      setFiles(event.target.files)
    }
  }

  const goBack = () => {
    if (history.location.state?.fail) {
      history.push(history.location.state?.from)
    } else {
      history.push(history.location.state.from, { from: 'error' })
    }
  }

  // Cancel button
  const handleCancel = () => {
    goBack()
  }

  // Save button
  useEffect(() => {
    if (robot && title && description) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [robot, title, description])

  const getRobotPK = async robotSN => {
    const res = await client
      .get(`/robot/sn2pk/${robotSN}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log('getRobotPK error')
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotPK')
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        // RobotSN not in database
        enqueueSnackbar(t('RobotNotFound'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotPK')
        throw new Error(`RobotSN ${robotSN} not found in database`)
      }
      if (res.data.length === 1) {
        // Only one robot with RobotSN
        return res.data[0].Robot.PK_Robot
      }
      if (res.data.length > 1) {
        // Multiple robots with RobotSN
        enqueueSnackbar(t('RobotNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'getRobotPK')
        throw new Error(`Multiple entries for RobotSN ${robotSN}`)
      }
    } else {
      // shouldn't get here
      enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
      throw new Error('Bad response from get_testbed')
    }
  }

  const create_error = async robotPK => {
    let formData = new FormData()
    formData.append('object_name', 'robot')
    formData.append('object_id', robotPK)
    formData.append('name', title)
    formData.append('description', description)
    formData.append('code', errorCode)
    formData.append('rut_id', robotPK)
    formData.append('fk_user', user)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    for (let file of files) {
      const compressedFile = await imageCompression(file, options)
      formData.append('images', compressedFile, file.name)
      formData.append('image_names', file.name)
    }

    const res = await client({
      method: 'post',
      url: `/error`,
      data: formData,
      headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' },
    }).catch(error => {
      enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
      throw new Error(error)
    })
    if (res?.status === 200) {
      if (!res.data.PK_ErrorProfile || !res.data.PK_RobotStatusProfile) {
        enqueueSnackbar(t('DatabaseEntryFail'), { variant: 'error' })
        throw new Error(t('DatabaseEntryFail'))
      }
      if (!res.data.Jira.id) {
        enqueueSnackbar(t('JiraUpdateFail'), { variant: 'error' })
        throw new Error(t('JiraUpdateFail'))
      }
      return true
    } else {
      throw new Error('Bad response from create_error')
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      const robotPK = await getRobotPK(robot)
      await create_error(robotPK)
      enqueueSnackbar(t('ErrorCreateSuccess'), { variant: 'success' })
      setLoading(false)
      goBack()
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('Error Report')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{assetID(reportType)}</Typography>
              <TextField
                autoFocus
                required
                id="robot-text"
                label={assetID(reportType)}
                value={robot}
                onChange={e => handleRobotChange(e.target.value)}
                error={error.robotError}
                onBlur={event => handleError(event, 'robot-text')}
              />

              {/* <Typography variant="subtitle1">{t('Error Code')}</Typography>
              <TextField
                required
                type="number"
                id="outlined-multiline-static"
                label={t('ErrorCode')}
                value={errorCode}
                onChange={e => handleErrorCodeChange(e.target.value)}
                error={error.errorCodeError}
                onBlur={event => handleError(event, 'error-code-text')}
              /> */}

              <Typography variant="subtitle1">{t('ErrorTitle')}</Typography>
              <TextField
                required
                id="title-text"
                label={t('ErrorTitle')}
                value={title}
                onChange={e => handleTitleChange(e.target.value)}
                error={error.titleError}
                onBlur={event => handleError(event, 'title-text')}
              />

              <Typography variant="subtitle1">{t('Description')}</Typography>
              <TextField
                required
                id="outlined-multiline-static"
                label={t('ErrorDescription')}
                multiline
                rows={5}
                inputProps={{ maxLength: descriptionLimit }}
                helperText={`${description.length}/${descriptionLimit}`}
                value={description}
                onChange={e => handleDescriptionChange(e.target.value)}
                error={error.descriptionError}
                onBlur={event => handleError(event, 'description-text')}
              />

              <Typography variant="subtitle1">{t('Photos')}</Typography>
              <label htmlFor="contained-button-file" style={{ minWidth: '75%' }}>
                <InputButton
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onInput={handlePhotosChange}
                />
                <Button variant="contained" component="span" color="greyGreen">
                  {t('Browse')}
                </Button>
              </label>

              <span></span>
              <Stack overflow="hidden">
                {Array.from(files).map((item, index) => {
                  return (
                    <Typography noWrap component="div" key={`photo-${index}`}>
                      {item.name}
                    </Typography>
                  )
                })}
              </Stack>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" color="greyGreen" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <LoadingButton variant="contained" disabled={submitDisabled} onClick={handleSave} loading={loading}>
                {t('Save')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
})

export default ErrorReport
