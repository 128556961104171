import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, TextField } from '@mui/material'
import { observer } from 'mobx-react'
import './SwapBattery.css'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import client from '../../../Config/axiosClient'
import Store from '../../../Store/Store'
import ConfirmationModal from '../Modal/ConfirmationModal'
import { Stack } from '@mui/system'

const SwapBattery = observer(() => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const user = Store.accountInfo.userPK
  const [robot, setRobot] = useState('')
  const [oldBattery, setOldBattery] = useState('')
  const [newBattery, setNewBattery] = useState('')
  const [robotPK, setRobotPK] = useState('')
  const [oldBatteryPK, setOldBatteryPK] = useState('')
  const [newBatteryPK, setNewBatteryPK] = useState('')
  const [robotBatteryProfilePK, setRobotBatteryProfilePK] = useState('')
  const [error, setError] = useState({
    robotError: false,
    oldBatError: false,
    newBatError: false,
  })
  const openModal = Store.openModal
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  // Error handling
  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  const handleError = (event, from) => {
    if (from === 'robot-text' || from === 'find_robot' || from === 'get-battery-profile') {
      const errorType = 'robotError'
      checkValidation(event, errorType)
    } else if (from === 'oldBat-text') {
      const errorType = 'oldBatError'
      // checkValidation(event, errorType)
    } else if (from === 'get-old-battery' || from === 'oldBat-check') {
      const errorType = 'oldBatError'
      checkValidation(event, errorType)
    } else if (from === 'newBat-text' || from === 'get-new-battery') {
      const errorType = 'newBatError'
      checkValidation(event, errorType)
    }
  }

  // Inputs
  const handleRobotChange = value => {
    setRobot(value)
  }

  const handleOldBatteryChange = value => {
    setOldBattery(value)
  }

  const handleNewBatteryChange = value => {
    setNewBattery(value)
  }

  // Modal stuff
  const modal = (title, message) => {
    setModalTitle(title)
    setModalMessage(message)
    Store.setOpenModal(true) // this must come first
    setShowModal(true) // this must come second
  }

  useEffect(() => {
    if (openModal) {
      setShowModal(true)
    } else if (!openModal) {
      setShowModal(false)
    }
  }, [openModal])

  // Clear the form
  const reset = () => {
    setRobot('')
    setOldBattery('')
    setNewBattery('')
  }

  // Cancel button
  const handleCancel = () => {
    reset()
    history.push(history.location.state.from, { from: 'swap_battery' })
  }

  // Continue Testing button
  useEffect(() => {
    if (robot && newBattery) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [robot, oldBattery, newBattery])

  const find_robot = async () => {
    let body = {
      serial_number: robot,
    }
    const res = await client
      .post(`/robot/search`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        enqueueSnackbar(t('SerialLookupError'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'find_robot')
        throw new Error(`Robot ${robot} doesn't exist in the database`)
      }
      if (res.data.length === 1) {
        return res.data[0].PK_Robot
      }
      if (res.data.length > 1) {
        enqueueSnackbar(t('SerialNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'find_robot')
        throw new Error(`Multiple entries for robot ${robot} exist in the database`)
      } else {
        console.log(res.data)
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(`Robot ${robot} lookup error`)
      }
    } else {
      throw new Error('Bad response from find_robot')
    }
  }

  const get_battery = async (serial, from) => {
    const res = await client
      .get(`/battery/serial/${serial}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        enqueueSnackbar(t('BatteryNotOnboarded'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, from)
        throw new Error(`Battery ${serial} doesn't exist in the database`)
      }
      if (res.data.length === 1) {
        const PK_Battery = res.data[0].PK_Battery
        return PK_Battery
      }
      if (res.data.length > 1) {
        enqueueSnackbar(t('BatteryNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, from)
        throw new Error(`Multiple entries for battery ${serial} exist in the database`)
      } else {
        console.log(res.data)
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(`Battery ${serial} lookup error`)
      }
    } else {
      throw new Error('Bad response from get_battery')
    }
  }

  const get_battery_profile = async robot_id => {
    const res = await client
      .get(`/battery/profile/${robot_id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      const activeBatteries = res.data.filter(item => {
        if (item.RemovalDate) {
          return false
        } else {
          return true
        }
      })
      if (activeBatteries.length === 0) {
        enqueueSnackbar(t('BatteryProfileLookupError'), { variant: 'error' })
        const event = { target: { value: '' } }
        const from = 'get-battery-profile'
        handleError(event, from)
        throw new Error(`Robot battery profile doesn't exist in the database`)
      }
      if (activeBatteries.length === 1) {
        return activeBatteries
      }
      if (activeBatteries.length > 1) {
        enqueueSnackbar(t('BatteryNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        const from = 'get-battery-profile'
        handleError(event, from)
        throw new Error(`Multiple entries for robot battery profile exist in the database`)
      } else {
        console.log(res.data)
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(`Robot battery profile lookup error`)
      }
    } else {
      throw new Error('Bad response from get_battery_profile')
    }
  }

  const rem_old_battery = async (profile_id, robot_id, battery_id, user_id) => {
    let body = {
      robot_id: robot_id,
      battery_id: battery_id,
      user_id: user_id,
      display_order: 0,
    }
    const res = await client
      .post(`/battery/profile/${profile_id}?remove=true`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return res.data
    } else {
      throw new Error('Bad response from rem_old_battery')
    }
  }

  const add_new_battery = async (robot_id, battery_id, user_id) => {
    let body = {
      robot_id: robot_id,
      battery_id: battery_id,
      user_id: user_id,
      display_order: 0,
    }
    const res = await client
      .post(`/battery/profile`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return true
    } else {
      throw new Error('Bad response from add_new_battery')
    }
  }

  const updateBatteries = async (RobotBatteryProfilePK, robotPK, oldBatteryPK, newBatteryPK, user) => {
    try {
      setSubmitLoading(true)

      await rem_old_battery(RobotBatteryProfilePK, robotPK, oldBatteryPK, user)
      await add_new_battery(robotPK, newBatteryPK, user)
      enqueueSnackbar(t('BatterySwapSuccessful'), { variant: 'success' })

      setSubmitLoading(false)
      reset()
      history.push(history.location.state.from, { from: 'swap_battery' })
    } catch (error) {
      setSubmitLoading(false)
      throw new Error(error)
    }
  }

  const handleContinueTest = async () => {
    try {
      setSubmitLoading(true)
      const robotPK = await find_robot()
      setRobotPK(robotPK)
      let oldBatteryPK = ''
      if (oldBattery) {
        oldBatteryPK = await get_battery(oldBattery, 'get-old-battery')
        setOldBatteryPK(oldBatteryPK)
      }
      const newBatteryPK = await get_battery(newBattery, 'get-new-battery')
      setNewBatteryPK(newBatteryPK)
      const robotBatteryProfile = await get_battery_profile(robotPK)
      const currentBattery = robotBatteryProfile.pop()
      setRobotBatteryProfilePK(currentBattery.PK_RobotBatteryProfile)

      if (oldBatteryPK && oldBatteryPK == newBatteryPK) {
        enqueueSnackbar(t('BatteryOldNewSame'), { variant: 'warning' })
        const title = t('BatteryOldNewSameTitle')
        const message = t('BatteryOldNewSameContinue')
        modal(title, message)
        setSubmitLoading(false)
        return
      }

      if (!oldBatteryPK) {
        enqueueSnackbar(t('BatteryOldBlank'), { variant: 'warning' })
        const title = t('BatteryOldBlankTitle')
        const message = t('BatteryOldBlankContinue')
        setOldBatteryPK(currentBattery.FK_Battery)
        modal(title, message)
        setSubmitLoading(false)
        return
      }

      if (oldBatteryPK !== currentBattery.FK_Battery) {
        enqueueSnackbar(t('BatteryOldMismatch'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'oldBat-check')
        setSubmitLoading(false)
        throw new Error(`Old battery mismatch. ${oldBatteryPK} !== ${currentBattery.FK_Battery}`)
      }

      await updateBatteries(currentBattery.PK_RobotBatteryProfile, robotPK, oldBatteryPK, newBatteryPK, user)
      setSubmitLoading(false)
    } catch (error) {
      setSubmitLoading(false)
      console.log(error)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('SwapBattery')}
      </Typography>
      <Stack mb={2}>
        <Typography variant="modal1" align="center" gutterBottom>
          {t('InstallNewBattery')}
        </Typography>
        <Typography variant="modal1" align="center">
          {t('ScanRobotAndBattery')}
        </Typography>
      </Stack>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{t('RobotSN')}</Typography>
              <TextField
                autoFocus
                required
                id="robot-text"
                label={t('RobotSN')}
                value={robot}
                onChange={e => handleRobotChange(e.target.value)}
                error={error.robotError}
                onBlur={event => handleError(event, 'robot-text')}
              />

              <Typography variant="subtitle1">{t('OldBattery')}</Typography>
              <TextField
                id="oldBat-text"
                label={t('OldBattery')}
                value={oldBattery}
                onChange={e => handleOldBatteryChange(e.target.value)}
                error={error.oldBatError}
                onBlur={event => handleError(event, 'oldBat-text')}
              />

              <Typography variant="subtitle1">{t('NewBattery')}</Typography>
              <TextField
                required
                id="newBat-text"
                label={t('NewBattery')}
                value={newBattery}
                onChange={e => handleNewBatteryChange(e.target.value)}
                error={error.newBatError}
                onBlur={event => handleError(event, 'newBat-text')}
              />
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" color="greyGreen" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                onClick={handleContinueTest}
                disabled={submitDisabled}
                loading={submitLoading}
              >
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
      <ConfirmationModal
        showModal={showModal}
        title={modalTitle}
        message={modalMessage}
        updateBatteries={updateBatteries}
        robotBatteryProfilePK={robotBatteryProfilePK}
        robotPK={robotPK}
        oldBatteryPK={oldBatteryPK}
        newBatteryPK={newBatteryPK}
        user={user}
      />
    </Box>
  )
})

export default SwapBattery
