import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

class ReportDataStore {
  // observables
  reportData = [
    {
      title: '',
      description: '',
    },
  ]

  errorType = ''

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'ReportDataStore',
      properties: ['reportData', 'errorType'],
      storage: window.localStorage,
    })
  }

  // actions
  setReportData = reportData => {
    this.reportData = reportData
  }

  setErrorType = errorType => {
    this.errorType = errorType
  }

  resetReportData = () => {
    this.setReportData([])
  }

  resetErrorType = () => {
    this.setErrorType('')
  }

  clearReportDataStore = () => {
    this.reportData = [
      {
        title: '',
        description: '',
      },
    ]
    this.errorType = ''
    clearPersistedStore(this)
  }
}

export default ReportDataStore
