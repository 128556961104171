import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { observer } from 'mobx-react'
import client from '../../../Config/axiosClient'
import { useHistory, useParams } from 'react-router-dom'
import Store from '../../../Store/Store'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Box, Button, Container, Paper, Typography } from '@mui/material'
import RetireRobotModal from '../Modal/RetireRobotModal'


import './RetireRobot.css'

const RetireRobot = observer(() => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { blid } = useParams()
  const user = Store.accountInfo.userPK
  const [openRetireRobotModal, setOpenRetireRobotModal] = useState(false)

  let robotInfo;
  // if were coming from testing, use the testing store.  If coming from robot details
  // use the robotStatus store.
  if (Store.testingDataStore.selected.robot.BLID !== blid) {
    console.log(blid)
    console.log(Store.robotStatusStore.robotStatusObjects)
    robotInfo = Store.robotStatusStore.robotStatusObjects[blid];
    console.log(robotInfo);
    robotInfo.RobotName = robotInfo.robotName;
  } else {
    robotInfo = Store.testingDataStore.selected.robot
  }  

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleClose = () => {
    setOpenRetireRobotModal(false)
  }

  async function retire_robot() {
    const res = await client
      .post(
        '/robot/retire',
        {
          blid: robotInfo.BLID,
          fk_user: user,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('RetireRobotFailed'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return res
    }
  }

  const handleRetireRobot = async () => {
    try {
      setOpenRetireRobotModal(true)
      // console.log("robotInfo")
      // console.log(robotInfo)
      // console.log(user)
      const result = await retire_robot()
      console.log("result")
      console.log(result)
      if (result.body.message && result.body.message.error) {
        enqueueSnackbar('Robot removed from DB, but with errors: ' + result.body.message.error)
      } else {
        enqueueSnackbar(t('RetireRobotSuccess'), { variant: 'success' })
      }
      // console.log("result")
      // console.log(result)
      setOpenRetireRobotModal(false)
      history.goBack()
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('Retire')} {robotInfo.RobotName}
      </Typography>
      <Container maxWidth="lg">
        <Paper sx={{ p: '10px' }}>
          <div className="content-wrapper">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
              {t('RetireRobotAreYouSure')} {robotInfo.RobotName}?
            </Typography>
            <Typography variant="content-wrapper">{t('ThisActionCannotBeUndone')}</Typography>
          </div>
          <div className="addon-button-wrapper">
            <Button variant="contained" onClick={handleCancel} color="alertPurple" sx={{ m: '0px 6px' }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleRetireRobot} color="greyGreen" sx={{ m: '0px 6px' }}>
              Yes, Retire
            </Button>
          </div>
        </Paper>
        <RetireRobotModal open={openRetireRobotModal} handleClose={handleClose} />
      </Container>
    </Box>
  )
})

export default RetireRobot
