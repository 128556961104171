import React, { useState } from 'react'
import { TextField } from '@mui/material'

const CustomTextField = ({ value, handleChange, id, index }) => {
  const [text, setText] = useState(value)

  const onTextChange = e => {
    setText(e.target.value)
    handleChange(e, id, index)
    // handleError(e, 'serial')
  }

  return (
    <div>
      <TextField
        size="small"
        value={text}
        onChange={onTextChange}
        // error={error}
        // onBlur={event => handleError(event, 'serial')}
      />
    </div>
  )
}

export default CustomTextField
