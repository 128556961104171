import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Backdrop, Modal, Fade, Typography, Box, Button, CircularProgress } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import './PrintLabelModal.css'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '1000px',
    height: '500px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const PrintLabelModal = ({ open, handleClose }) => {
  const { authState } = useOktaAuth()
  const classes = useStyles()
  const idToken = authState.idToken.idToken
  const [robotImage, setRobotImage] = useState(
    'https://store.irobot.com/on/demandware.static/-/Sites-master-catalog-irobot/default/dw3f4a9852/images/large/R650020.jpg'
  )
  const [robotLabel, setRobotLabel] = useState('')
  const robotData = Store.onboardingStore.robotData
  const { t } = useTranslation()

  useEffect(() => {
    const getImage = async () => {
      let res = await client.get(`/robot/image/${robotData.FK_RobotModel}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setRobotImage(res.data.image)
    }
    const getLabel = async () => {
      const date = new Date().toISOString()
      const body = JSON.stringify({
        RobotSN: robotData.RobotSN,
        BLID: robotData.BLID,
        RobotName: robotData.RobotName,
        CreatedDate: date,
      })
      body = body.replace('"', '"')
      const data = { body: body }
      let res = await client.post('/robot/label', data, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setRobotLabel(res.data.url)
    }
    if (idToken && open) {
      getImage()
      getLabel()
    }
  }, [idToken, open, robotData])

  const handlePrint = () => {
    window.open(robotLabel, '', 'width=300;height=100;')
  }
  return (
    <div>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px' }}
            >
              {t('PrintLabel')}
            </Typography>
            <Typography
              variant="modal1"
              align="center"
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '15px',
                paddingBottom: '15.5px',
                textAlign: 'center',
              }}
            >
              {t('PrintAndAffix')}
            </Typography>
            <div className="print-content">
              <Box
                component="img"
                alt={t('RobotImageAlt')}
                src={robotImage}
                sx={{
                  height: 240,
                  width: 240,
                  //   maxHeight: { xs: 240, md: 200 },
                }}
              />
              <Box
                sx={{ border: 1, height: 200, width: 600, display: 'flex' }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {robotLabel ? (
                  <object data={robotLabel} type="application/pdf" width="100%" height="100%">
                    <p>
                      {t('AlternativeText1')}
                      <a href={robotLabel}>{t('AlternativeText2')}</a>
                    </p>
                  </object>
                ) : (
                  <CircularProgress size={90} />
                )}
              </Box>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '28px',
                marginTop: '30px',
              }}
            >
              <Button variant="contained" onClick={handlePrint}>
                {t('Print')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default PrintLabelModal
