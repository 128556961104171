// Create unique tracktable because both robot and testbed track table are very similar
import React, { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  CircularProgress,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { RobotTrackData } from '../../../../Store/MOCK_DATA'
import CustomSearch from '../../../Search/Search'
import { useTranslation } from 'react-i18next'
import client from '../../../../Config/axiosClient'
import { useOktaAuth } from '@okta/okta-react'
import { globalStatus } from '../../../../Constants/global'
import Store from '../../../../Store/Store'

const TrackTableRobot = () => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    'In Progress': false,
    Defined: false,
    'On Hold': false,
    'Ready for Investigation': false,
    'In Review': false,
  })
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')
  const { t } = useTranslation()
  const [fetching, setFetching] = useState(false)
  const [data, setData] = useState([])
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken

  const columns = [
    { id: 'robotName', label: t('RobotNameNoBreak'), minWidth: 'max-content' },
    {
      id: 'robotSN',
      label: t('RobotSN'),
      minWidth: 'max-content',
    },
    {
      id: 'createdDate',
      label: t('OnboardedDate'),
      minWidth: 'max-content',
    },
    { id: 'testName', label: t('TestNameNoBreak'), minWidth: 'max-content' },
    {
      id: 'testbed',
      label: t('Testbed'),
      minWidth: 'max-content',
    },
    {
      id: 'status',
      label: t('Status'),
      minWidth: 'max-content',
    },
  ]

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      const res = await client.get('/robot', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      let data = []
      for (const item of res.data) {
        const robotName = item.DisplayName
        const robotSN = item.RobotSN
        const blid = item.BLID
        const createdDate = item.CreatedDate
        const testName = item.Test[0].DisplayName ? item.Test[0].DisplayName : ''
        const testbed = item.Testbed[0].Name ? item.Testbed[0].Name : ''
        const status = item.RobotStatus[0].Name
        const robot_id = item.PK_Robot
        const activeStatuses = [
          ...new Set(
            ...[
              item.RobotStatus.map(entry => {
                return entry.Name
              }),
            ]
          ),
        ]
        const allTests = [
          ...new Set(
            ...[
              item.Test.map(entry => {
                return entry.DisplayName
              }),
            ]
          ),
        ]
        data.push({
          robotName: robotName,
          robotSN: robotSN,
          blid: blid,
          createdDate: createdDate,
          testName: testName,
          testbed: testbed,
          status: status,
          robot_id: robot_id,
          activeStatuses: activeStatuses,
          allTests: allTests,
        })
      }
      setData(data)
      setFetching(false)
    }
    getData()
  }, [idToken])

  // Data from API
  let rows = data
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClick = (event, row) => {
    console.log('row')
    console.log(row)
    Store.assetTracking.resetRowData()
    Store.assetTracking.setRowData(row)
    history.push('/app/robot/id')
  }

  const getStatusColor = value => {
    if (globalStatus.warning.values.robot.includes(value)) {
      return globalStatus.warning.themeColor
    }
    if (globalStatus.caution.values.robot.includes(value)) {
      return globalStatus.caution.themeColor
    }
    if (globalStatus.info.values.robot.includes(value)) {
      return globalStatus.info.themeColor
    }
    if (globalStatus.success.values.robot.includes(value)) {
      return globalStatus.success.themeColor
    }
    return 'black'
  }

  const setTextBold = column => {
    if (column === 'status') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const dateFormater = cell => {
    // console.log(JSON.stringify(date));
    if (!cell) {
      return null
    }
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatedDate
  }

  const onFilterChanged = name => event => {
    setFilters({ ...filters, [name]: event.target.checked })
  }

  const filterArray = Object.keys(filters)
  const filteredRows = rows.filter(row => {
    const filterKeyTrue = filterArray.some(filterKey => {
      return filterKey === row.status && filters[filterKey] === true
    })
    if (filterKeyTrue) {
      return row
    } else {
      return null
    }
  })

  if (filteredRows.length > 0) {
    rows = filteredRows
  }

  // Search
  function handleSearchUpdate(value) {
    setSearch(value)
  }

  rows = rows.filter(row => {
    if (search === '') {
      return row
    }
    return (
      row.robotName.toLowerCase().includes(search.toLowerCase()) ||
      row.testName.toLowerCase().includes(search.toLowerCase()) ||
      row.testbed.toLowerCase().includes(search.toLowerCase()) ||
      row.status.toLowerCase().includes(search.toLowerCase())
    )
  })

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const filterNames = ['available', 'bit', 'error', 'needs_repair', 'under_repair']
  const filterList = filterNames.map(item => {
    return (
      <FormControlLabel
        key={`formControlLabel-${item}`}
        value="end"
        control={<Checkbox checked={filters[item]} onChange={onFilterChanged(item)} value={item} />}
        label={t(item)}
        labelPlacement="end"
      />
    )
  })

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      let statusColor = ''
      let boldText = ''
      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={`robot-${index}`}
          onClick={() => handleClick(event, row)}
          sx={{ cursor: 'pointer' }}
        >
          {columns.map(column => {
            let value = row[column.id]
            if (column.id === 'createdDate') {
              value = dateFormater(value)
            }
            statusColor = getStatusColor(value)
            boldText = setTextBold(column.id)
            return (
              <TableCell key={column.id} sx={{ color: `${statusColor}`, fontWeight: `${boldText}` }}>
                {t(value)}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        {/* Toolbar */}
        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 1 }}>
          <Stack direction="row" alignItems="center" sx={{ mx: 2 }}>
            <Typography variant="modal2" sx={{ mr: 2 }}>
              {t('Filterbystatus')}:
            </Typography>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {filterList}
              </FormGroup>
            </FormControl>
          </Stack>
          <Box sx={{ minWidth: '20em', flexGrow: '2' }}>
            <CustomSearch search={search} handleSearchUpdate={handleSearchUpdate} />
          </Box>
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    sortDirection={orderBy === column.id ? order : false}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}

export default TrackTableRobot
