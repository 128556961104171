import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'

class UserProfileStore {
  // observables
  activityHistoryData = []
  settings = {
    layout: 'Square',
    notifications: 'Slack',
    changesNotifications: false,
    errorNotifications: false,
  }

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'UserProfileStore',
      properties: ['activityHistoryData', 'settings'],
      storage: window.localStorage,
    })
  }

  // action
  setActivityHistoryData = activityHistoryData => {
    this.activityHistoryData = activityHistoryData
  }

  setSettingsData = settings => {
    this.settings = settings
  }

  // actions change observables!
}

export default UserProfileStore
