import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Box, Container, Paper, Step, Stepper, StepLabel, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import RobotInfoStep from './RobotInfoStep'
import BiTStep from './BiTStep'
import ComponentStep from './ComponentsStep'
import VerifyStep from './VerifyStep'
import SuccessStep from './SuccessStep'
import RobotSavingModal from '../../Modal/RobotSavingModal'
import PrintLabelModal from '../../Modal/PrintLabelModal'
import RobotNotFound from '../../Modal/RobotNotFound'
import RUTOnboarded from '../../Modal/RUTOnboarded'
import BitFileError from '../../Modal/BitFileError'
import ErrorModal from '../../Modal/ErrorModal'
import Store from '../../../../Store/Store'
import DataWillBeLostModal from '../../Modal/DataWillBeLostModal'
import client from '../../../../Config/axiosClient'
//import sand04client from '../../../../Config/sand04client'
import { useTranslation } from 'react-i18next'
import { StoreMallDirectory } from '@mui/icons-material'

// const steps = ['Enter Robot Info', 'Upload BiT Results', 'Edit Components', 'Verify Input', 'Print Label']
const steps = ['Enter Robot Info', 'Upload BiT Results', 'Verify Input', 'Print Label']

const RobotOnboarding = observer(() => {
  const { enqueueSnackbar } = useSnackbar()
  const [activeStep, setActiveStep] = useState(Store.onboardingStore.activeStep)
  const [contentSize, setContentSize] = useState(Store.onboardingStore.contentSize)
  const [openRobotSavingModal, setOpenRobotSavingModal] = useState(false)
  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false)
  const [openRobotNotFound, setOpenRobotNotFound] = useState(false)
  const [openRUTOnboarded, setOpenRUTOnboarded] = useState(false)
  const [openBitError, setOpenBitError] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [cantContinue, setCantContinue] = useState(true)
  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState(
    Store.onboardingStore.bitFileComments ? Store.onboardingStore.bitFileComments : ''
  )
  const [uploadFile, setUploadFile] = useState([])
  const [fileName, setFileName] = useState(Store.onboardingStore.bitFilePath ? Store.onboardingStore.bitFilePath : '')
  const [serial, setSerial] = useState(
    Store.onboardingStore.robotData[0].RobotSN ? Store.onboardingStore.robotData[0].RobotSN : ''
  )
  const [test, setTest] = useState(Store.onboardingStore.selectedTest ? Store.onboardingStore.selectedTestHelper : '')
  const [robot, setRobot] = useState(
    Store.onboardingStore.selectedRobot ? Store.onboardingStore.selectedRobotHelper : ''
  )
  const [isBlocked, setIsBlocked] = useState(Store.onboardingStore.isBlocked)
  const [status, setStatus] = useState(0)

  const { authState } = useOktaAuth()
  const history = useHistory()
  const idToken = authState.idToken.idToken
  let blid = Store.onboardingStore.robotData[0]['BLID']
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleIsBlocked = value => {
    setIsBlocked(value)
    Store.onboardingStore.setIsBlocked(value)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    // WHILE COMPONENTS ARE DISABLED...
    //handleSize(activeStep + 1)
    setCantContinue(true)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    // WHILE COMPONENTS ARE DISABLED...
    //handleSize(activeStep - 1)
    setCantContinue(true)
  }

  const handleContinue = value => {
    // NOTE: false means user CAN continue, true means users CANT
    //For testing, don't check validation
    //setCantContinue(value)
    setCantContinue(false)
  }

  const handleSize = stepNumber => {
    if (stepNumber === 0 || stepNumber === 1 || stepNumber === 3 || stepNumber === 4) {
      setContentSize('sm')
      Store.onboardingStore.setContentSize('sm')
    }
    if (stepNumber === 2) {
      setContentSize('lg')
      Store.onboardingStore.setContentSize('lg')
    }
    Store.onboardingStore.setActiveStep(stepNumber)
  }

  const handleClose = () => {
    setOpenRobotSavingModal(false)
    setOpenPrintLabelModal(false)
    setOpenRobotNotFound(false)
    setOpenRUTOnboarded(false)
    setOpenBitError(false)
    setOpenErrorModal(false)
  }

  const getTest = test => {
    setTest(test)
  }

  const getRobot = robot => {
    setRobot(robot)
  }

  const getSerial = serial => {
    setSerial(serial)
  }

  const checkStatus = data => {
    // If data is empty [] open modal
    // Serial number example: 1234
    if (data.length == 0) {
      setOpenRobotNotFound(true)
      setLoading(false)
    } else {
      // If already onboarded open modal
      // Serial number example: RS1
      if (data[0].Onboarded) {
        setOpenRUTOnboarded(true)
        setLoading(false)
      }

      // If serial number is ok and robot is not onboarded continue to next step
      // Serial number example: RS2
      if (!data[0].Onboarded) {
        Store.onboardingStore.setRobotInfoData(data[0])
        Store.onboardingStore.setTestHelper(test)
        Store.onboardingStore.setRobotHelper(robot)
        setLoading(false)
        handleNext()
      }
    }
  }

  const checkSerial = async () => {
    if (
      serial === Store.onboardingStore.robotData[0].RobotSN &&
      test === Store.onboardingStore.selectedTestHelper &&
      robot === Store.onboardingStore.selectedRobotHelper
    ) {
      handleNext()
    } else if (
      (!serial && Store.onboardingStore.robotData[0].RobotSN) ||
      (!test && Store.onboardingStore.selectedTestHelper) ||
      (!robot && Store.onboardingStore.selectedRobotHelper)
    ) {
      // After reloading the page, we dont have a serial, but we have the stored serial
      handleNext()
    } else {
      setLoading(true)
      const res = await client.post(
        `/robot/search`,
        {
          serial_number: serial,
          test_id: Store.onboardingStore.selectedTest.id,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      // console.log(res.data)
      checkStatus(res.data)
    }
  }

  const fillFormData = event => {
    setUploadFile(event.target.files[0])
    setFileName(event.target.files[0].name)
  }

  const fillCommentsFormData = comments => {
    setComments(comments)
  }

  const handleUploadFile = async () => {
    if (fileName === Store.onboardingStore.bitFilePath && comments === Store.onboardingStore.bitFileComments) {
      handleNext()
    } else {
      setLoading(true)
      let formData = new FormData()
      formData.append('upload_file', uploadFile)
      formData.append('filename', fileName)
      formData.append('comments', comments)
      formData.append('blid', blid)
      console.log(Store.accountInfo.userPK)
      formData.append('userPK', Store.accountInfo.userPK)

      const res = await client({
        method: 'post',
        url: `/bitfile`,
        data: formData,
        headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'multipart/form-data' },
      }).catch(error => {
        setStatus(error.request.status)
        setOpenBitError(true)
        setLoading(false)
      })

      if (res) {
        setLoading(false)
        Store.onboardingStore.setBitFile(fileName)
        Store.onboardingStore.setComments(comments)
        handleNext()
      }
    }
  }

  const handleRobotSaving = async () => {
    // This may be trying to fire multiple times...
    setLoading(true)
    setOpenRobotSavingModal(true)
    // Remove unnecessary status from selectedTest (FOR NOW)
    let user_id = Store.accountInfo.userPK
    let selectedTest = Store.onboardingStore.selectedTest
    delete selectedTest.status
    let body = {
      user_id: user_id,
      robot_id: blid,
      blid: blid,
      serial: serial,
      onboard_data: Store.onboardingStore.robotData[0],
      //test: selectedTest,
      test: Store.onboardingStore.selectedRobot,
      jira_id: Store.onboardingStore.selectedRobot.id,
      jira_key: Store.onboardingStore.selectedRobot.key,
    }
    // console.log('BODY')
    // console.log(body)
    const res = await client
      .post(`/robot/onboard`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        setOpenRobotSavingModal(false)
      })

    if (res?.status === 200) {
      // console.log('RES')
      // console.log(res)
      setLoading(false)
      setOpenRobotSavingModal(false)
      handleIsBlocked(false)
      let onboarding_errors = ['RobotNotProvisioned', 'RobotAlreadyOnboarded']
      if (onboarding_errors.includes(res.data['message'])) {
        setStatus(res.data['message'])
        // console.log(res.data['message'])
        // console.log(status)
        setOpenErrorModal(true)
      }
      handleNext()
    }
  }

  // const handleOpenPrintLabelModal = () => setOpenPrintLabelModal(true)

  const handlePrintLabel = () => {
    // console.log('Store.onboardingStore')
    // console.log(Store.onboardingStore)
    const getLabel = async () => {
      console.log('in async')
      const date = new Date().toISOString()
      let sn = Store.onboardingStore.robotData[0].RobotSN
      let blid = Store.onboardingStore.robotData[0].BLID
      let robotName = Store.onboardingStore.selectedRobotHelper
      let body = JSON.stringify({
        RobotSN: sn,
        BLID: blid,
        RobotName: robotName,
        CreatedDate: date,
      })
      body = body.replace('"', '"') // Why did I do this?
      const data = { body: body }
      // let res = await sand04client.post(
      let res = await client.post('/robot/label', data, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      // console.log('res.data')
      // console.log(res.data)
      window.open(res.data.url)
      handleClose()
      enqueueSnackbar(`${robotName} successfully onboarded.`, { variant: 'success' })
    }
    getLabel()
  }

  /* Unnecessary, but leave for now, just in case
  const handleFinish = async () => {
    setLoading(true)
    let selectedTest = Store.onboardingStore.selectedTest
    delete selectedTest.status
    let body = {
      robot_id: blid,
      onboard_data: Store.onboardingStore.onboardedData,
      test: selectedTest,
      jira_id: Store.onboardingStore.selectedRobot.id,
      jira_key: Store.onboardingStore.selectedRobot.key,
    }
    const res = await client
      .post(`/robot/onboard`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })

    if (res?.status === 200) {
      setLoading(false)
      handleIsBlocked(false)
      Store.onboardingStore.clearPersistedStore()
      history.push('/app')
    }
  }
  */

  const stepsLength = steps.length - 1
  const errorMessage = t('ExitOnboardingError')

  return (
    <div>
      <Container maxWidth="sm">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Container>
      <Container maxWidth={contentSize}>
        <Paper sx={{ mt: 4 }}>
          {activeStep === 0 && (
            <RobotInfoStep
              getTest={getTest}
              getRobot={getRobot}
              getSerial={getSerial}
              handleContinue={handleContinue}
              handleBlock={handleIsBlocked}
            />
          )}
          {activeStep === 1 && (
            <BiTStep
              handleContinue={handleContinue}
              fillFormData={fillFormData}
              fillCommentsFormData={fillCommentsFormData}
            />
          )}
          {/* {activeStep === 2 && <ComponentStep handleContinue={handleContinue} />}
          {activeStep === 3 && <VerifyStep handleContinue={handleContinue} />}
          {activeStep === 4 && <SuccessStep />} */}
          {activeStep === 2 && <VerifyStep handleContinue={handleContinue} />}
          {activeStep === 3 && <SuccessStep />} 
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {activeStep > 0 && activeStep !== stepsLength && (
              <Button variant="contained" color="greyGreen" sx={{ m: '25px 4px' }} onClick={handleBack}>
                {t('Back')}
              </Button>
            )}
            {activeStep === 0 && activeStep !== stepsLength && (
              <LoadingButton
                onClick={checkSerial}
                loading={loading}
                disabled={cantContinue}
                variant="contained"
                sx={{ m: '25px 4px' }}
              >
                {t('Next')}
              </LoadingButton>
            )}
            {activeStep === 1 && activeStep !== stepsLength && (
              <LoadingButton
                onClick={handleUploadFile}
                loading={loading}
                disabled={cantContinue}
                variant="contained"
                sx={{ m: '25px 4px' }}
              >
                {t('Next')}
              </LoadingButton>
            )}
            {/* {activeStep === 2 && activeStep !== stepsLength && (
              <Button variant="contained" sx={{ m: '25px 4px' }} onClick={handleNext} disabled={cantContinue}>
                {t('Next')}
              </Button>
            )} */}
            {activeStep === 2 && activeStep !== stepsLength && (
              <LoadingButton variant="contained" loading={loading} onClick={handleRobotSaving}>
                {t('YesRobotDataIsCorrect')}
              </LoadingButton>
            )}
            {activeStep === 3 && activeStep === stepsLength && (
              <Button variant="contained" color="alertPurple" sx={{ m: '25px 4px' }} onClick={handlePrintLabel}>
                {t('PrintLabel')}
              </Button>
            )}
          </div>
          <RobotSavingModal open={openRobotSavingModal} handleClose={handleClose} />
          <PrintLabelModal open={openPrintLabelModal} handleClose={handleClose} />
          <RobotNotFound open={openRobotNotFound} handleClose={handleClose} />
          <RUTOnboarded open={openRUTOnboarded} handleClose={handleClose} />
          <BitFileError open={openBitError} handleClose={handleClose} status={status} />
          <ErrorModal open={openErrorModal} handleClose={handleClose} status={status} />
        </Paper>
      </Container>
      <DataWillBeLostModal isBlocked={isBlocked} errorMessage={errorMessage} />
    </div>
  )
})

export default RobotOnboarding
