import { useState } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  CircularProgress,
  Stack,
  Typography,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomSearch from '../../../Search/Search'
import { globalStatus } from '../../../../Constants/global'

const RobotHistory = observer(props => {
  const { t } = useTranslation()
  let rows = props.rows
  const fetching = props.fetching
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('createdDate')
  const [filters, setFilters] = useState({
    running: false,
    stopped: false,
    battery: false,
    bit: false,
    error: false,
    needs_repair: false,
  })
  const [search, setSearch] = useState('')

  const columns = [
    { id: 'status', label: t('Status'), minWidth: 170 },
    {
      id: 'createdDate',
      label: t('CreatedDate'),
      minWidth: 'max-content',
    },
    {
      id: 'createdBy',
      label: t('CreatedBy'),
      minWidth: 'max-content',
    },
    {
      id: 'endDate',
      label: t('EndDate'),
      minWidth: 'max-content',
    },
    {
      id: 'endedBy',
      label: t('EndedBy'),
      minWidth: 'max-content',
    },
  ]

  // Formatting
  const getStatusColor = value => {
    if (globalStatus.warning.values.robot.includes(value)) {
      return globalStatus.warning.themeColor
    }
    if (globalStatus.caution.values.robot.includes(value)) {
      return globalStatus.caution.themeColor
    }
    if (globalStatus.info.values.robot.includes(value)) {
      return globalStatus.info.themeColor
    }
    if (globalStatus.success.values.robot.includes(value)) {
      return globalStatus.success.themeColor
    }
    return 'black'
  }

  const setTextBold = column => {
    if (column === 'status') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const dateFormater = cell => {
    // console.log(JSON.stringify(date));
    if (!cell) {
      return null
    }
    const dateTime = new Date(cell)
    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
    return formatedDate
  }

  // Page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // Filtering
  const onFilterChanged = name => event => {
    setFilters({ ...filters, [name]: event.target.checked })
  }

  const filterArray = Object.keys(filters)

  const filterList = filterArray.map(item => {
    return (
      <FormControlLabel
        key={`formControlLabel-${item}`}
        value="end"
        control={<Checkbox checked={filters[item]} onChange={onFilterChanged(item)} value={item} />}
        label={t(item)}
        labelPlacement="end"
      />
    )
  })

  const filteredRows = rows.filter(row => {
    const filterKeyTrue = filterArray.some(filterKey => {
      return filterKey === row.status && filters[filterKey] === true
    })
    if (filterKeyTrue) {
      return row
    } else {
      return null
    }
  })

  // const filteredRows = rows.filter(row => {
  //   if (filters.current && row.endDate == null) {
  //     return row
  //   }
  //   if (filters.previous && row.endDate) {
  //     return row
  //   }
  //   return null
  // })

  if (filteredRows.length > 0) {
    rows = filteredRows
  }

  // Search
  function handleSearchUpdate(value) {
    setSearch(value)
  }

  rows = rows.filter(row => {
    if (search === '') {
      return rows
    }
    return (
      row.status.toLowerCase().includes(search.toLowerCase()) ||
      row.createdBy.toLowerCase().includes(search.toLowerCase()) ||
      row.endedBy.toLowerCase().includes(search.toLowerCase())
    )
  })

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      let statusColor = ''
      let boldText = ''
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={`history-${index}`}>
          {columns.map(column => {
            let value = row[column.id]
            if (column.id === 'createdDate' || column.id === 'endDate') {
              value = dateFormater(value)
            }
            statusColor = getStatusColor(value)
            boldText = setTextBold(column.id)
            return (
              <TableCell key={column.id} sx={{ color: `${statusColor}`, fontWeight: `${boldText}` }}>
                {t(value)}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 1 }}>
        <Stack direction="row" alignItems="center" sx={{ mx: 2 }}>
          <Typography sx={{ mr: 2 }}>{t('Filterby')}:</Typography>
          <FormControl component="fieldset">
            <FormGroup row>{filterList}</FormGroup>
          </FormControl>
        </Stack>
        <Box sx={{ minWidth: '20em', flexGrow: '2' }}>
          <CustomSearch search={search} handleSearchUpdate={handleSearchUpdate} />
        </Box>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  sortDirection={orderBy === column.id ? order : false}
                  key={`history-${column.id}`}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
})

export default RobotHistory
