import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Container, FormControl, Grid, Paper, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import client from '../../../Config/axiosClient'
import Store from '../../../Store/Store'
import SaveTestbedModal from '../Modal/SaveTestbedModal'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/system'

const EditBatteryVariant = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  const defaultVoltage = 14.8
  const defaultCapacity = 0
  const voltageMin = 0
  const voltageMax = 99
  const capacityMin = 0
  const capacityMax = 99999
  const user = Store.accountInfo.userPK
  const [variant, setVariant] = useState('')
  const [voltage, setVoltage] = useState(defaultVoltage)
  const [capacity, setCapacity] = useState(defaultCapacity)

  const [error, setError] = useState({
    variantError: false,
    voltageError: false,
    capacityError: false,
  })

  const openModal = Store.openModal
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  // Set selected variant
  const handleVariant = event => {
    setVariant(event.target.value)
  }

  // Set voltage field
  const handleVoltage = event => {
    let value = event.target.value
    if (value < voltageMin) {
      value = voltageMin
    }
    if (value > voltageMax) {
      value = voltageMax
    }
    setVoltage(Number(value).toFixed(1))
  }

  // Set capacity field
  const handleCapacity = event => {
    let value = event.target.value
    if (value < capacityMin) {
      value = capacityMin
    }
    if (value > capacityMax) {
      value = capacityMax
    }
    setCapacity(Number(value).toFixed(0))
  }

  // Modal stuff
  const modal = (type, message) => {
    setModalType(type)
    setModalMessage(message)
    Store.setOpenModal(true) // this must come first
    setShowModal(true) // this must come second
  }

  useEffect(() => {
    if (openModal) {
      setShowModal(true)
    } else if (!openModal) {
      setShowModal(false)
    }
  }, [openModal])

  // Error handling
  const handleError = (event, from) => {
    if (from === 'variant-text') {
      const errorType = 'variantError'
      checkValidation(event, errorType)
    } else if (from === 'voltage-text') {
      const errorType = 'voltageError'
      checkValidation(event, errorType)
    } else if (from === 'capacity-text') {
      const errorType = 'capacityError'
      checkValidation(event, errorType)
    }
  }

  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  // Cancel button
  const handleCancel = () => {
    reset()
    history.push('/app')
  }

  // Submit button
  useEffect(() => {
    if (variant && voltage && capacity) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [variant, voltage, capacity])

  const check_variant = async () => {
    const res = await client
      .get('/battery/variant', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      let exists = res.data.find(item => {
        if (item.Name === variant.toLowerCase().replace(/\s+/g, '_')) {
          return true
        } else {
          return false
        }
      })
      if (exists) {
        enqueueSnackbar(t('BatteryVariantExists'), { variant: 'error' })
        modal('error', t('BatteryVariantExists'))
        throw new Error(`Battery variant ${variant} already exists`)
      }
      enqueueSnackbar(t('BatteryVariantAvailable'), { variant: 'success' })
      return true
    }
  }

  const create_variant = async () => {
    const name = variant.toLowerCase().replace(/\s+/g, '_')
    const displayName = variant
    const displayOrder = 0
    let body = {
      name: name,
      display_name: displayName,
      voltage: voltage,
      capacity: capacity,
      display_order: displayOrder,
      fk_user: user,
    }
    const res = await client
      .post(`/battery_variant`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('BatteryVariantCreateError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      enqueueSnackbar(t('BatteryVariantCreated'), { variant: 'success' })
      const message = t('BatteryVariantCreated')
      modal('success', message)
      return true
    }
  }

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true)
      await check_variant()
      await create_variant()
      setSubmitLoading(false)
      reset()
    } catch (error) {
      console.log(error)
      setSubmitLoading(false)
    }
  }

  // Clear the form
  const reset = () => {
    setVariant('')
    setVoltage(defaultVoltage)
    setCapacity(defaultCapacity)
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('AddBatteryVariant')}
      </Typography>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        <Typography variant="modal1">{t('AddBatteryVariantLong')}</Typography>
      </Box>

      <Container maxWidth="sm">
        <Paper>
          {/* <Grid container direction="column" justifyContent="space-evenly" sx={{ p: '35px' }}> */}
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{t('VariantName')}</Typography>
              <TextField
                required
                id="variant-text"
                label={t('VariantName')}
                onChange={handleVariant}
                value={variant}
                error={error.variantError}
                onBlur={event => handleError(event, 'variant-text')}
              />

              <Typography variant="subtitle1">{t('VoltageUnit')}</Typography>
              <TextField
                required
                type="number"
                inputProps={{ min: voltageMin, max: voltageMax, step: 0.1 }}
                id="voltage-text"
                label={t('VoltageUnit')}
                onChange={handleVoltage}
                value={voltage}
                error={error.voltageError}
                onBlur={event => handleError(event, 'voltage-text')}
              />

              <Typography variant="subtitle1">{t('BatteryCapacityUnit')}</Typography>
              <TextField
                required
                type="number"
                inputProps={{ min: capacityMin, max: capacityMax }}
                id="capacity-text"
                label={t('BatteryCapacityUnit')}
                onChange={handleCapacity}
                value={capacity}
                error={error.capacityError}
                onBlur={event => handleError(event, 'capacity-text')}
              />
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button onClick={handleCancel} variant="contained" color="greyGreen">
                {t('Cancel')}
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                disabled={submitDisabled}
                loading={submitLoading}
                variant="contained"
              >
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
      <SaveTestbedModal showModal={showModal} modalType={modalType} modalMessage={modalMessage} />
    </Box>
  )
}

export default EditBatteryVariant
