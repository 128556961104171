import { useState } from 'react'
import { observer } from 'mobx-react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Container, Stack } from '@mui/system'
import { Button, Paper, TextField, Typography } from '@mui/material'
import { Error } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'

const ErrorDetails = observer(() => {
  const { authState } = useOktaAuth()
  const { t } = useTranslation()
  const history = useHistory()
  const [comment, setComment] = useState('')
  const [loadingResolved, setLoadingResolved] = useState(false)
  const [loadingNeedsRepair, setloadingNeedsRepair] = useState(false)
  const errorCode = Store.testingDataStore.errorDetails.Code
  const errorTitle = Store.testingDataStore.errorDetails.Name
  const errorDescription = Store.testingDataStore.errorDetails.Description
  const profile_id = Store.testingDataStore.errorDetails.ErrorProfile.PK_ErrorProfile
  const object_name = Store.testingDataStore.errorDetails.ErrorProfile.ObjectName
  const object_id = Store.testingDataStore.errorDetails.ErrorProfile.ObjectID
  const user_id = Store.accountInfo.userPK

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  async function create_status(status_name) {
    let body = {
      robot_id: object_id,
      status_name: status_name,
      user_id: user_id,
    }
    const res = await client
      .post('/robot/status', body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return res.data
    } else {
      throw new Error('Bad response from create_status')
    }
  }

  async function close_error() {
    let body = {
      profile_id: profile_id,
      object_name: object_name,
      object_id: object_id,
      user_id: user_id,
    }
    const res = await client
      .post('/error/close', body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return res.data
    } else {
      throw new Error('Bad response from close_error')
    }
  }

  const goBack = () => {
    history.push(history.location.state.from)
  }

  // Cancel button
  function handleCancel() {
    goBack()
  }

  async function handleNeedsRepair() {
    try {
      setloadingNeedsRepair(true)
      await create_status('needs_repair')
      setloadingNeedsRepair(false)
      goBack()
    } catch (error) {
      setloadingNeedsRepair(false)
      console.log(error)
    }
  }

  async function handleResolved() {
    try {
      setLoadingResolved(true)
      await close_error()
      setLoadingResolved(false)
      goBack()
    } catch (error) {
      setLoadingResolved(false)
      console.log(error)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('ErrorDetails')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Error fontSize="large" sx={{ display: 'flex', justifySelf: 'flex-end' }} />
              <Typography variant="modal4">{`${t('Error')} ${errorCode ? errorCode : ''}`}</Typography>

              <span></span>
              <Stack>
                <Typography variant="modal1">{errorTitle}</Typography>
                <Typography>{errorDescription}</Typography>
              </Stack>

              {/* <Typography variant="subtitle1">{t('Comments')}</Typography>
              <TextField
                autoFocus
                required
                id="comment-text"
                label={t('Comments')}
                value={comment}
                // onChange={e => handleCommentChange(e.target.value)}
                // error={error.robotError}
                // onBlur={event => handleError(event, 'robot-text')}
              /> */}
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" color="greyGreen" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                color="alertPurple"
                onClick={handleNeedsRepair}
                loading={loadingNeedsRepair}
              >
                {t('NeedsRepair')}
              </LoadingButton>
              <LoadingButton variant="contained" onClick={handleResolved} loading={loadingResolved}>
                {t('Resolved')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
})

export default ErrorDetails
