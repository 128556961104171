import React from 'react'
import { observer } from 'mobx-react'
import { Typography, Grid } from '@mui/material'
import Store from '../../../../Store/Store'
import { useTranslation } from 'react-i18next'

const VerifyStep = observer(() => {
  console.log('VerifyStep onboardingstore')
  console.log(Store.onboardingStore)
  const robotData = Store.onboardingStore.robotData[0]
  const selectedRobotData = Store.onboardingStore.selectedRobot
  const { t } = useTranslation()

  return (
    <div>
      <Grid container direction="column" sx={{ p: '40px 40px 0px' }}>
        <Typography variant="modal1" align="center" gutterBottom color="#6CB86A">
          {t('VerifyRobotData')}
        </Typography>
        <div className="success-text-container-top">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('RobotName')}
          </Typography>
          <Typography variant="modal2" component="div">
            {robotData.RobotName}
          </Typography>
        </div>
        <div className="success-text-container-bottom">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('Serialnumber')}
          </Typography>
          <Typography variant="modal2" component="div">
            {robotData.RobotSN}
          </Typography>
        </div>
        <div className="success-text-container-bottom">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('BLID')}
          </Typography>
          <Typography variant="modal2" component="div">
            {robotData.BLID}
          </Typography>
        </div>
        {/* <div className="success-text-container-top">
          <Typography variant="modal1" sx={{ mr: '36px', minWidth: '106px' }}>
            {t('Model')}
          </Typography>
          <Typography variant="modal2" component="div">
            {robotData.ModelName}
          </Typography>
        </div> */}
      </Grid>
    </div>
  )
})

export default VerifyStep
