export const TRANSLATIONS_MS = {
  ActivityHistory: 'Activity History',
  Add: 'Add',
  AddBattery: 'Add Battery',
  AddRobot: 'Add Robot',
  Addacomment: 'Add a comment',
  Addcommentforcomponent: 'Add comment for component',
  AddonTests: 'Add-on Tests',
  AllRepairs: 'All Repairs',
  AlternativeText1: 'Alternative text - include a link ',
  AlternativeText2: 'to the PDF!',
  AssetTracking: 'Asset Tracking',
  Assign: 'Assign',
  AssignRUTToTehnician: 'Assign RUT to a repair techinician',
  Assigned: 'Assigned',
  BLID: 'BLID',
  Back: 'Back',
  Battery: 'Battery',
  BatteryCapacity: 'Battery Capacity',
  BatteryOnboardSucces: 'Battery was onboarded successfully.',
  BatterySN: 'Battery S/N',
  BatterySOC: 'Battery SOC',
  BiT: 'BiT',
  BiTResults: 'BiT Results',
  Bit: 'Bit',
  BitTest: 'Bit Test',
  BitUploadError: 'BiT file upload Error',
  Browse: 'Browse',
  BuildSerialLot: 'Build/Lot/Serial Number',
  Cancel: 'Cancel',
  ChangeBattery: 'Please change the battery.',
  ChangesIveMade: 'Changes I have made',
  CheckComment: 'Check if the comments have only allowed characters (ASCII).',
  CheckComponentDeletion: 'Are you sure you want to delete this component?',
  CheckConnection: 'Please check your connection and try again.',
  CheckUpload: 'Check if you have uploaded the correct fiFle.',
  Cleaning: 'Cleaning',
  ClearRoomba: "Clear Roomba's debris extractors, then press CLEAN to restart.",
  Close: 'Close',
  Comment: 'Comment',
  Comments: 'Comments',
  CompleteCleaning: 'Complete Cleaning',
  'CompleteCleaning?': 'Complete Cleaning?',
  CompleteRepair: 'Complete Repair',
  'CompleteRepair?': 'Complete Repair?',
  Component: 'Component',
  ContinueTesting: 'Continue Testing',
  Date: 'Date',
  DateTime: 'Date/Time',
  Debrisextractorsstuck: 'Debris extractors stuck',
  Defined: 'Defined',
  Delete: 'Delete',
  Deletingcomponent: 'Deleting component!',
  Done: 'Done',
  Duedate: 'Due date',
  Edit: 'Edit',
  Editcommentforcomponent: 'Edit comment for component',
  Editcomponents: 'Edit components',
  Empty: 'Empty',
  English: 'English',
  Error: 'Error',
  Error2: 'Error 2',
  ErrorReport: 'Error Report',
  Errors: 'Errors',
  Evaluation: 'Evaluation',
  ExitOnboardingError: 'You are about to exit onboarding process. All entered data will be lost.',
  Fail: 'Fail',
  Failuredescription: 'Failure description',
  Filterby: 'Filter by',
  Filterbystatus: 'Filter by status',
  Finish: 'Finish',
  Go: 'Go',
  Goback: 'Go back',
  History: 'History',
  Horizontallayout: 'Horizontal layout',
  InProgress: 'In Progress',
  InReview: 'In Review',
  InspectNote:
    'NOTE: If the inspection failed click on the button "Fail" and if the inspection passed click on the button "Pass"',
  InspectRUT: 'Inspect RUT',
  InspectRUTpriotTesting: 'Inspect the RUT prior to testing',
  InstallNewBattery: 'Install a new battery in the RUT.',
  Label: 'Label',
  Language: 'Language',
  Leavingthepage: 'Leaving the page',
  ListOfModules: 'List of modules/components',
  Loading: 'Loading',
  Location: 'Location',
  LogIn: 'Log In',
  LogOut: 'Log Out',
  Lowbattery: 'Low battery!',
  Malay: 'Malay',
  Missions: 'Missions',
  Model: 'Model',
  Module: 'Module',
  MyRepairs: 'My Repairs',
  Name: 'Name',
  NeedsCleaning: 'Needs Cleaning',
  NeedsRepair: 'Needs Repair',
  Networkerror: 'Network error',
  NewBattery: 'New Battery',
  Next: 'Next',
  NotConnected: 'It seems that you are not connected to internet',
  Notifications: 'Notifications',
  Notificationsettings: 'Notification settings',
  OK: 'OK',
  Occupied: 'Occupied',
  OldBattery: 'Old Battery',
  OnHold: 'On Hold',
  OnboardABattery: 'Onboard a Battery',
  Onboarding: 'Onboarding',
  OnboardingReadiness: 'Onboarding Readiness',
  Outlook: 'Outlook',
  Pass: 'Pass',
  Paused: 'Paused',
  Photos: 'Photos',
  Print: 'Print',
  PrintAndAffix: 'Print the label and affix it to the robot',
  PrintLabel: 'Print Label',
  PrintLabels: 'Print Labels',
  PriorToOnboarding: 'Prior to onboarding a robot, you will need',
  Profile: 'Profile',
  Progress: 'Progress',
  ProvisionAnother: 'Provision Another',
  Provisioning: 'Provisioning',
  RUT: 'RUT',
  RUTAlreadyOnboard: 'RUT Already Onboard',
  RUTalreadyonboarded: 'RUT already onboarded',
  Ready: 'Ready',
  ReadyforInvestigation: 'Ready for Investigation',
  ReliabilityGrowthTesting: 'Reliability Growth Testing',
  Removefromtestbed: 'Remove from test bed',
  Repair: 'Repair',
  RepairRobot: 'Repair Robot',
  Repairs: 'Repairs',
  RepairsOverview: 'Repairs Overview',
  ReportError: 'Report Error',
  Reporting: 'Reporting',
  RequestCleaning: 'Request Cleaning',
  'RequestCleaning?': 'Request Cleaning?',
  RequestRepair: 'Request Repair',
  Resolved: 'Resolved',
  Retire: 'Retire',
  Robot: 'Robot',
  RobotAlreadyOnboarded: 'The robot has already been onboarded.',
  RobotImageAlt: 'Robot image showing where to put the label',
  RobotName: 'Robot Name',
  RobotNameMustMatch: "The robot's name must match the one selected below.",
  RobotNameNoBreak: 'Robot Name',
  RobotNotFound: 'Robot Not Found',
  RobotNotProvisioned: 'Robot has not been provisioned yet',
  RobotOnboardSuccess: 'Robot was onboarded successfully.',
  RobotOnboardingSuccess: 'Robot was onboarded successfully.',
  RobotProvisionedSuccess: 'Robot was provisioned successfully.',
  RobotSN: 'Robot S/N',
  RobotStatus: 'Robot Status',
  RobotconnectedtoWiFi: 'Robot connected to WiFi',
  Robotname: 'Robot name',
  Robotnamedcorrectly: 'Robot named correctly',
  Robots: 'Robots',
  Running: 'Running',
  Runtime: 'Runtime',
  Save: 'Save',
  SaveAndExit: 'Save & Exit',
  Savechanges: 'Save changes',
  Savepassword: 'Save password',
  SavingRobotInformation: 'Saving Robot Information...',
  ScanRobotAndBattery: 'Scan the robot, old battery, and new battery.',
  ScanRobotSerial: "Scan the robot's serial number.",
  ScanRobotTestbed: 'Please scan the robot, battery and test bed',
  'Search…': 'Search…',
  SelectDifferentRobot: 'Please select a different robot to onboard.',
  SelectHowToReceiveNotifications: 'Select how you would like to receive your notifications',
  SelectNotifyAlerts: 'Select for which alerts you would like to get notified.',
  SelectTestAndRobot: 'Select the test and robot name that correspond to the robot being onboarded.',
  SelectWorkstation: 'Select a Workstation',
  SelectWorkstationLayout: 'Select your preferred workstation screen layout.',
  Sendrequest: 'Send request',
  Serialnumber: 'Serial Number',
  SettingsNotSaved: 'Error: Settings not saved',
  Settingsnotsaved: 'Settings not saved!',
  Show: 'Show',
  SignIn: 'Sign In',
  Slack: 'Slack',
  Snackbar: 'Snackbar',
  Software: 'Software',
  SomeError: 'Some error happened.',
  Somethingwentwrong: 'Something went wrong',
  SpareParts: 'Spare Parts',
  Squarelayout: 'Square layout',
  StartTest: 'Start Test',
  Status: 'Status',
  Subcomponent: 'Subcomponent',
  Submit: 'Submit',
  Success: 'Success',
  Supplier: 'Supplier',
  SwapBattery: 'Swap Battery',
  Technician: 'Technician',
  Technicianname: 'Technician name',
  Test: 'Test',
  Testbed: 'Testbed',
  Testbeds: 'Testbeds',
  TestIndividualComponents: 'Test individual components by adding them to a robot',
  TestModalScreens: 'Test Modal Screens',
  TestNameNoBreak: 'Test Name',
  Testing: 'Testing',
  Therobotnamewasnotfound: 'The robot name was not found.',
  Title: 'Title',
  TrackRobots: 'Track Robots',
  TrackSpareParts: 'Track Spare Parts',
  TrackTestbeds: 'Track Testbeds',
  Unassigned: 'Unassigned',
  UnderTest: 'Under Test',
  UploadBiTResults: 'Upload BiT Results',
  UseApp: 'Use the iRobot app to connect the robot to WiFi.',
  User: 'User',
  UserProfile: 'User Profile',
  UserSettings: 'User Settings',
  UserSettingsError: 'You are about to exit user settings without saving your changes',
  Userdetails: 'User details',
  Username: 'User name',
  Userrole: 'User role',
  Variant: 'Variant',
  VerifyRobotData: 'Verify that the information provided is correct.',
  VerifyRobotName: 'Verify that the robot name is correct.',
  WhenAnyErrors: 'When any errors of a certain type occur',
  WiFiPassword: 'WiFi Password',
  WiFipassword: 'WiFi password',
  WifiNetwork: 'WiFi Network',
  WifiNetworkname: 'WiFi Network name',
  WorkstationLayout: 'Workstation Layout',
  Yes: 'Si',
  YesRobotDataIsCorrect: 'Yes, the information is correct.',
  Youarenotsignedin: 'You are not signed in',
  hours: 'hours',
}
