import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class ProvisioningDataStore {
  // observables
  robotName = ''
  wifiNetwork = ''
  wifiPassword = ''
  savePassword = false

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'ProvisioningDataStore',
      properties: ['wifiNetwork', 'wifiPassword', 'savePassword'],
      storage: window.localStorage,
    })
  }

  // actions
  setRobotName = robotName => {
    this.robotName = robotName
  }

  setWifiNetwork = wifiNetwork => {
    this.wifiNetwork = wifiNetwork
  }

  setWifiPassword = wifiPassword => {
    this.wifiPassword = wifiPassword
  }

  setSavePassword = savePassword => {
    this.savePassword = savePassword
  }

  resetRobotName = () => {
    this.robotName = ''
  }

  resetProvisioningData = () => {
    this.setRobotName = ''
    this.setWifiNetwork = ''
    this.setWifiPassword = ''
    this.setSavePassword = false
    clearPersistedStore(this)
  }

  // actions change observables!
}

export default ProvisioningDataStore
