import React from 'react'
import { Backdrop, Modal, Fade, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import { Close } from '@mui/icons-material'

import Store from '../../../Store/Store'
import './CustomModal.css'
import Items from './ModalItems'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '609px',
    height: '338px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const CustomModal = observer(({ title }) => {
  const classes = useStyles()
  const openModal = Store.openModal
  const handleClose = () => Store.setOpenModal(false)

  const fromModal = true

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '20px', paddingBottom: '15.5px' }}
            >
              {title}
            </Typography>
            <div className="grid-content">
              <Items title={title} fromModal={fromModal} />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
})

export default CustomModal
