import React, { useState, useEffect } from 'react'
import { Typography, Grid, Button, styled, TextField } from '@mui/material'
import './BiTStep.css'
import { observer } from 'mobx-react'
import Store from '../../../../Store/Store'
import { useTranslation } from 'react-i18next'

const InputButton = styled('input')({
  display: 'none',
})

const BiTStep = observer(({ handleContinue, fillFormData, fillCommentsFormData }) => {
  const [filePath, setFilePath] = useState(Store.onboardingStore.bitFilePath ? Store.onboardingStore.bitFilePath : '')
  const [comments, setComments] = useState(
    Store.onboardingStore.bitFileComments ? Store.onboardingStore.bitFileComments : ''
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (filePath !== '') {
      handleContinue(false)
    }
  }, [filePath, handleContinue])

  const onInputChange = async event => {
    setFilePath(event.target.files[0].name)
    if (event.target.value !== '' && event.target.files) {
      handleContinue(false)
      fillFormData(event)
    } else {
      handleContinue(true)
    }
  }

  const handleUpdateField = e => {
    setComments(e.target.value)
    fillCommentsFormData(e.target.value)
  }

  return (
    <Grid container direction="column" sx={{ p: '40px 40px 0px' }}>
      <div className="title-field">
        <Typography variant="subtitle1" component="div">
          {t('BiTResults')}
        </Typography>
        <TextField id="standard-read-only-input" value={filePath} inputProps={{ readOnly: true }} variant="standard" />
        <label htmlFor="contained-button-file">
          <InputButton
            accept=".7z, .zip, .rar, .tar, .wim, .gzip, .bzip, .txt"
            id="contained-button-file"
            multiple
            type="file"
            onInput={onInputChange}
          />
          <Button variant="contained" component="span" color="greyGreen">
            {t('Browse')}
          </Button>
        </label>
      </div>
      <div className="comment-section">
        <Typography variant="subtitle1" component="div" sx={{ marginTop: '15px' }}>
          {t('Comments')}
        </Typography>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={6}
          value={comments}
          sx={{ minWidth: '350px' }}
          onChange={handleUpdateField}
        />
      </div>
    </Grid>
  )
})
export default BiTStep
