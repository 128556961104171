import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ProvisioningSuccess = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleProvision = () => {
    history.push('/app/provisioning')
  }

  const handleDone = () => {
    history.push('/app')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('Success')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Grid container direction="column" sx={{ p: '32px', m: '10px' }}>
            <Typography variant="modal1" align="center" gutterBottom>
              {t('RobotProvisionedSuccess')}
            </Typography>
            <div className="buttons-center">
              <Button variant="contained" color="greyGreen" sx={{ margin: '0px 4px' }} onClick={handleProvision}>
                {t('ProvisionAnother')}
              </Button>
              <Button variant="contained" sx={{ margin: '0px 4px' }} onClick={handleDone}>
                {t('Done')}
              </Button>
            </div>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}

export default ProvisioningSuccess
