import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  CircularProgress,
} from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import client from '../../../../Config/axiosClient'

const RobotBatteries = observer(props => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const robotPK = props.robot.PK_Robot
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('installDate')

  const columns = [
    {
      id: 'battery',
      label: t('Battery'),
      minWidth: 'max-content',
    },
    {
      id: 'installDate',
      label: t('InstallDate'),
      minWidth: 'max-content',
    },
    {
      id: 'installedBy',
      label: t('InstalledBy'),
      minWidth: 'max-content',
    },
    {
      id: 'removalDate',
      label: t('RemovalDate'),
      minWidth: 'max-content',
    },
    {
      id: 'removedBy',
      label: t('RemovedBy'),
      minWidth: 'max-content',
    },
  ]

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      if (!robotPK) {
        return
      }
      const res = await client
        .get(`/battery/profile/${robotPK}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetching(false)
        })
      let batteries = []
      for (const item of res.data) {
        const battery = item.Battery[0].BatterySN
        const installDate = item.CreatedDate
        const installedBy = item.User.find(o => o.PK_User === item.CreatedBy).Name
        const removalDate = item.RemovalDate
        const removedBy = item.RemovedBy ? item.User.find(o => o.PK_User === item.RemovedBy).Name : ''
        batteries.push({
          battery: battery,
          installDate: installDate,
          installedBy: installedBy,
          removalDate: removalDate,
          removedBy: removedBy,
        })
      }
      setData(batteries)
      setFetching(false)
    }
    getData()
  }, [idToken, robotPK, t, enqueueSnackbar])

  let rows = data

  // Formatting
  const setTextBold = column => {
    if (column === 'battery') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const dateFormater = cell => {
    // console.log(JSON.stringify(date));
    if (!cell) {
      return null
    }
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatedDate
  }

  // Page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      let statusColor = ''
      let boldText = ''
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={`battery-${index}`}>
          {columns.map(column => {
            let value = row[column.id]
            if (column.id === 'installDate' || column.id === 'removalDate') {
              value = dateFormater(value)
            }
            boldText = setTextBold(column.id)
            return (
              <TableCell key={column.id} sx={{ fontWeight: `${boldText}` }}>
                {t(value)}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  sortDirection={orderBy === column.id ? order : false}
                  key={`battery-${column.id}`}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
})

export default RobotBatteries
