import React, { useCallback, useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Grid, Button, FormControl, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import './StartRobotTest.css'
import { observer } from 'mobx-react'
import client from '../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import Store from '../../../Store/Store'
import { Stack } from '@mui/system'

const StartRobotTest = observer(() => {
  const [robot, setRobot] = useState('')
  const [battery, setBattery] = useState('')
  const [testbed, setTestbed] = useState('')
  const [testbed_sn, setTestbedSN] = useState('')
  const user = Store.accountInfo.userPK
  const { authState } = useOktaAuth()
  const history = useHistory()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const [error, setError] = useState({
    robotError: false,
    robotErrorHelperText: '',
    batteryError: false,
    batteryErrorHelperText: '',
    testbedError: false,
  })
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  // Error handling
  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  const checkFieldFormat = (event, errorType) => {
    if (errorType === 'robot-sn-length') {
      if (event.target.value.length !== 21) {
        setError(prevError => ({ ...prevError, robotError: true }))
        setError(prevError => ({ ...prevError, robotErrorHelperText: 'Robot Serial Number must be exactly 21 characters' }))  
      } else {
        setError(prevError => ({ ...prevError, robotError: false }))
        setError(prevError => ({ ...prevError, robotErrorHelperText: '' }))          
      }
    }
    if (errorType === 'battery-sn-length') {
      if (event.target.value.length !== 17) {
        setError(prevError => ({ ...prevError, batteryError: true }))
        setError(prevError => ({ ...prevError, batteryErrorHelperText: 'Battery Serial Number must be exactly 17 characters' }))  
      } else {
        setError(prevError => ({ ...prevError, batteryError: false }))
        setError(prevError => ({ ...prevError, batteryErrorHelperText: '' }))  
      }
    }
  }

  const handleError = (event, from) => {
    if (from === 'robot-sn-length' || from === 'battery-sn-length') {
      checkFieldFormat(event, from)
    }
    if (from === 'robot-text' || from === 'find_robot' || from === 'robot-check') {
      const errorType = 'robotError'
      checkValidation(event, errorType)
    }
    if (from === 'battery-text' || from === 'get_battery' || from === 'battery-check') {
      const errorType = 'batteryError'
      checkValidation(event, errorType)
    }
    if (from === 'testbed-text' || from === 'get_testbed' || from === 'testbed-check') {
      const errorType = 'testbedError'
      checkValidation(event, errorType)
    }
  }

  // User inputs
  const handleRobotChange = value => {
    setRobot(value)
  }

  const handleBatteryChange = value => {
    setBattery(value)
  }

  const handleTestbedChange = value => {
    setTestbed(value)
  }

  // Cancel button
  const handleCancel = () => {
    history.push(history.location.state.from)
  }

  // Set testbed on load
  useEffect(() => {
    setTestbed(Store.testingDataStore.selected.location)
  }, [])

  // Start Test button
  useEffect(() => {
    if (robot && battery && testbed) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [robot, battery, testbed])

  const get_testbed = useCallback(
    async displayName => {
      const res = await client
        .get(`/testbed/displayname/${displayName}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          enqueueSnackbar(t('TestbedLookupError'), { variant: 'error' })
          throw new Error(error)
        })
      if (res?.status === 200) {
        if (res.data.length === 0) {
          // No testbed in database
          enqueueSnackbar(t('TestbedNotFound'), { variant: 'error' })
          const event = { target: { value: '' } }
          // handleError(event, 'get_testbed')
          throw new Error(`Testbed ${testbed} not found in database`)
        }
        if (res.data.length === 1) {
          // Only one testbed
          setTestbedSN(res.data[0].TestbedSN)
          return res.data[0]
        }
        if (res.data.length > 1) {
          // Multiple testbeds in database
          enqueueSnackbar(t('TestbedNotUnique'), { variant: 'error' })
          const event = { target: { value: '' } }
          // handleError(event, 'get_testbed')
          throw new Error(`Multiple entries for testbed ${testbed}`)
        } else {
          // shouldn't get here
          throw new Error(`${res.data.length} entries found for testbed ${testbed}`)
        }
      } else {
        // shouldn't get here
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        throw new Error('Bad response from get_testbed')
      }
    },
    [enqueueSnackbar, idToken, t, testbed]
  )

  async function find_robot() {
    const res = await client
      .post(
        '/robot/search',
        {
          serial_number: robot,
          // test_id: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('RobotLookupError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        // No robot in database
        enqueueSnackbar(t('RobotNotFound'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'find_robot')
        throw new Error(`Robot ${robot} not found in database`)
      }
      if (res.data.length === 1) {
        // Only one robot
        return res.data[0]
      }
      if (res.data.length > 1) {
        // Multiple robots in database
        enqueueSnackbar(t('RobotNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'find_robot')
        throw new Error(`Multiple entries for robot ${robot} in database`)
      } else {
        // shouldn't get here
        throw new Error(`${res.data.length} entries found for ${robot}`)
      }
    } else {
      // shouldn't get here
      throw new Error('Bad response from find_robot')
    }
  }

  function checkRobot(rut) {
    let rutStatus = []
    for (const stat of rut.Status) {
      rutStatus.push(stat.RobotStatus.Name)
    }
    if (rut.Onboarded === false || rutStatus.length === 0) {
      if (rutStatus.length === 0) {
        rutStatus = undefined
      }
      enqueueSnackbar(t('RobotNotOnboarded'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'robot-check')
      throw new Error(`RobotSN ${rut.RobotSN} exists in database, but is not onboarded. Status: ${rutStatus}`)
    }
    const availableStatuses = ['available', 'running']
    if (rutStatus.find(item => availableStatuses.includes(item))) {
      return true
    }
    const testStatuses = ['stopped', 'bit', 'battery', 'error', 'repair', 'under_repair']
    if (rutStatus.find(item => testStatuses.includes(item))) {
      enqueueSnackbar(t('RobotAlreadyUnderTest'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'robot-check')
      throw new Error(`RobotSN ${rut.RobotSN} is already under test. Status: ${rutStatus}`)
    }
    if (rutStatus.includes('retired')) {
      enqueueSnackbar(t('RobotRetired'), { variant: 'error' })
      throw new Error(`RobotSN ${rut.RobotSN} is retired. Status: ${rutStatus}`)
    }
    enqueueSnackbar(t('RobotStatusError'), { variant: 'error' })
    throw new Error(`RobotSN ${rut.RobotSN} has an invalid status. Status: ${rutStatus}`)
  }

  async function get_battery() {
    const res = await client
      .get(`/battery/serial/${battery}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('BatteryLookupError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      if (res.data.length === 0) {
        // No battery in database
        enqueueSnackbar(t('BatteryNotOnboarded'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'get_battery')
        throw new Error(`Battery ${battery} not found in database`)
      }
      if (res.data.length === 1) {
        // Only one battery
        return res.data[0]
      }
      if (res.data.length > 1) {
        // Multiple batteries in database
        enqueueSnackbar(t('BatteryNotUnique'), { variant: 'error' })
        const event = { target: { value: '' } }
        handleError(event, 'get_battery')
        throw new Error(`Multiple entries for battery ${battery} in database`)
      } else {
        // shouldn't get here
        throw new Error(`${res.data.length} entries found for ${battery}`)
      }
    } else {
      // shouldn't get here
      throw new Error('Bad response from get_battery')
    }
  }

  function checkBattery(bat) {
    if (bat.FK_Robot) {
      enqueueSnackbar(t('BatteryInUse'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'battery-check')
      throw new Error(`Battery is in use. FK_Robot = ${bat.FK_Robot} `)
    } else {
      return true
    }
  }

  function checkTestbed(bed) {
    let bedStatus = []
    for (const stat of bed.Status) {
      bedStatus.push(stat.TestbedStatus.Name)
    }
    if (bedStatus.length === 0) {
      bedStatus = undefined
      enqueueSnackbar(t('TestbedStatusUndefined'), { variant: 'error' })
      throw new Error(`testbed ${bed.Name} exists in database but has no status. Status: ${bedStatus}`)
    }
    if (bedStatus.length === 1 && bedStatus[0] == 'ready') {
      return true
    }
    if (bedStatus.includes('needs_repair')) {
      enqueueSnackbar(t('TestbedNeedsRepair'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'testbed-check')
      throw new Error(`Testbed needs repair. Status #${bedStatus}`)
    }
    if (bedStatus.includes('under_repair')) {
      enqueueSnackbar(t('TestbedUnderRepair'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'testbed-check')
      throw new Error(`Testbed is under repair. Status #${bedStatus}`)
    }
    if (bedStatus.includes('needs_cleaning')) {
      enqueueSnackbar(t('TestbedNeedsCleaning'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'testbed-check')
      throw new Error(`Testbed needs cleaning. Status #${bedStatus}`)
    }
    if (bedStatus.includes('being_cleaned')) {
      enqueueSnackbar(t('TestbedBeingCleaned'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'testbed-check')
      throw new Error(`Testbed is being cleaned. Status #${bedStatus}`)
    }
    if (bedStatus.includes('under_test')) {
      enqueueSnackbar(t('TestbedOccupied'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'testbed-check')
      throw new Error(`Testbed ${testbed} is occupied. Status #${bedStatus}`)
    }
    if (bedStatus.includes('retired')) {
      enqueueSnackbar(t('TestbedRetired'), { variant: 'error' })
      const event = { target: { value: '' } }
      handleError(event, 'testbed-check')
      throw new Error(`Testbed ${testbed} is retired. Status #${bedStatus}`)
    }
  }

  async function start_test() {
    const bed = await get_testbed(testbed)
    const sn = bed.TestbedSN
    const res = await client
      .post(
        '/test',
        {
          robot_sn: robot,
          battery_sn: battery,
          testbed_sn: sn,
          fk_user: user,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('StartTestFailed'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return true
    }
  }

  const handleStartTest = async () => {
    try {
      setSubmitLoading(true)

      // Check status of RUT
      const rut = await find_robot()
      checkRobot(rut)

      // Check status of battery
      const bat = await get_battery()
      checkBattery(bat)

      // Check status of testbed
      const bed = await get_testbed(testbed)
      console.log("bed")
      console.log(bed)
      checkTestbed(bed)

      // Create test record
      await start_test()
      setSubmitLoading(false)
      enqueueSnackbar(t('StartTestSuccess'), { variant: 'success' })
      history.push('/app/testing/workstation')
    } catch (error) {
      console.log(error)
      setSubmitLoading(false)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('StartTest')}
      </Typography>
      <Stack mb={2}>
        <Typography variant="modal1" align="center">
          {t('ScanRobotTestbed')}
        </Typography>
      </Stack>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{t('RobotSN')}</Typography>
              <TextField
                required
                id="outlined"
                label={t('RobotSN')}
                value={robot}
                onChange={e => handleRobotChange(e.target.value)}
                error={error.robotError}
                onBlur={event => {
                  handleError(event, 'robot-sn-length')
                }}
                helperText={error.robotErrorHelperText}
              />

              <Typography variant="subtitle1">{t('Battery')}</Typography>
              <TextField
                required
                id="outlined"
                label={t('Battery')}
                value={battery}
                onChange={e => handleBatteryChange(e.target.value)}
                error={error.batteryError}
                onBlur={event => {
                  handleError(event, 'battery-sn-length')
                }}
                helperText={error.batteryErrorHelperText}
              />

              <Typography variant="subtitle1">{t('Testbed')}</Typography>
              <TextField
                disabled
                required
                id="outlined"
                label={t('Testbed')}
                value={Store.testingDataStore.selected.location}
                onChange={e => handleTestbedChange(e.target.value)}
                error={error.testbedError}
                onBlur={event => {
                  handleTestbedChange(event.target.value)
                  handleError(event, 'testbed-text')
                }}
              />
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" color="greyGreen" onClick={handleCancel}>
                {t('Cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                onClick={handleStartTest}
                disabled={submitDisabled}
                loading={submitLoading}
              >
                {t('StartTest')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
})

export default StartRobotTest
