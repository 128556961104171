import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { action } from 'mobx'
import { Prompt, useHistory, useLocation } from 'react-router-dom'
import { Modal, Backdrop, Fade, Typography, Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import './SettingsNotSavedModal.css'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '609px',
    height: '258px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const DataWillBeLostModal = action(({ isBlocked, errorMessage }) => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const FILENAME = Store.onboardingStore.bitFilePath

  const [isModalOpen, setIsModalOpen] = useState(false)
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(location)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const { t } = useTranslation()

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, history])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = nextLocation => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = nextLocation => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation)
      return false
    }
    return true
  }

  const deleteBitFile = async () => {
    await client.post(
      `/bitfile/${FILENAME}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    )
  }

  useEffect(() => {
    return () => {
      Store.onboardingStore.clearPersistedStore()
    }
  }, [])

  const handleConfirmNavigationClick = () => {
    // Do something and go to the next screen
    if (FILENAME) {
      deleteBitFile()
    }
    closeModal()
    setConfirmedNavigation(true)
  }

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={closeModal}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px', marginBottom: '18.5px' }}
            >
              {t('Leavingthepage')}
            </Typography>
            <div className="settings-content">
              <Typography>{errorMessage}</Typography>
              <div className="button-wrapper">
                <Button onClick={closeModal} variant="contained" color="greyGreen" sx={{ m: '0px 6px' }}>
                  {t('Cancel')}
                </Button>
                <Button onClick={handleConfirmNavigationClick} variant="contained" sx={{ m: '0px 6px' }}>
                  {t('OK')}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
})

export default DataWillBeLostModal
