import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Tabs, Tab } from '@mui/material'
import TabsPanel from '../Profile/TabsPanel'
import AllRepairsTable from './Tables/AllRepairsTable'
import MyRepairsTable from './Tables/MyRepairsTable'
import StatsRepairsTable from './Tables/StatsRepairsTable'
import { useTranslation } from 'react-i18next'

const Repairs = () => {
  // Tabs: User details, Activity History
  const [tabValue, setTabValue] = useState(0)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  function allyProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const goRepair = robotName => {
    history.push('/app/repair_robot/' + robotName)
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('RepairsOverview')}
      </Typography>
      <Container maxWidth="false">
        <Box
          sx={{
            backgroundColor: 'white',
            display: 'inline-block',
            width: '40%',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="basic tabs example"
          >
            <Tab sx={{ width: '33%' }} label={t('AllRepairs')} {...allyProps(0)} />
            <Tab sx={{ width: '33%' }} label={t('MyRepairs')} {...allyProps(1)} />
            <Tab sx={{ width: '33%' }} label={t('Statistics')} {...allyProps(3)} />
          </Tabs>
        </Box>
        <Paper>
          <TabsPanel value={tabValue} index={0}>
            <AllRepairsTable onRepair={goRepair} />
          </TabsPanel>
          <TabsPanel value={tabValue} index={1}>
            <MyRepairsTable onRepair={goRepair} />
          </TabsPanel>
          <TabsPanel value={tabValue} index={2}>
            <StatsRepairsTable />
          </TabsPanel>
        </Paper>
      </Container>
    </Box>
  )
}

export default Repairs
