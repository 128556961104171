import React, { useState, useEffect, useRef } from 'react'
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import { Button } from '@mui/material'
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks'
import { KeyboardArrowDown } from '@mui/icons-material'
import MenuItems from './MenuItems'

const MenuPopupState = ({ title }) => {
  const [width, setWidth] = useState()
  const ref = useRef(null)

  useEffect(() => {
    setWidth(ref.current.offsetWidth)
  }, [])

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoMenu',
  })

  return (
    <>
      <Button ref={ref} variant="inherit" {...bindHover(popupState)} endIcon={<KeyboardArrowDown />}>
        {title}
      </Button>
      <HoverMenu
        className="menu-position"
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItems title={title} popupState={popupState} width={width} />
      </HoverMenu>
    </>
  )
}

export default MenuPopupState
