import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class TestingDataStore {
  workstationID = ''
  workstationName = ''
  workstationDisplayName = ''
  workstationTopic = ''
  requestedCleaning = false
  requestedRepair = false
  repairComment = ''
  selected = ''
  racks = {}
  rackNameArray = []
  refresh = ''
  errorDetails = {}
  repairType = ''
  repairNotes = ''
  selectedTestbed = {}
  selectedTestbedDisplayName = ''
  keepWebsocketOpen = false

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'TestingDataStore',
      properties: [
        'workstationID',
        'workstationName',
        'workstationDisplayName',
        'workstationTopic',
        'requestedCleaning',
        'requestedRepair',
        'repairComment',
        'selected',
        'racks',
        'rackNameArray',
        'refresh',
        'errorDetails',
        'repairType',
        'repairNotes',
        'selectedTestbed',
        'selectedTestbedDisplayName',
        'keepWebsocketOpen',
      ],
      storage: window.localStorage,
    })
  }

  setRequestedCleaning = isRequested => {
    this.requestedCleaning = isRequested
  }

  setRequestedRepair = isRequested => {
    this.requestedRepair = isRequested
  }

  setRepairComment = comment => {
    this.repairComment = comment
  }

  setWorkstationID = id => {
    this.workstationID = id
  }

  setWorkstationName = name => {
    this.workstationName = name
  }

  setWorkstationTopic = topic => {
    this.workstationTopic = topic
  }

  setWorkstationDisplayName = displayName => {
    this.workstationDisplayName = displayName
  }

  setSelected = selected => {
    this.selected = selected
  }

  setRacks = racks => {
    this.racks = racks
  }

  setRackNameArray = rackNameArray => {
    this.rackNameArray = rackNameArray
  }

  updateRacks = (location, key, value) => {
    try {
      let row = this.racks.find(item => item.location === location)
      if (row == undefined) {
        throw new Error(`No rack with location ${location}`)
      }
      const pair = { [key]: value }
      Object.assign(row, pair)
    } catch (error) {
      console.log(error)
    }
  }

  updateRackNameArray = (key, value) => {
    try {
      let row = this.rackNameArray.find(rack => rack.rack === value)
      const pair = { [key]: value }
      Object.assign(row, pair)
    } catch (error) {
      console.log(error)
    }
  }

  setRefresh = () => {
    this.refresh = Date.now()
  }

  setErrorDetails = errorDetails => {
    this.errorDetails = errorDetails
  }

  setRepairType = repairType => {
    this.repairType = repairType
  }

  setRepairNotes = repairNotes => {
    this.repairNotes = repairNotes
  }

  setSelectedTestbed = selectedTestbed => {
    this.selectedTestbed = selectedTestbed
  }

  setSelectedTestbedDisplayName = selectedTestbedDisplayName => {
    this.selectedTestbedDisplayName = selectedTestbedDisplayName
  }

  setKeepWebsocketOpen = keepWebsocketOpen => {
    this.keepWebsocketOpen = keepWebsocketOpen
  }

  clearTestingDataStore = () => {
    this.workstationID = ''
    this.workstationName = ''
    this.WorkstationDisplayName = ''
    this.workstationTopic = ''
    this.requestedCleaning = false
    this.requestedRepair = false
    this.repairComment = ''
    this.selected = ''
    this.racks = {}
    this.rackNameArray = []
    this.refresh = ''
    this.errorDetails = {}
    this.repairType = ''
    this.repairNotes = ''
    this.selectedTestbed = {}
    this.selectedTestbedDisplayName = ''
    this.keepWebsocketOpen = false

    clearPersistedStore(this)
  }
}

export default TestingDataStore
