import React, { useState, useEffect } from 'react'
import { action } from 'mobx'
import Store from '../../../../../Store/Store'

import SubcomponentDropdown from './SubcomponentDropdown'
import SupplierDropdown from './SupplierDropdown'
import CustomTextField from './CustomTextField'

const CustomEdit = action(({ id, value, index, handleChange, currentlyEditing, handleDisable }) => {
  const [error, setError] = useState({
    subcomponentError: false,
    // supplierError: false,
    // serialError: false,
  })

  const handleDisableDone = value => {
    handleDisable(value)
  }

  const checkValidation = (event, errorType, label) => {
    if (errorType === 'subcomponentError') {
      if (label === '' && event.target.value === 0) {
        setError({ ...error, [errorType]: false })
        handleDisableDone(false)
      } else if (label === '') {
        setError({ ...error, [errorType]: true })
        handleDisableDone(true)
      } else {
        setError({ ...error, [errorType]: false })
        handleDisableDone(false)
      }
    } else {
      if (event.target.value === '') {
        setError({ ...error, [errorType]: true })
        handleDisableDone(true)
      } else {
        setError({ ...error, [errorType]: false })
        handleDisableDone(false)
      }
    }
  }

  const handleErrorEdit = (event, from) => {
    // if (from === 'supplier-select') {
    //   const errorType = 'supplierError'
    //   // Not good practice, change this!
    //   let label = ''
    //   if (document.getElementById('supplier-dropdown-label') != null) {
    //     label = document.getElementById('supplier-dropdown-label').innerHTML
    //   }
    //   checkValidation(event, errorType, label)
    // }
    if (from === 'subcomponent-select') {
      const errorType = 'subcomponentError'
      // Not good practice, change this!
      let label = ''
      if (document.getElementById('subcomponent-dropdown-label') != null) {
        label = document.getElementById('subcomponent-dropdown-label').innerHTML
      }
      checkValidation(event, errorType, label)
    }
    // if (from === 'serial') {
    //   const errorType = 'serialError'
    //   checkValidation(event, errorType)
    // }
  }

  if (id === 'component') {
    useEffect(() => {
      Store.onboardingStore.setComponent(value)
    }, [value])
    return value
  }
  if (id === 'subcomponent') {
    return (
      <SubcomponentDropdown
        value={value}
        handleChange={handleChange}
        id={id}
        index={index}
        currentlyEditing
        handleError={(event, from) => handleErrorEdit(event, from)}
        error={error.subcomponentError}
      />
    )
  }
  if (id === 'supplier') {
    return (
      <SupplierDropdown
        value={value}
        handleChange={handleChange}
        id={id}
        index={index}
        currentlyEditing
        // handleError={(event, from) => handleErrorEdit(event, from)}
        // error={error.supplierError}
      />
    )
  }
  if (id === 'serial') {
    return (
      <CustomTextField
        value={value}
        handleChange={handleChange}
        id={id}
        index={index}
        currentlyEditing={currentlyEditing}
        // error={error.serialError}
        // handleError={handleErrorEdit}
      />
    )
  }
})

export default CustomEdit
