import { makeAutoObservable } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

class AssetTrackingStore {
  refresh = ''
  requestedCleaning = false
  requestedRepair = false
  repairComment = ''
  rowData = []

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'AssetTrackingStore',
      properties: ['refresh', 'requestedCleaning', 'requestedRepair', 'repairComment', 'rowData'],
      storage: window.localStorage,
    })
  }

  setRefresh = () => {
    this.refresh = Date.now()
  }

  setRequestedCleaning = isRequested => {
    this.requestedCleaning = isRequested
  }

  setRequestedRepair = isRequested => {
    this.requestedRepair = isRequested
  }

  setRepairComment = comment => {
    this.repairComment = comment
  }

  setRowData = rowData => {
    this.rowData = rowData
  }

  resetRowData = () => {
    this.setRowData([])
  }

  clearAssetTrackingStore = () => {
    this.refresh = ''
    this.requestedCleaning = false
    this.requestedRepair = false
    this.repairComment = ''
    this.rowData = []

    clearPersistedStore(this)
  }
}

export default AssetTrackingStore
