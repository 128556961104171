import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Container,
  Paper,
  FormControl,
  Select,
  Snackbar,
  Alert,
  MenuItem,
  Divider,
  Switch,
  CircularProgress,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import './Settings.css'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import SettingsNotSavedModal from '../Modal/SettingsNotSavedModal'
import client from '../../../Config/axiosClient'
import Store from '../../../Store/Store'
import { useTranslation } from 'react-i18next'

const Settings = observer(() => {
  const [settings, setSettings] = useState(toJS(Store.userProfileStore.settings))
  const initialState = Store.userProfileStore.settings
  const [isBlocked, setIsBlocked] = useState(false)
  const [fetchLoading, setFetchLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const { authState } = useOktaAuth()
  const history = useHistory()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const user = Store.accountInfo.userPK

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  useEffect(() => {
    const getSettings = async () => {
      const res = await client.get(`/user/setting/${user}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      const tmp = JSON.parse(res.data.Settings)
      if (Object.keys(tmp).length > 0) {
        setSettings(tmp)
        Store.userProfileStore.setSettingsData(tmp)
        setFetchLoading(false)
      }
      setFetchLoading(false)
    }
    if (idToken) {
      setFetchLoading(true)
      getSettings()
    }
  }, [idToken, user])

  // If the settings are changed but then reverted back to the original state don't prompt the user
  const checkChange = (initialStateValue, value) => {
    if (initialStateValue === value) {
      return false
    }
    return true
  }

  const handleLayoutChange = event => {
    setSettings({ ...settings, layout: event.target.value })
    // Settings changed, needs to be saved somewhere
    // We have to block routes to other pages and prompt user if he tries to leave the current screen
    setIsBlocked(checkChange(initialState.layout, event.target.value))
  }

  const handleNotificationsChange = event => {
    setSettings({ ...settings, notifications: event.target.value })
    setIsBlocked(checkChange(initialState.notifications, event.target.value))
  }

  const handleChangesNotifications = event => {
    setSettings({ ...settings, changesNotifications: event.target.checked })
    setIsBlocked(checkChange(initialState.changesNotifications, event.target.checked))
  }

  const handleErrorNotifications = event => {
    setSettings({ ...settings, errorNotifications: event.target.checked })
    setIsBlocked(checkChange(initialState.errorNotifications, event.target.checked))
  }

  // const handleErrorChange = value => {
  //   setError(value)
  // }

  const handleSaveChanges = async () => {
    setLoading(true)
    const body = {
      Settings: JSON.stringify({
        layout: settings.layout,
        notifications: settings.notifications,
        changesNotifications: settings.changesNotifications,
        errorNotifications: settings.errorNotifications,
      }),
    }

    const res = await client
      .post('/user/setting', body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setError(true)
        setLoading(false)
      })

    if (res.status === 200 || res.statusText === 'OK') {
      // Changed settings are saved, user doesn't have to be prompted
      Store.userProfileStore.setSettingsData(settings)
      setIsBlocked(false)
      setLoading(false)
      return true
    } else {
      setLoading(false)
      setError(true)
      return false
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setError(false)
  }

  const errorMessage = t('UserSettingsError')

  return (
    <Box>
      <div className="title-loading">
        <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
          {t('UserSettings')}
        </Typography>
        {fetchLoading && <CircularProgress size={30} sx={{ ml: '15px', mb: '0.35em' }} />}
      </div>
      <Container maxWidth="false">
        <Paper>
          <Box sx={{ p: '15px 32px' }}>
            <Typography variant="settings">{t('WorkstationLayout')}</Typography>
            <Typography sx={{ mt: '15px' }}>{t('SelectWorkstationLayout')}</Typography>
            <Box sx={{ mt: '15px', maxWidth: '310px' }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={settings.layout}
                  onChange={handleLayoutChange}
                  disabled={fetchLoading}
                >
                  <MenuItem value={'Square'}>{t('Squarelayout')}</MenuItem>
                  <MenuItem value={'Horizontal layout'}>{t('Horizontallayout')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Divider variant="middle" sx={{ m: '26px 21px' }} />
            <Typography variant="settings">{t('Notificationsettings')}</Typography>
            <Typography sx={{ mt: '15px', mb: '7px' }}>{t('SelectNotifyAlerts')}</Typography>
            <div className="switches_rows">
              <Typography>{t('ChangesIveMade')}</Typography>
              <Switch
                checked={settings.changesNotifications}
                onChange={handleChangesNotifications}
                className="switch_blue"
                color="switchBlue"
                disabled={fetchLoading}
              />
            </div>
            <div className="switches_rows">
              <Typography>{t('WhenAnyErrors')}</Typography>
              <Switch
                checked={settings.errorNotifications}
                onChange={handleErrorNotifications}
                className="switch_green"
                color="primary"
                disabled={fetchLoading}
              />
            </div>
            <Typography sx={{ mt: '15px' }}>{t('SelectHowToReceiveNotifications')}:</Typography>
            <Box sx={{ mt: '15px', maxWidth: '310px' }}>
              <FormControl fullWidth>
                <Select
                  labelId="notifications-type-select"
                  id="notifications-simple-select"
                  value={settings.notifications}
                  onChange={handleNotificationsChange}
                  disabled={fetchLoading}
                >
                  <MenuItem value={'Slack'}>{t('Slack')}</MenuItem>
                  <MenuItem value={'Outlook'}>{t('Outlook')}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <LoadingButton onClick={handleSaveChanges} loading={loading} variant="contained" sx={{ mt: '36px' }}>
              {t('Savechanges')}
            </LoadingButton>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={error}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ mt: '55px', mr: '10px' }}
      >
        <Alert onClose={handleClose} severity={'error'} sx={{ width: '100%' }}>
          {t('SettingsNotSaved')}
        </Alert>
      </Snackbar>
      <SettingsNotSavedModal
        handleSaveChanges={handleSaveChanges}
        isBlocked={isBlocked}
        errorMessage={errorMessage}
        // handleErrorChange={handleErrorChange}
        // error={error}
      />
    </Box>
  )
})

export default Settings
