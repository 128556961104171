import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react'
import Store from '../../../../../Store/Store'
import client from '../../../../../Config/axiosClient'

const SubcomponentDropdown = observer(
  ({ value, handleChange, id, index, currentlyEditing, handleError, disable, error }) => {
    const { authState } = useOktaAuth()
    const [subcomponentData, setSubcomponentData] = useState([])
    const [fetchingComponents, setFetchingComponents] = useState(false)
    const idToken = authState.idToken.idToken
    const robotModel = Store.onboardingStore.robotData.FK_RobotModel
    const component = Store.onboardingStore.component
    const subcomponentsData = Store.onboardingStore.subcomponentsData
    const [oldComponent, setOldComponent] = useState(component)

    useEffect(() => {
      const getSubcomponents = async () => {
        setFetchingComponents(true)
        setSubcomponentData([])
        const res = await client.get(`/component?robot_model=${robotModel}&parent=${component}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        setSubcomponentData(res.data)
        setFetchingComponents(false)
        Store.onboardingStore.setSubcomponentData(component, res.data)
        setOldComponent(component)
      }

      if (component != oldComponent && !subcomponentsData[component] && idToken && robotModel) {
        getSubcomponents()
      } else {
        if (subcomponentsData[component]) {
          setSubcomponentData(subcomponentsData[component])
        } else {
          setSubcomponentData([])
        }
      }
    }, [idToken, robotModel, component, subcomponentsData, oldComponent, currentlyEditing])

    return (
      <Autocomplete
        disablePortal
        id="subcomponent-dropdown"
        options={subcomponentData}
        fullWidth
        inputValue={value?.Level1}
        key={component}
        disabled={fetchingComponents || !subcomponentData || subcomponentData.length === 0 || disable}
        onBlur={event => {
          if (typeof handleError !== 'undefined') {
            handleError(event, 'subcomponent-select')
          }
        }}
        onClose={event => {
          if (typeof handleError !== 'undefined') {
            handleError(event, 'subcomponent-select')
          }
        }}
        isOptionEqualToValue={(option, value) => option?.Level1 === value?.Level1}
        getOptionLabel={option => option?.Level1}
        renderInput={params => {
          if (currentlyEditing) {
            return (
              <TextField
                value={value?.Level1}
                error={error}
                label={value}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>{fetchingComponents ? <CircularProgress size={20} /> : params.InputProps.endAdornment}</>
                  ),
                }}
              />
            )
          } else {
            return (
              <TextField
                value={value?.Level1}
                error={error}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>{fetchingComponents ? <CircularProgress size={20} /> : params.InputProps.endAdornment}</>
                  ),
                }}
              />
            )
          }
        }}
        onChange={event => {
          handleChange(event, id, index)
        }}
        size="small"
        sx={{ width: '173px' }}
      />
    )
  }
)

export default SubcomponentDropdown
