import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import client from '../../../Config/axiosClient'
import Store from '../../../Store/Store'
import SaveTestbedModal from '../Modal/SaveTestbedModal'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/system'

const EditTestbed = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  const user = Store.accountInfo.userPK

  const [workstationList, setWorkstationList] = useState([])
  const [fetchingWkst, setFetchingWkst] = useState(false)
  const [workstation, setWorkstation] = useState('')

  const [rackList, setRackList] = useState([])
  const [fetchingRack, setFetchingRack] = useState(false)
  const [rack, setRack] = useState('')

  const [shelfList, setShelfList] = useState([])
  const [fetchingShelf, setFetchingShelf] = useState(false)
  const [shelf, setShelf] = useState('')

  const [testbed, setTestbed] = useState('')
  // const [testbedKey, setTestbedKey] = useState('28')

  const [serial, setSerial] = useState('')
  const [notes, setNotes] = useState('')

  const [statusList, setStatusList] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState(false)
  const [status, setStatus] = useState('')

  const [error, setError] = useState({
    workstationError: false,
    rackError: false,
    shelfError: false,
    testbedError: false,
    serialError: false,
    notesError: false,
    statusError: false,
  })
  const openModal = Store.openModal
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalMessage, setModalMessage] = useState('')

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  // Get list of workstations and set selected workstation
  useEffect(() => {
    const getData = async () => {
      setFetchingWkst(true)
      const res = await client
        .get('/workstation', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingWkst(false)
        })
      setWorkstationList(res.data)
      setFetchingWkst(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleWorkstationChange = event => {
    setWorkstation(event.target.value)
  }

  // Get list of racks and set selected rack
  useEffect(() => {
    const getData = async () => {
      setFetchingRack(true)
      const res = await client
        .get('/rack', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingRack(false)
        })
      setRackList(res.data)
      setFetchingRack(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleRackChange = event => {
    setRack(event.target.value)
  }

  // Get list of shelves and set selected shelf
  useEffect(() => {
    const getData = async () => {
      setFetchingShelf(true)
      const res = await client
        .get('/shelf', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingShelf(false)
        })
      setShelfList(res.data)
      setFetchingShelf(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleShelf = event => {
    setShelf(event.target.value)
  }

  // Set selected testbed
  const handleTestbed = event => {
    setTestbed(event.target.value)
  }

  // Set testbed serial number field
  const handleSerial = event => {
    setSerial(event.target.value)
  }

  // Set notes field
  const handleNotes = event => {
    setNotes(event.target.value)
  }

  // Get list of statuses and set selected status
  useEffect(() => {
    const getData = async () => {
      setFetchingStatus(true)
      const res = await client
        .get('/testbed/status', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          setFetchingStatus(false)
        })
      setStatusList(res.data)
      setFetchingStatus(false)
    }
    getData()
  }, [idToken, enqueueSnackbar, t])

  const handleStatus = event => {
    setStatus(event.target.value)
  }

  // Error handling
  const handleError = (event, from) => {
    if (from === 'workstation-select') {
      const errorType = 'workstationError'
      checkValidation(event, errorType)
    } else if (from === 'rack-select') {
      const errorType = 'rackError'
      checkValidation(event, errorType)
    } else if (from === 'shelf-select') {
      const errorType = 'shelfError'
      checkValidation(event, errorType)
    } else if (from === 'testbed-text') {
      const errorType = 'testbedError'
      checkValidation(event, errorType)
    } else if (from === 'serial-text') {
      const errorType = 'serialError'
      checkValidation(event, errorType)
    } else if (from === 'status-select') {
      const errorType = 'statusError'
      checkValidation(event, errorType)
    } else if (from === 'notes-text') {
      const errorType = 'notesError'
      checkValidation(event, errorType)
    }
  }

  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  // Cancel button
  const handleCancel = () => {
    reset()
    history.push('/app')
  }

  // Submit button
  useEffect(() => {
    if (workstation && rack && shelf && testbed && serial && status) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [workstation, rack, shelf, testbed, notes, serial, status])

  const check_location = async () => {
    // It would be nice to do the search on the server, but I couldn't get it working
    // let body = {
    //   "fk_workstation": workstation,
    //   "fk_rack": rack,
    //   "fk_shelf": shelf
    // }
    // console.log("check_location body:")
    // console.log(body)
    const res = await client
      .get(`/testbed/location`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('LocationCreateError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      let exists = res.data.find(item => {
        if (item.FK_Workstation === workstation && item.FK_Rack === rack && item.FK_Shelf === shelf) return true
      })
      if (exists) {
        enqueueSnackbar(t('LocationOccupied'), { variant: 'error' })
        modal('error', t('LocationOccupied'))
        throw new Error(error)
      } else {
        enqueueSnackbar(t('LocationAvailable'), { variant: 'success' })
        return true
      }
    }
  }

  const create_testbed = async () => {
    const name = testbed.toLowerCase().replace(/\s+/g, '_')
    const displayName = testbed
    const displayOrder = 0
    let body = {
      testbed_sn: serial,
      name: name,
      display_name: displayName,
      display_order: displayOrder,
      notes: notes,
      fk_user: user,
    }
    const res = await client
      .post(`/testbed`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('TestbedCreateError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.data.statusCode === 409) {
      enqueueSnackbar(t('TestbedCreateError'), { variant: 'error' })
      const message = JSON.parse(res.data.body).error_message
      modal('error', message)
      throw new Error(message)
    } else if (res?.status === 200) {
      enqueueSnackbar(t('TestbedCreated'), { variant: 'success' })
      return res.data.generatedFields[0].longValue
    }
  }

  const save_location = async pkTestbed => {
    let body = {
      fk_workstation: workstation,
      fk_rack: rack,
      fk_shelf: shelf,
      fk_testbed: pkTestbed,
      fk_user: user,
    }
    const res = await client
      .post(`/testbed/location`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('TestbedLocationError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.data.statusCode === 409) {
      // will need to delete the entry created by create_testbed
      enqueueSnackbar(t('TestbedLocationError'), { variant: 'error' })
      const message = JSON.parse(res.data.body).error_message
      modal('error', message)
      throw new Error(message)
    } else if (res?.status === 200) {
      enqueueSnackbar(t('TestbedLocationSuccess'), { variant: 'success' })
    }
  }

  const save_status = async pkTestbed => {
    let body = {
      fk_testbed: pkTestbed,
      testbed_status: status,
      fk_user: user,
      notes: notes,
    }
    const res = await client
      .post(`/testbed/status`, body, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        enqueueSnackbar(t('TestbedStatusError'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      enqueueSnackbar(t('TestbedStatusSuccess'), { variant: 'success' })
      const message = t('TestbedCreated')
      modal('success', message)
      return true
    }
  }

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true)
      await check_location()
      const pkTestbed = await create_testbed()
      await save_location(pkTestbed)
      await save_status(pkTestbed)
      setSubmitLoading(false)
      reset()
    } catch (error) {
      console.log(error)
      setSubmitLoading(false)
    }
  }

  // Modal stuff
  const modal = (type, message) => {
    setModalType(type)
    setModalMessage(message)
    Store.setOpenModal(true) // this must come first
    setShowModal(true) // this must come second
  }

  useEffect(() => {
    if (openModal) {
      setShowModal(true)
    } else if (!openModal) {
      setShowModal(false)
    }
  }, [openModal])

  // Clear the form
  const reset = () => {
    setWorkstation('')
    setRack('')
    setShelf('')
    setTestbed('')
    setSerial('')
    setNotes('')
    setStatus('')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('AddTestbed')}
      </Typography>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        <Typography variant="modal1">{t('AddTestbedLong')}</Typography>
      </Box>

      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{t('Workstation')}</Typography>
              <FormControl
                fullWidth
                required={fetchingWkst ? false : true}
                error={error.workstationError}
                onBlur={event => handleError(event, 'workstation-select')}
              >
                {fetchingWkst ? (
                  <InputLabel id="workstation-label">
                    <CircularProgress size={25} />
                  </InputLabel>
                ) : (
                  <InputLabel id="workstation-label">{t('Workstation')}</InputLabel>
                )}
                <Select
                  id="workstation-select"
                  value={workstation}
                  label={t('Workstation')}
                  onChange={handleWorkstationChange}
                  disabled={fetchingWkst}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {workstationList.map(wkst => {
                    return (
                      <MenuItem key={`workstation_${wkst.PK_Workstation}`} value={wkst.PK_Workstation}>
                        {wkst.DisplayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Typography variant="subtitle1">{t('Rack')}</Typography>
              <FormControl
                fullWidth
                required={fetchingRack ? false : true}
                error={error.rackError}
                onBlur={event => handleError(event, 'rack-select')}
              >
                {fetchingRack ? (
                  <InputLabel id="rack-label">
                    <CircularProgress size={25} />
                  </InputLabel>
                ) : (
                  <InputLabel id="rack-label">{t('Rack')}</InputLabel>
                )}
                <Select
                  id="rack-select"
                  value={rack}
                  label={t('Rack')}
                  onChange={handleRackChange}
                  disabled={fetchingRack}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {rackList.map(rack => {
                    return (
                      <MenuItem key={`rack_${rack.PK_Rack}`} value={rack.PK_Rack}>
                        {rack.DisplayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Typography variant="subtitle1">{t('Shelf')}</Typography>
              <FormControl
                fullWidth
                required={fetchingShelf ? false : true}
                error={error.shelfError}
                onBlur={event => handleError(event, 'shelf-select')}
              >
                {fetchingShelf ? (
                  <InputLabel id="shelf-label">
                    <CircularProgress size={25} />
                  </InputLabel>
                ) : (
                  <InputLabel id="shelf-label">{t('Shelf')}</InputLabel>
                )}
                <Select
                  id="shelf-select"
                  value={shelf}
                  label={t('Shelf')}
                  onChange={handleShelf}
                  disabled={fetchingShelf}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {shelfList.map(shelf => {
                    return (
                      <MenuItem key={`shelf_${shelf.PK_Shelf}`} value={shelf.PK_Shelf}>
                        {shelf.DisplayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <span></span>
              <span></span>

              <Typography variant="subtitle1">{t('TestbedName')}</Typography>
              <TextField
                required
                id="testbed-text"
                label={t('TestbedName')}
                onChange={handleTestbed}
                value={testbed}
                error={error.testbedError}
                onBlur={event => handleError(event, 'testbed-text')}
              />

              <Typography variant="subtitle1">{t('TestbedSNshort')}</Typography>
              <TextField
                required
                id="serial-next"
                label={t('TestbedSN')}
                onChange={handleSerial}
                value={serial}
                error={error.serialError}
                onBlur={event => handleError(event, 'serial-text')}
              />

              <Typography variant="subtitle1">{t('TestbedStatus')}</Typography>
              <FormControl
                fullWidth
                required={fetchingStatus ? false : true}
                error={error.statusError}
                onBlur={event => handleError(event, 'status-select')}
              >
                {fetchingStatus ? (
                  <InputLabel id="status-label">
                    <CircularProgress size={25} />
                  </InputLabel>
                ) : (
                  <InputLabel id="status-label">{t('TestbedStatus')}</InputLabel>
                )}
                <Select
                  id="status-select"
                  value={status}
                  label={t('TestbedStatus')}
                  onChange={handleStatus}
                  disabled={fetchingStatus}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                >
                  {statusList.map(status => {
                    return (
                      <MenuItem key={`status_${status.PK_TestbedStatus}`} value={status.Name}>
                        {status.DisplayName}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>

              <Typography variant="subtitle1">{t('Notes')}</Typography>
              <FormControl fullWidth>
                <TextField
                  id="notes-text"
                  label={t('Notes')}
                  onChange={handleNotes}
                  value={notes}
                  error={error.notesError}
                  onClose={event => handleError(event, 'notes-text')}
                />
              </FormControl>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button onClick={handleCancel} variant="contained" color="greyGreen">
                {t('Cancel')}
              </Button>
              <LoadingButton
                onClick={handleSubmit}
                disabled={submitDisabled}
                loading={submitLoading}
                variant="contained"
              >
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
      <SaveTestbedModal showModal={showModal} modalType={modalType} modalMessage={modalMessage} />
    </Box>
  )
}

export default EditTestbed
