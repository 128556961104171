let globalStatus = {
  // When changing these values, check the following screens:
  // Asset Tracking -> Testbeds
  // Asset Tracking -> Robots
  // Testing
  ignore: {
    name: 'Ignore',
    theme: '',
    themeColor: '',
    hexColor: '',
    values: {
      testbed: ['available', 'run', 'running', 'under_test'],
      robot: [],
      all: [],
    },
  },
  warning: {
    name: 'Warning',
    theme: 'error',
    themeColor: 'error.main',
    hexColor: '',
    values: {
      testbed: ['needs_repair', 'under_repair'],
      robot: ['error', 'needs_repair', 'under_repair'],
      all: [],
    },
  },
  caution: {
    name: 'Caution',
    theme: 'warning',
    themeColor: 'warning.main',
    hexColor: '',
    values: {
      testbed: ['being_cleaned', 'needs_cleaning', 'retired'],
      robot: ['battery', 'bit', 'charge', 'charging', 'retired', 'stop', 'stopped'],
      all: [],
    },
  },
  info: {
    name: 'Info',
    theme: 'info',
    themeColor: 'info.main',
    hexColor: '',
    values: {
      testbed: ['available'],
      robot: ['available', 'ready'],
      all: [],
    },
  },
  success: {
    name: 'Success',
    theme: 'success',
    themeColor: 'success.main',
    hexColor: '',
    values: {
      testbed: ['under_test'],
      robot: ['run', 'running', 'none'],
      all: [],
    },
  },
  startTest: {
    name: 'Start Test',
    theme: 'success',
    themeColor: 'success.main',
    hexColor: '',
    values: {
      testbed: ['available', 'ready', ''],
      robot: [],
      all: [],
    },
  },
}

for (let stat in globalStatus) {
  const allValues = [...globalStatus[stat].values.testbed, ...globalStatus[stat].values.robot]
  globalStatus[stat].values.all = [...new Set(allValues)]
}

export { globalStatus }
