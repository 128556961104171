import React, { useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
} from '@mui/material'
import { RepairOverviewData } from '../../../../Store/MOCK_DATA'
import CustomSearch from '../../../Search/Search'
import AssignModal from '../AssignModal'
import { useTranslation } from 'react-i18next'

const AllRepairsTable = props => {
  const [page, setPage] = useState(0)
  const [rows, setRows] = useState(RepairOverviewData)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    Assigned: false,
    Unassigned: false,
  })
  // const [filteredRowData, setFilteredRowData] = useState(null)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const { t } = useTranslation()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleRepair = robotName => {
    props.onRepair(robotName)
  }

  // MOCK DATA

  const columns = [
    { id: 'robot_name', label: 'Robot Name', minWidth: 170 },
    { id: 'error_name', label: 'Error Name', minWidth: 170 },
    {
      id: 'date_reported',
      label: 'Date Reported',
      minWidth: 170,
    },
    {
      id: 'assignee',
      label: 'Assignee',
      minWidth: 170,
    },
  ]

  const dateFormater = cell => {
    // const date = new Date(cell);
    // console.log(JSON.stringify(date));
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleDateString()

    return formatedDate
  }

  const onFilterChanged = name => event => {
    setFilters({ ...filters, [name]: event.target.checked })
  }

  const filteredRows = rows.filter(row => {
    if (filters.Assigned && row.assignee) {
      return row
    }
    if (filters.Unassigned && !row.assignee) {
      return row
    }
    return null
  })

  let showRows = rows
  if (filteredRows.length > 0) {
    showRows = filteredRows
  } else {
    showRows = rows
  }

  function handleSearchUpdate(value) {
    setSearch(value)
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const onAssign = row => {
    setSelectedRow(row)
    setOpenModal(true)
  }

  const handleClose = () => setOpenModal(false)

  const handleAssign = (robotName, newAssignee) => {
    setRows(prevRows =>
      [...prevRows].map(row => (row.robot_name === robotName ? { ...row, assignee: newAssignee } : row))
    )
    handleClose()
  }

  return (
    <Box>
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: '8px' }}>
          {/* <Grid container direction="row" columnSpacing={3} alignItems="center" sx={{ ml: '0px', mt: '3px' }}>
            <Grid item sx={{ mr: 3 }}>
              <Typography variant="modal2">{t('Filterbystatus')}:</Typography>
            </Grid>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox checked={filters['Assigned']} onChange={onFilterChanged('Assigned')} value="Assigned" />
                  }
                  label={t('Assigned')}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      checked={filters['Unassigned']}
                      onChange={onFilterChanged('Unassigned')}
                      value="Unassigned"
                    />
                  }
                  label={t('Unassigned')}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Grid> */}
          <div style={{ width: '370px' }}>
            <CustomSearch search={search} handleSearchUpdate={handleSearchUpdate} />
          </div>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    sortDirection={orderBy === column.id ? order : false}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(showRows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter(row => {
                  if (search === '') {
                    return row
                  }
                  return (
                    row.robot_name.toLowerCase().includes(search.toLowerCase()) ||
                    row.error_name.toLowerCase().includes(search.toLowerCase()) ||
                    dateFormater(row.date_reported).includes(search.toLowerCase()) ||
                    row.assignee.toLowerCase().includes(search.toLowerCase())
                  )
                })
                .map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.robot_name} sx={{ cursor: 'pointer' }}>
                      {columns.map(column => {
                        let value = row[column.id]
                        if (column.id === 'date_reported') {
                          value = dateFormater(value)
                        }
                        return <TableCell key={column.id}>{value}</TableCell>
                      })}
                      <TableCell>
                        <div style={{ display: 'flex', justifyContent: 'end', flexDirection: 'row' }}>
                          {/* <Button
                            variant="contained"
                            onClick={() => onAssign(row)}
                            color="greyGreen"
                            sx={{ mr: '12px', width: '84px' }}
                          >
                            {t('Assign')}
                          </Button> */}
                          <Button
                            variant="contained"
                            onClick={() => handleRepair(row.robot_name)}
                            color="alertPurple"
                            sx={{ width: '84px' }}
                          >
                            {t('Repair')}
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <AssignModal open={openModal} handleClose={handleClose} handleAssign={handleAssign} selectedRow={selectedRow} />
    </Box>
  )
}

export default AllRepairsTable
