import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Card } from '@mui/material'
import { observer } from 'mobx-react'

import './ItemCard.css'
import Store from '../../../Store/Store'

const ItemCard = observer(({ name, icon, fromModal, route, testingItem, repairsItem, provisioningItem }) => {
  const history = useHistory()

  const onClickHandler = event => {
    Store.setModalTitle(event.target.outerText)
    if (testingItem) {
      history.push('/app/testing')
    } else if (repairsItem) {
      history.push('/app/repairs')
    } else if (provisioningItem) {
      history.push('/app/provisioning')
    } else {
      if (fromModal) {
        Store.setOpenModal(false)
        history.push(route)
      } else {
        Store.setOpenModal(true)
      }
    }
  }

  return (
    <>
      <Card
        onClick={event => {
          onClickHandler(event)
        }}
        className="card"
      >
        <div className="content">
          <div className="icon">{icon}</div>
          <div className="text-card">
            <Typography variant="body2" sx={{ fontSize: 12, fontWeight: 'medium' }}>
              {name}
            </Typography>
          </div>
        </div>
      </Card>
    </>
  )
})

export default ItemCard
