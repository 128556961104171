import { Box, Chip } from '@mui/material'

import { ReactComponent as Logo } from '../../Icons/logo.svg'
import React from 'react'

// import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '20px',
          ml: '33px',
          mr: '33px',
        }}
      >
        <Logo />
        <Chip id="chip" label={'ENV: ' + process.env.NODE_ENV} color="warning" />

        <Box sx={{ typography: 'body2' }}>
          {process.env.REACT_APP_NAME} v.{process.env.REACT_APP_VERSION}
        </Box>
      </Box>
    </footer>
  )
}

export default Footer
