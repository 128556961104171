import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react'
import Store from '../../../../../Store/Store'
import client from '../../../../../Config/axiosClient'

const ComponentDropdown = observer(({ value, handleChange, id, index, handleError, error, disable }) => {
  const { authState } = useOktaAuth()
  const [componentData, setComponentData] = useState([])
  const [fetchingComponents, setFetchingComponents] = useState(false)
  const [isNotEmpty, setIsNotEmpty] = useState(true)
  const idToken = authState.idToken.idToken
  const robotModel = Store.onboardingStore.robotData.FK_RobotModel
  const componentsData = Store.onboardingStore.componentsData

  useEffect(() => {
    const getComponents = async () => {
      setFetchingComponents(true)
      const res = await client.get(`/component?robot_model=${robotModel}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setComponentData(res.data)
      setFetchingComponents(false)
      Store.onboardingStore.setComponentsData(res.data)
      if (res.data.length === 0) {
        setIsNotEmpty(false)
      }
    }

    if (componentsData.length === 0 && idToken && isNotEmpty) {
      getComponents()
    } else {
      setComponentData(componentsData)
    }
  }, [componentsData, idToken, robotModel, isNotEmpty])

  return (
    <Autocomplete
      disablePortal
      id="component-dropdown"
      options={componentData}
      fullWidth
      disabled={fetchingComponents || disable}
      loading={fetchingComponents}
      onBlur={event => {
        if (typeof handleError !== 'undefined') {
          handleError(event, 'component-select')
        }
      }}
      onClose={event => {
        if (typeof handleError !== 'undefined') {
          handleError(event, 'component-select')
        }
      }}
      isOptionEqualToValue={(option, value) => option?.Component === value?.Component}
      getOptionLabel={option => option?.Component}
      renderInput={params => (
        <TextField
          value={value?.Component}
          error={error}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{fetchingComponents ? <CircularProgress size={20} /> : params.InputProps.endAdornment}</>,
          }}
        />
      )}
      onChange={event => {
        handleChange(event, id, index)
      }}
      size="small"
      sx={{ width: '173px' }}
    />
  )
})

export default ComponentDropdown
