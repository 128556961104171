import React from 'react'
import { Backdrop, Modal, Fade, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import './NotificationModals.css'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '609px',
    // height: '210px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const RobotNotFound = ({ open, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px', marginBottom: '18.5px' }}
            >
              {t('RobotNotFound')}
            </Typography>
            <div className="modal-content">
              <Typography variant="modal1">{t('Therobotnamewasnotfound')}</Typography>
              <ol>
                <li>{t('UseApp')}</li>
                <li>{t('VerifyRobotName')}</li>
              </ol>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default RobotNotFound
