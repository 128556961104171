import React, { useState } from 'react'
import { Backdrop, Modal, Fade, Button, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import Store from '../../../Store/Store'
import './WorkstationTestbedDetail.css'
import RequestRepairModal from '../Modal/RequestRepairModal'
import SimpleCheckModal from '../Modal/SimpleCheckModal'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import client from '../../../Config/axiosClient'
import WorkstationDetailNotifications from './Tables/WorkstationDetailNotifications'
import { Stack } from '@mui/system'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
  maxHeight: '90vh',
  overflow: 'auto',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '1000px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const WorkstationTestbedDetail = observer(({ open, handleClose }) => {
  const [openRequestRepairModal, setOpenRequestRepairModal] = useState(false)
  const [openCheckModal, setOpenCheckModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalType, setModalType] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken

  const location = Store.testingDataStore.selected?.location
  const testbedName = Store.testingDataStore.selected.testbed?.Name
  const testbedStatus = t(Store.testingDataStore.selected.testbed?.status)
  const robotName = Store.testingDataStore.selected.robot?.RobotName
  //const robotStatus = t(Store.testingDataStore.selected.robot?.status)
  const selectedBlid = Store.testingDataStore.selected.robot?.BLID
  const statusArray = Store.testingDataStore.selected?.statusArray
  const errorArray = Store.testingDataStore.selected?.robot?.error
  const pkTestbed = Store.testingDataStore.selected.testbed?.PK_Testbed
  const robotStatusObject = Store.robotStatusStore.getRobotStatusObject(selectedBlid)
  const estCap = robotStatusObject?.bbchg3?.estCap;
  const robotStatus = t(robotStatusObject?.cleanMissionStatus?.phase)
  let robotRuntime = ''
  if (robotStatusObject?.bbrun && 
      (typeof robotStatusObject?.bbrun?.hr !== 'undefined') && 
      (typeof robotStatusObject?.bbrun?.min !== 'undefined')
    ) {
    robotRuntime = robotStatusObject?.bbrun?.hr + "hr, " + robotStatusObject?.bbrun?.min + "min"
  }

  const handleSwapBattery = () => {
    history.push('/app/testing/swap_battery', { from: '/app/testing/workstation' })
  }

  const handleBiT = () => {
    history.push('/app/testing/upload_bit', { from: '/app/testing/workstation' })
  }

  const handleReportError = () => {
    // Error type can be 'robot', 'battery', or 'testbed'
    // Only using 'robot' for now
    const errorType = 'robot'
    Store.reportData.setErrorType(errorType)
    // let row = []
    // errorData.map(error => {
    //   if (error.type === errorType) {
    //     row = {
    //       title: `${error.title}`,
    //       description: `${error.description}`,
    //     }
    //     Store.reportData.setReportData(row)
    //   }
    // })

    history.push('/app/testing/error', { from: '/app/testing/workstation' })
  }

  async function newTestbedStatus(status_name, notes) {
    setLoading(true)
    const res = await client
      .post(
        '/testbed/status',
        {
          fk_testbed: pkTestbed,
          testbed_status: status_name,
          fk_user: Store.accountInfo.userPK,
          notes: notes,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        console.log(error)
        setLoading(false)
        return false
      })
    if (res?.status === 200) {
      setLoading(false)
      return true
    }
  }

  async function closeTestbedStatus(status_name) {
    setLoading(true)
    const res = await client
      .post(
        '/testbed/status',
        {
          fk_testbed: pkTestbed,
          testbed_status: status_name,
          fk_user: Store.accountInfo.userPK,
          notes: '',
          close: true,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        console.log(error)
        setLoading(false)
        return false
      })
    if (res?.status === 200) {
      setLoading(false)
      return true
    }
  }

  const handleRequestCleaning = async () => {
    await newTestbedStatus('needs_cleaning', '')
    handleCloseModals()
  }

  const handleCompleteCleaning = async () => {
    await closeTestbedStatus('needs_cleaning')
    await closeTestbedStatus('being_cleaned')
    handleCloseModals()
  }

  const handleRequestRepair = async () => {
    await newTestbedStatus('needs_repair', Store.testingDataStore.repairComment)
    handleCloseModals()
  }

  const handleCompleteRepair = async () => {
    await closeTestbedStatus('needs_repair')
    await closeTestbedStatus('under_repair')
    Store.testingDataStore.setRepairComment('')
    handleCloseModals()
  }

  const handleOpenRepairModal = () => {
    setOpenRequestRepairModal(true)
  }

  const handleOpenCheckModal = type => {
    setModalType(type)
    if (type === 'repair-complete') {
      setModalTitle(t('CompleteRepair?'))
    }
    if (type === 'cleaning-request') {
      setModalTitle(t('RequestCleaning?'))
    }
    if (type === 'cleaning-complete') {
      setModalTitle(t('CompleteCleaning?'))
    }
    setOpenCheckModal(true)
  }

  const handleCloseModals = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenRequestRepairModal(false)
      setOpenCheckModal(false)
      Store.testingDataStore.setRefresh()
    }
  }

  const handleCheck = type => {
    if (type === 'repair-complete') {
      handleCompleteRepair()
    }
    if (type === 'cleaning-request') {
      handleRequestCleaning()
    }
    if (type === 'cleaning-complete') {
      handleCompleteCleaning()
    }
  }

  const handleRepairRobot = robotName => {
    history.push('/app/repair_robot/' + robotName)
  }

  const handleEndTest = selectedBlid => {
    history.push('/app/testing/end_test/' + selectedBlid)
  }

  const handleRetireRobot = selectedBlid => {
    history.push('/app/testing/retire_robot/' + selectedBlid)
  }

  // Will be needed later
  // const getErrorNameFromCode = error_code => {
  //   if (error_code in errorCodeObjects) {
  //     return errorCodeObjects[error_code]['error_name']
  //   }
  // }

  return (
    <div>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px', marginRight: '8px' }}>
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <div className="top-detail">
              <div className="detail-padding">
                <div style={{ display: 'flex' }}>
                  <div className="detail-item">
                    <Typography variant="modal3">{location}</Typography>
                    <Typography>{t('Location')}</Typography>
                  </div>

                  <div className="detail-item">
                    <Typography variant="modal3">{testbedName ? testbedName : '—'}</Typography>
                    <Typography>{t('Testbed')}</Typography>
                  </div>
                  <div className="detail-item">
                    <span style={{ pointerEvents: 'none' }}>
                      <Button variant="contained" color="lightOrange" disableElevation sx={{ height: '36px' }}>
                        {testbedStatus ? t(testbedStatus) : '—'}
                      </Button>
                    </span>
                  </div>
                </div>

                <WorkstationDetailNotifications array={statusArray} type="testbed" />

                <Stack direction="row" justifyContent="space-between" mb={2}>
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      disabled={Store.testingDataStore.requestedCleaning}
                      onClick={() => handleOpenCheckModal('cleaning-request')}
                    >
                      {t('RequestCleaning')}
                    </Button>
                    <Button
                      variant="contained"
                      color="greyGreen"
                      disabled={!Store.testingDataStore.requestedCleaning}
                      onClick={() => handleOpenCheckModal('cleaning-complete')}
                    >
                      {t('CompleteCleaning')}
                    </Button>
                  </Stack>
                  {/* <Stack direction="row" spacing={2}>
                    <Button
                      variant="contained"
                      disabled={Store.testingDataStore.requestedRepair}
                      onClick={handleOpenRepairModal}
                    >
                      {t('RequestRepair')}
                    </Button>
                    <Button
                      variant="contained"
                      color="greyGreen"
                      disabled={!Store.testingDataStore.requestedRepair}
                      onClick={() => handleOpenCheckModal('repair-complete')}
                    >
                      {t('CompleteRepair')}
                    </Button>
                  </Stack> */}
                </Stack>
              </div>
            </div>
            <div className="bottom-wrapper">
              <div className="detail-padding">
                <div style={{ display: 'flex' }}>
                  <div className="detail-item">
                    <Typography variant="modal3">{robotName ? robotName : '—'}</Typography>
                    <Typography>{t('RobotName')}</Typography>
                  </div>

                  <div className="detail-item">
                    <Typography variant="modal3">{robotRuntime ? robotRuntime : '—'}</Typography>
                    <Typography>{t('Runtime')}</Typography>
                  </div>
                  <div className="detail-item">
                    <Typography variant="modal3">{estCap ? estCap : '—'}</Typography>
                    <Typography>{t('BatteryCapacity')}</Typography>
                  </div>
                  <div className="detail-item">
                    <span style={{ pointerEvents: 'none' }}>
                      <Button variant="contained" color="lightOrange" disableElevation sx={{ height: '36px' }}>
                        {robotStatus ? robotStatus : '—'}
                      </Button>
                    </span>
                  </div>
                </div>

                <WorkstationDetailNotifications array={statusArray} type="robot" error={errorArray} />

                <Stack direction="row" justifyContent="space-between" mb={3}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleSwapBattery}>
                      {t('SwapBattery')}
                    </Button>
                    <Button variant="contained" onClick={handleBiT}>
                      {t('BiT')}
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleReportError}>
                      {t('ReportError')}
                    </Button>
                    {/* <Button variant="contained" color="greyGreen" onClick={() => handleRepairRobot(robotName)}>
                      {t('RepairRobot')}
                    </Button> */}
                    <Button variant="contained" color="alertPurple" onClick={() => handleEndTest(selectedBlid)}>
                      {t('EndTest')}
                    </Button>
                    <Button variant="contained" color="alertPurple" onClick={() => handleRetireRobot(selectedBlid)}>
                      {t('RetireRobot')}
                    </Button>
                  </Stack>
                </Stack>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <RequestRepairModal
        open={openRequestRepairModal}
        handleClose={handleCloseModals}
        handleRequestRepair={handleRequestRepair}
        loading={loading}
      />
      <SimpleCheckModal
        open={openCheckModal}
        handleClose={handleCloseModals}
        title={modalTitle}
        type={modalType}
        handleCheck={handleCheck}
        loading={loading}
      />
    </div>
  )
})

export default WorkstationTestbedDetail
