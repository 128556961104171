import { useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material'
import { Build } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Store from '../../../Store/Store'

const RepairDetails = observer(() => {
  const { t } = useTranslation()
  const history = useHistory()
  const repairType = Store.testingDataStore.repairType
  const repairNotes = Store.testingDataStore.repairNotes
  const location = Store.testingDataStore.selected?.location

  let title = ''
  if (repairType == 'robot') {
    title = t('RobotNeedsRepairTitle')
  } else if (repairType == 'testbed') {
    title = t('TestbedNeedsRepairTitle')
  }

  const handleOK = () => {
    history.push(history.location.state.from)
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('RepairDetails')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Build fontSize="large" sx={{ display: 'flex', justifySelf: 'flex-end' }} />
              <Typography variant="modal4">{title}</Typography>

              <span></span>
              <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%">
                <Typography variant="modal1">{t('Location')}</Typography>
                <Typography>{location}</Typography>

                <Typography variant="modal1">{`${t('Notes')}`}</Typography>
                <Typography>{repairNotes}</Typography>
              </Box>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button variant="contained" onClick={handleOK}>
                {t('OK')}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
})

export default RepairDetails
