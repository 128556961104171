import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Container, Paper, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ComponentStep from './Robot Onboarding/ComponentsStep'
import './AddOnTests.css'

const AddOnTests = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('AddonTests')}
      </Typography>
      <Container maxWidth="lg">
        <Box sx={{ p: '0px 20px 20px', mb: '7px', textAlign: 'center' }}>
          <Typography variant="modal1">{t('TestIndividualComponents')}</Typography>
        </Box>
        <Paper sx={{ p: '10px' }}>
          <div className="title-wrapper">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
              {t('Robot')}:
            </Typography>
            <Typography variant="subtitle2">Sapphire-EP1-01</Typography>
          </div>
          <ComponentStep fromTests={true} />
          <div className="addon-button-wrapper">
            <Button variant="contained" color="greyGreen" sx={{ m: '0px 6px' }}>
              {t('Back')}
            </Button>
            <Button variant="contained" sx={{ m: '0px 6px' }}>
              {t('Next')}
            </Button>
          </div>
        </Paper>
      </Container>
    </Box>
  )
}

export default AddOnTests
