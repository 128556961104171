import React, { useState, useEffect } from 'react'
import { Backdrop, Modal, Fade, Typography, Button, Autocomplete, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Close } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import './AssignModal.css'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '609px',
    // height: '210px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const AssignModal = ({ open, handleClose, handleAssign, selectedRow }) => {
  const [assignee, setAssignee] = useState('')
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    // console.log('Selected row assignee', selectedRow?.assignee)
    if (selectedRow?.assignee) {
      handleSetAssign(selectedRow.assignee)
    } else {
      handleSetAssign('')
    }
  }, [selectedRow])

  const techinicianData = [{ label: 'G. Hollands' }, { label: 'R. Dupont' }, { label: 'J. Liner' }]

  const handleSetAssign = assignee => {
    setAssignee(assignee)
  }

  const handleChange = event => {
    setAssignee(event.target.textContent)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px', marginBottom: '18.5px' }}
            >
              {t('Assign')}
            </Typography>
            <div className="settings-content">
              <Typography variant="modal2">{t('AssignRUTToTehnician')}</Typography>
              <div className="assign-container">
                <div className="assign-content">
                  <Typography variant="modal5" sx={{ width: '105px' }}>
                    {t('RobotName')}
                  </Typography>
                  <Typography variant="modal6">{selectedRow?.robot_name}</Typography>
                </div>
                <div className="assign-content">
                  <Typography variant="modal5" sx={{ width: '135px' }}>
                    {t('Technician')}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id="techinician-dropdown"
                    options={techinicianData}
                    fullWidth
                    value={selectedRow?.assignee ? selectedRow?.assignee : null}
                    onChange={event => handleChange(event)}
                    isOptionEqualToValue={(option, value) => option.label === value}
                    renderInput={params => {
                      return <TextField {...params} />
                    }}
                  />
                </div>
              </div>
              <div className="button-wrapper">
                <Button onClick={handleClose} variant="contained" color="greyGreen" sx={{ m: '0px 6px' }}>
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  sx={{ m: '0px 6px' }}
                  onClick={() => handleAssign(selectedRow.robot_name, assignee)}
                >
                  {t('Assign')}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default AssignModal
