import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import { BatteryFull, Battery80, Battery60, Battery50, Battery20, BatteryAlert, BatteryUnknown } from '@mui/icons-material'
import WorkstationTestbedDetail from '../WorkstationTestbedDetail'
import './RackTable.css'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Store from '../../../../Store/Store'
import { globalStatus } from '../../../../Constants/global'

const RackTable = observer(props => {
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()
  const [rows, setRows] = useState(props.rows)
  const [rackName, setRackName] = useState(props.rackName)
  const robotStatusObjects = Store.robotStatusStore.robotStatusObjects

  useEffect(() => {
    setRows(props.rows)
    setRackName(props.rackName)
  }, [props])

  useEffect(() => {
    if (
      history.location.state?.from === 'new_battery' ||
      history.location.state?.from === 'bit' ||
      history.location.state?.from === 'error'
    ) {
      history.location.state.from = ''
    }
  }, [history.location.state])

  const columns = [
    {
      id: 'location',
      label: t('Location'),
      minWidth: 80,
      align: 'left',
    },
    {
      id: 'status',
      label: t('RobotStatus'),
      minWidth: 175,
      align: 'center',
    },
    {
      id: 'battery',
      label: t('Battery'),
      minWidth: 80,
      align: 'center',
    },
    {
      id: 'notifications',
      label: t('Notifications'),
      minWidth: 175,
      align: 'center',
    },
  ]

  const handleOpenModalDetail = row => {
    Store.testingDataStore.setSelected(row)
    // reset requestedCleaning and requestedRepair to false
    // then set them true if needed
    Store.testingDataStore.setRequestedCleaning(false)
    Store.testingDataStore.setRequestedRepair(false)
    Store.robotStatusStore.getRobotStatusObject(row.robot.BLID)
    row.statusArray?.map(item => {
      if (item.type == 'testbed' && (item.Name == 'needs_cleaning' || item.Name == 'being_cleaned')) {
        Store.testingDataStore.setRequestedCleaning(true)
      }
      if (item.type == 'testbed' && (item.Name == 'under_repair' || item.Name == 'needs_repair')) {
        Store.testingDataStore.setRequestedRepair(true)
      }
    })

    setOpenModal(true)
  }

  const handleClose = () => {
    Store.testingDataStore.setRefresh()
    setOpenModal(false)
  }

  const handleStartTest = testbedDisplayName => {
    Store.testingDataStore.setSelectedTestbedDisplayName(testbedDisplayName)
    history.push('/app/testing/inspect_rut', { from: '/app/testing/workstation' })
  }

  const locationFormatter = (value, rowData) => {
    let title = rowData?.robot?.RobotName ? rowData.robot.RobotName : ''
    return (
      <Tooltip title={title}>
        <span>{value}</span>
      </Tooltip>
    )
  }

  const getRobotStatusValue = cleanMissionStatus => {
    try {
      if (cleanMissionStatus.error && cleanMissionStatus.error > 0) {
        return 'error'
      }
      if (cleanMissionStatus.phase) {
        return cleanMissionStatus.phase
      }
    } catch {
      return ''
    }
  }

  const getHumanReadableRobotStatusValue = robotValue => {
    let value = robotValue
    let i
    if (globalStatus.warning.values.robot.includes(value)) {
      i = globalStatus.warning.values.robot.indexOf(value)
      return ['warning', globalStatus.warning.values.robot[i]]
    }
    if (globalStatus.caution.values.robot.includes(value)) {
      i = globalStatus.caution.values.robot.indexOf(value)
      return ['caution', globalStatus.caution.values.robot[i]]
    }
    if (globalStatus.info.values.robot.includes(value)) {
      i = globalStatus.info.values.robot.indexOf(value)
      return ['info', globalStatus.info.values.robot[i]]
    }
    if (globalStatus.success.values.robot.includes(value)) {
      i = globalStatus.success.values.robot.indexOf(value)
      return ['success', globalStatus.success.values.robot[i]]
    }
    if (globalStatus.startTest.values.robot.includes(value)) {
      i = globalStatus.startTest.values.robot.indexOf(value)
      return ['startTest', globalStatus.startTest.values.robot[i]]
    }
    if (globalStatus.ignore.values.robot.includes(value)) {
      return ['', '']
    }
    return ['', '']
  }

  const statusButtonFormatter = (statusType, value) => {
    if (globalStatus[statusType]) {
      let theme = globalStatus[statusType].theme
      return (
        <Button variant="contained" color={theme} disableElevation className="status-button" sx={{ width: '100%' }}>
          {t(value)}
        </Button>
      )
    }
  }

  const notificationsFormatter = (isUnderTest, value, blid, testbedDisplayName) => {
    // Notifications come from the database
    // console.log("isUnderTest")
    // console.log(isUnderTest)
    // console.log("JSON.stringify(value)")
    // console.log(JSON.stringify(value))
    // console.log("JSON.stringify(globalStatus.startTest.values.all)")
    // console.log(JSON.stringify(globalStatus.startTest.values.all))
    if (!value.length) {
      value = ""
    }
    if (!isUnderTest && globalStatus.startTest.values.all.includes(value)) {
        return (
        <Button
          onClick={() => handleStartTest(testbedDisplayName)}
          variant="contained"
          color={globalStatus.startTest.theme}
          className="start-test-button"
          sx={{ width: '100%' }}
        >
          {t('StartTest')}
        </Button>
      )
    }
    if (globalStatus.ignore.values.all.includes(value)) {
      return ''
    }
    if (globalStatus.info.values.all.includes(value)) {
      return (
        <Button
          variant="contained"
          color={globalStatus.info.theme}
          disableElevation
          className="notification-button"
          sx={{ width: '100%' }}
        >
          {t(value)}
        </Button>
      )
    }

    if (globalStatus.warning.values.all.includes(value)) {
      return (
        <Button
          variant="contained"
          color={globalStatus.warning.theme}
          disableElevation
          className="notification-button"
          sx={{ width: '100%' }}
        >
          {t(value)}
        </Button>
      )
    }
    if (globalStatus.caution.values.all.includes(value)) {
      return (
        <Button
          variant="contained"
          color={globalStatus.caution.theme}
          disableElevation
          className="notification-button"
          sx={{ width: '100%' }}
        >
          {t(value)}
        </Button>
      )
    }
    return value
  }

  const statusFormatter = (value, rowData) => {
    const blid = rowData.robot.BLID
    const robotStatusObject = Store.robotStatusStore.robotStatusObjects[blid]
    if (blid && robotStatusObject) {
      value = getRobotStatusValue(robotStatusObject.cleanMissionStatus)
    }
    const [statusType, readableValue] = getHumanReadableRobotStatusValue(value)
    return statusButtonFormatter(statusType, readableValue)
  }

  const batteryFormatter = (rowData) => {
    // console.log("rowData")
    // console.log(rowData)
    const blid = rowData.robot.BLID
    // console.log("blid")
    // console.log(blid)
    if (!blid) {
      return (
        <span className="battery-format-span">
          <BatteryUnknown sx={{ color: '#737373' }} />
        </span>
      )  
    } else {
      console.log("Store.robotStatusStore.robotStatusObjects")
      console.log(Store.robotStatusStore.robotStatusObjects)
      const robotStatusObject = Store.robotStatusStore.robotStatusObjects[blid]
      let batPct;
      batPct = robotStatusObject?.batPct
      if (batPct <= 100 && batPct >= 81) {
        return (
          <span className="battery-format-span">
            <BatteryFull sx={{ color: '#33cc33' }} /> {batPct}%
          </span>
        )
      } else if (batPct <= 80 && batPct >= 61) {
        return (
          <span className="battery-format-span">
            <Battery80 sx={{ color: '#99cc00' }} /> {batPct}%
          </span>
        )
      } else if (batPct <= 60 && batPct >= 51) {
        return (
          <span className="battery-format-span">
            <Battery60 sx={{ color: '#e6e600' }} /> {batPct}%
          </span>
        )
      } else if (batPct <= 50 && batPct >= 21) {
        return (
          <span className="battery-format-span">
            <Battery50 sx={{ color: '#ff9900' }} /> {batPct}%
          </span>
        )
      } else if (batPct <= 20 && batPct >= 4) {
        return (
          <span className="battery-format-span">
            <Battery20 sx={{ color: '#e62e00' }} /> {batPct}%
          </span>
        )
      } else if (batPct <= 3 && (batPct > 0 || batPct === 0)) {
        return (
          <span className="battery-format-span">
            <BatteryAlert sx={{ color: '#e62e00' }} /> {batPct}%
          </span>
        )
      } else {
        return (
          <span className="battery-format-span">
            <BatteryUnknown sx={{ color: '#737373' }} />
          </span>
        )    
      }
  


    }
  }

  return (
    <Paper>
      <TableContainer sx={{ maxHeight: 440, maxWidth: 'sm' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length == null
              ? ''
              : rows.map(row => {
                  console.log("row")
                  console.log(row)
                  const testbedDisplayName = row.location
                  const blid = row.robot.BLID
                  if (row.rack !== rackName) {
                    return
                  }
                  let boldText = ''
                  const isUnderTest = row.status !== ''
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.location}
                      sx={{ cursor: 'pointer', height: '70px' }}
                      onClick={() => handleOpenModalDetail(row)}
                    >
                      {columns.map(column => {
                        let value = row[column.id]

                        if (column.id === 'location') {
                          value = locationFormatter(value, row)
                        }

                        if (column.id === 'status') {
                          value = statusFormatter(value, row)
                        }

                        if (column.id === 'battery') {
                          value = batteryFormatter(row)
                        }

                        if (column.id === 'notifications') {
                          value = notificationsFormatter(isUnderTest, value, blid, testbedDisplayName)
                        }

                        return (
                          <TableCell key={column.id} align={column.align} sx={{ fontWeight: `${boldText}` }}>
                            {value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <WorkstationTestbedDetail open={openModal} handleClose={handleClose} />
    </Paper>
  )
})

export default RackTable
