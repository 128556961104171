import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useOktaAuth } from '@okta/okta-react'
import {
  Typography,
  Grid,
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'
import { Stack } from '@mui/system'

const RobotInfoStep = observer(({ getTest, getRobot, getSerial, handleContinue, handleBlock }) => {
  const { authState } = useOktaAuth()
  const [test, setTest] = useState('')
  const [robotName, setRobotName] = useState('')
  const [robotBlid, setRobotBlid] = useState('')
  const [serial, setSerial] = useState('')
  const [testData, setTestData] = useState([])
  const [error, setError] = useState({
    testError: false,
    robotError: false,
    serialError: false,
  })
  const [robotData, setRobotData] = useState([])
  const [fetchingTests, setFetchingTests] = useState(false)
  const [fetchingRobots, setFetchingRobots] = useState(false)
  const [fetchingRobotBlid, setFetchingRobotBlid] = useState(false)
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()

  useEffect(() => {
    const getTests = async () => {
      setFetchingTests(true)
      const res = await client.get(`/test`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setTestData(res.data.issues)
      setFetchingTests(false)
      if (Store.onboardingStore.test) {
        setTest(Store.onboardingStore.test)
      }
    }
    if (idToken) {
      getTests()
    }
    if (Store.onboardingStore.robotData[0].RobotSN) {
      setSerial(Store.onboardingStore.robotData[0].RobotSN)
    }
  }, [idToken])

  useEffect(() => {
    const getRobots = async () => {
      setFetchingRobots(true)
      const res = await client.get(`/robot?test_id=${Store.onboardingStore.selectedTest.id}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setRobotData(res.data)
      setFetchingRobots(false)
      if (Store.onboardingStore.selectedRobot.summary) {
        setRobotName(Store.onboardingStore.selectedRobot.summary)
      }
    }
    if (idToken && test) {
      getRobots()
    }
  }, [idToken, test])

  useEffect(() => {
    const getRobotBlid = async () => {
      setFetchingRobotBlid(true)
      // testing with tupac: I355020J191220E104680
      const res = await client.get(`/robot/sn2rid/${serial}`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setRobotBlid(res.data.RobotID)
      Store.onboardingStore.setRobotBlid(robotBlid)
      Store.onboardingStore.setRobotSN(serial)
      setFetchingRobotBlid(false)
    }
    if (idToken && serial) {
      getRobotBlid()
    }
  }, [idToken, serial, robotBlid])

  useEffect(() => {
    // console.log("handle block")
    // console.log(test)
    // console.log(robotName)
    // console.log(serial)
    // console.log(robotBlid)
    if (test && robotName && serial && robotBlid) {
      handleBlock(true)
      handleContinue(false)
    } else {
      handleContinue(true)
    }
  }, [test, robotName, serial, robotBlid, handleContinue, handleBlock])

  const handleTestChange = event => {
    handleBlock(true)
    setTest(event.target.value)
    getTest(event.target.value)
    if (event.target.value !== Store.onboardingStore.selectedTest.summary) {
      Store.onboardingStore.setRobotInfoSelectedRobot('')
      setRobotName('')
    }
    Store.onboardingStore.setRobotInfoTest(event.target.value)
    Store.onboardingStore.setTestHelper(event.target.value)
    Store.onboardingStore.setRobotInfoSelectedTest(testData.find(test => test.summary === event.target.value))
    // handleError(event)
  }

  const handleRobotNameChange = event => {
    // console.log('robotData')
    console.log(robotData)
    setRobotName(event.target.value)
    getRobot(event.target.value)
    Store.onboardingStore.setRobotName(event.target.value)
    Store.onboardingStore.setRobotHelper(event.target.value)
    // console.log('Store.onboardingStore.selectedRobotHelper')
    // console.log(Store.onboardingStore.selectedRobotHelper)
    Store.onboardingStore.setRobotInfoSelectedRobot(robotData.find(robot => robot.summary === event.target.value))
    // console.log('Store.onboardingStore.selectedRobot')
    // console.log(Store.onboardingStore.selectedRobot)
  }

  const handleInputChange = event => {
    setSerial(event.target.value)
    getSerial(event.target.value)
    Store.onboardingStore.setRobotSN(event.target.value)
    // console.log(Store.onboardingStore)
    handleError(event, 'serial-select')
  }

  const handleError = (event, from) => {
    // childElementCount can be either 0 or 1
    // console.log('Child elements ', event.target.childElementCount)
    if (from === 'test-select' && !test) {
      const errorType = 'testError'
      checkValidation(event, errorType)
    } else if (from === 'robot-select' && !robotName) {
      const errorType = 'robotError'
      checkValidation(event, errorType)
    } else if (from === 'serial-select') {
      const errorType = 'serialError'
      checkValidation(event, errorType)
    }
  }

  const checkValidation = (event, errorType) => {
    if (errorType === 'serialError') {
      if (event.target.value !== '') {
        setError({ ...error, [errorType]: false })
      } else {
        setError({ ...error, [errorType]: true })
      }
    } else {
      if (event.target.childElementCount === 0) {
        setError({ ...error, [errorType]: true })
      } else {
        setError({ ...error, [errorType]: false })
      }
    }
  }

  return (
    <Stack pt={4} px={4}>
      <Typography variant="modal1" align="center" gutterBottom>
        {t('SelectTestAndRobot')}
      </Typography>
      <Typography variant="modal1" align="center" gutterBottom>
        {t('ScanRobotSerial')}
      </Typography>
      <Typography variant="modal1" align="center" gutterBottom sx={{ mb: 4 }}>
        {t('RobotNameMustMatch')}
      </Typography>

      <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
        <Typography variant="subtitle1">{t('Test')}</Typography>
        <FormControl fullWidth required={fetchingTests ? false : true} error={error.testError}>
          {fetchingTests ? (
            <InputLabel id="demo-simple-select-label">
              <CircularProgress size={25} />
            </InputLabel>
          ) : (
            <InputLabel id="demo-simple-select-label">{t('Test')}</InputLabel>
          )}
          <Select
            labelId="demo-simple-select-label"
            id="test-select"
            value={test}
            label={t('Test')}
            onChange={handleTestChange}
            onClose={event => handleError(event, 'test-select')}
            disabled={fetchingTests}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200, maxWidth: '300px' } } }}
          >
            {testData.map(test => {
              return (
                <MenuItem key={test.id} value={test.summary}>
                  {test.summary}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Typography variant="subtitle1">{t('RobotName')}</Typography>
        <FormControl fullWidth required={fetchingRobots ? false : true} error={error.robotError}>
          {fetchingRobots ? (
            <InputLabel id="demo-simple-select-label">
              <CircularProgress size={25} />
            </InputLabel>
          ) : (
            <InputLabel id="demo-simple-select-label">{t('Name')}</InputLabel>
          )}
          <Select
            labelId="demo-simple-select-label"
            id="robot-select"
            key={Store.onboardingStore.selectedTest.id}
            value={robotName}
            label="Name"
            onChange={handleRobotNameChange}
            onClose={event => handleError(event, 'robot-select')}
            disabled={fetchingRobots || !test}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200, maxWidth: '300px' } } }}
          >
            {robotData.map(robot => {
              return (
                <MenuItem key={robot.id} value={robot.summary}>
                  {robot.summary}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>

        <Typography variant="subtitle1">{t('RobotSN')}</Typography>
        <TextField
          id="serial"
          label="Enter Serial Number"
          required={fetchingRobotBlid ? false : true}
          value={serial}
          error={error.serialError}
          onChange={handleInputChange}
          onBlur={event => handleError(event, 'serial-select')}
        />
      </Box>
    </Stack>
  )
})

export default RobotInfoStep
