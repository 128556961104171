import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { observer } from 'mobx-react'
import client from '../../../Config/axiosClient'
import { useHistory, useParams } from 'react-router-dom'
import Store from '../../../Store/Store'
import EndTestSavingModal from '../Modal/EndTestSavingModal'
import { Box, Button, Container, Paper, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import './EndTest.css'

const EndTest = observer(() => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const user = Store.accountInfo.userPK
  const [openEndTestSavingModal, setOpenEndTestSavingModal] = useState(false)
  const robotInfo = Store.testingDataStore.selected.robot;

  const [submitLoading, setSubmitLoading] = useState(false)

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleCancel = () => {
    history.goBack()
  }

  const handleClose = () => {
    setOpenEndTestSavingModal(false)
  }

  async function end_test() {
    const res = await client
      .post(
        '/test/end',
        {
          robot_sn: robotInfo.RobotSN,
          blid: robotInfo.BLID,
          robot_name: robotInfo.RobotName,
          fk_user: user,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('EndTestFailed'), { variant: 'error' })
        throw new Error(error)
      })
    if (res?.status === 200) {
      return res
    }
  }

  const handleEndTest = async () => {
    try {
      setOpenEndTestSavingModal(true)
      // console.log("robotInfo")
      // console.log(robotInfo)
      // console.log(user)
      const result = await end_test()
      // console.log("result")
      // console.log(result)
      setOpenEndTestSavingModal(false)
      enqueueSnackbar(t('EndTestSuccess'), { variant: 'success' })
      history.goBack()

    } catch(error) {
      console.log(error)
      setSubmitLoading(false)
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('EndTest')} {robotInfo.RobotName}
      </Typography>
      <Container maxWidth="lg">
        <Paper sx={{ p: '10px' }}>
          <div className="content-wrapper">
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
              {t('EndTestAreYouSure')} {robotInfo.RobotName}?
            </Typography>
            <Typography variant="body1">{t('ThisActionCannotBeUndone')}</Typography>
          </div>
          <div className="addon-button-wrapper">
            <Button variant="contained" onClick={handleCancel} color="alertPurple" sx={{ m: '0px 6px' }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleEndTest} color="greyGreen" sx={{ m: '0px 6px' }}>
              Yes, End Test
            </Button>
          </div>
        </Paper>
        <EndTestSavingModal open={openEndTestSavingModal} handleClose={handleClose} />
      </Container>
    </Box>
  )
})

export default EndTest
