import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  CircularProgress,
} from '@mui/material'
import { useOktaAuth } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'
import client from '../../../../Config/axiosClient'

const RobotBitResults = observer(props => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const { t } = useTranslation()
  const robotPK = props.robot.PK_Robot
  const [data, setData] = useState([])
  const [fetching, setFetching] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('date')

  const columns = [
    {
      id: 'result',
      label: t('Result'),
      minWidth: 'max-content',
    },
    {
      id: 'date',
      label: t('Date'),
      minWidth: 'max-content',
    },
    {
      id: 'user',
      label: t('User'),
      minWidth: 'max-content',
    },
    {
      id: 'comments',
      label: t('Comments'),
      minWidth: 'max-content',
    },
  ]

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      if (!robotPK) {
        return
      }
      const res = await client
        .get(`/bitfile/${robotPK}`, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          console.log(error)
          setFetching(false)
        })
      let bitResults = []
      for (const item of res.data) {
        const result = item.Bitfile.Result
        const date = item.Bitfile.CreatedDate
        const user = item.User.Name
        const comments = item.Bitfile.Comments
        bitResults.push({
          result: result,
          date: date,
          user: user,
          comments: comments,
        })
      }
      setData(bitResults)
      setFetching(false)
    }
    getData()
  }, [idToken, robotPK])

  let rows = data

  // Formatting
  const getStatusColor = value => {
    const successValues = ['Pass', 'pass']
    const failValues = ['Fail', 'fail']
    if (successValues.includes(value)) {
      return 'success.main'
    }
    if (failValues.includes(value)) {
      return 'error.main'
    }
    return 'black'
  }

  const setTextBold = column => {
    if (column === 'result') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const dateFormater = cell => {
    // console.log(JSON.stringify(date));
    if (!cell) {
      return null
    }
    const dateTime = new Date(cell)

    const formatedDate = dateTime.toLocaleTimeString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formatedDate
  }

  // Page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      let statusColor = ''
      let boldText = ''
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={`bit-${index}`}>
          {columns.map(column => {
            let value = row[column.id]
            if (column.id === 'date') {
              value = dateFormater(value)
            }
            statusColor = getStatusColor(value)
            boldText = setTextBold(column.id)
            return (
              <TableCell key={column.id} sx={{ color: `${statusColor}`, fontWeight: `${boldText}` }}>
                {t(value)}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  sortDirection={orderBy === column.id ? order : false}
                  key={`bit-${column.id}`}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
})

export default RobotBitResults
