import { React, useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Typography,
  Container,
  Paper,
  Grid,
  FormControl,
  TextField,
  Button,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import client from '../../../Config/axiosClient'
import Store from '../../../Store/Store'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'

const Provisioning = () => {
  const { authState } = useOktaAuth()
  const history = useHistory()
  const [robotName, setRobotName] = useState('')
  const [wifiNetwork, setWifiNetwork] = useState(Store.provisioningDataStore.wifiNetwork)
  const [wifiPassword, setWifiPassword] = useState(Store.provisioningDataStore.wifiPassword)
  const [showPassword, setShowPassword] = useState(false)
  const [savePassword, setSavePassword] = useState(Store.provisioningDataStore.savePassword)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const idToken = authState.idToken.idToken
  const [error, setError] = useState({
    robotNameError: false,
    wifiNetworkError: false,
    wifiPasswordError: false,
  })
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleRobotNameChange = event => {
    setRobotName(event.target.value)
    handleError(event, 'robotName')
  }

  const handleWifiNetworkChange = event => {
    setWifiNetwork(event.target.value)
    handleError(event, 'wifiNetwork')
  }

  const handleWifiPasswordChange = event => {
    setWifiPassword(event.target.value)
    handleError(event, 'wifiPassword')
  }

  const handleShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true)
    } else if (showPassword === true) {
      setShowPassword(false)
    }
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const toggleSavePassword = () => {
    if (savePassword === false) {
      setSavePassword(true)
    } else if (savePassword === true) {
      setSavePassword(false)
    }
  }

  useEffect(() => {
    if (robotName && wifiNetwork && wifiPassword) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [robotName, wifiNetwork, wifiPassword])

  const handleError = (event, from) => {
    if (from === 'robotName') {
      const errorType = 'robotNameError'
      checkValidation(event, errorType)
    } else if (from === 'wifiNetwork') {
      const errorType = 'wifiNetworkError'
      checkValidation(event, errorType)
    } else if (from === 'wifiPassword') {
      const errorType = 'wifiPasswordError'
      checkValidation(event, errorType)
    }
  }

  const checkValidation = (event, errorType) => {
    if (event.target.value !== '') {
      setError({ ...error, [errorType]: false })
    } else {
      setError({ ...error, [errorType]: true })
    }
  }

  const handleCancel = () => {
    history.push('/app')
  }

  const handleSubmit = async () => {
    setLoading(true)
    Store.provisioningDataStore.setRobotName(robotName)
    Store.provisioningDataStore.setWifiNetwork(wifiNetwork)
    Store.provisioningDataStore.setSavePassword(savePassword)
    if (savePassword === true) {
      Store.provisioningDataStore.setWifiPassword(wifiPassword)
    } else if (savePassword === false) {
      Store.provisioningDataStore.setWifiPassword('')
    }
    const body = {
      name: robotName,
      display_name: robotName,
      robot_sn: robotName,
      blid: robotName,
      fk_robot_model: 1,
      fk_user: 1,
      notes: '',
    }
    const res = await client
      .post(`/robot`, body, {
        // change `/robot` to a provisioning-specific API
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
    if (res?.status === 200) {
      setLoading(false)
      console.log('POST successful')
      history.push(window.location.pathname + '/success')
    }
  }

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('Provisioning')}
      </Typography>
      <Container maxWidth="sm">
        <Paper>
          <Stack spacing={4} p={4}>
            <Box display="grid" gridTemplateColumns="max-content 1fr" gap={2} width="100%" alignItems="center">
              <Typography variant="subtitle1">{t('RobotName')}</Typography>
              <TextField
                required
                id="Robot Name"
                label={t('Robotname')}
                value={robotName}
                error={error.robotNameError}
                onBlur={event => handleError(event, 'robotName')}
                onChange={event => handleRobotNameChange(event)}
              />

              <Typography variant="subtitle1">{t('WifiNetwork')}</Typography>
              <TextField
                required
                id="WiFi Network"
                label={t('WifiNetworkname')}
                value={wifiNetwork}
                error={error.wifiNetworkError}
                onBlur={event => handleError(event, 'wifiNetwork')}
                onChange={event => handleWifiNetworkChange(event)}
              />

              <Typography variant="subtitle1">{t('WiFiPassword')}</Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="WiFi Password" error={error.wifiPasswordError}>
                  {t('WiFiPassword')} *
                </InputLabel>
                <OutlinedInput
                  required
                  id="WiFi Password"
                  label={t('WiFipassword')}
                  value={wifiPassword}
                  error={error.wifiPasswordError}
                  onBlur={event => handleError(event, 'wifiPassword')}
                  onChange={event => handleWifiPasswordChange(event)}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <span></span>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={savePassword} onChange={toggleSavePassword} />}
                  label={t('Save password')}
                />
              </FormGroup>
            </Box>

            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
              <Button onClick={handleCancel} variant="contained" color="greyGreen">
                {t('Cancel')}
              </Button>
              <LoadingButton onClick={handleSubmit} loading={loading} disabled={submitDisabled} variant="contained">
                {t('Submit')}
              </LoadingButton>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Box>
  )
}

export default Provisioning
