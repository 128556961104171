import { makeAutoObservable } from 'mobx'
import { clearPersistedStore, makePersistable } from 'mobx-persist-store'

class BatteryDataStore {
  pk_battery = ''

  constructor() {
    makeAutoObservable(this)
    makePersistable(this, {
      name: 'BatteryDataStore',
      properties: ['pk_battery'],
      storage: window.localStorage,
    })
  }

  setPK_Battery = pk_battery => {
    this.pk_battery = pk_battery
  }

  resetBatteryData = () => {
    this.setPk_Battery = ''
    clearPersistedStore(this)
  }
}

export default BatteryDataStore
