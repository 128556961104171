import React, { useState, useEffect } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Stack,
} from '@mui/material'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import CustomSearch from '../../../Search/Search'
import Store from '../../../../Store/Store'
import client from '../../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'
import { globalStatus } from '../../../../Constants/global.js'

const TrackTable = observer(() => {
  const history = useHistory()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [filters, setFilters] = useState({
    ready: false,
    needs_cleaning: false,
    needs_repair: false,
    under_test: false,
  })
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('location')
  const [data, setData] = useState([])
  const { authState } = useOktaAuth()
  const { t } = useTranslation()
  const [fetching, setFetching] = useState(false)

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      const res = await client.get('/testbed', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      let data = []
      for (const item of res.data) {
        const location = [item.Workstation[0].Name, '.', item.Rack[0].Name, '.', item.Shelf[0].Name].join('')
        const code = item.Name
        const status = item.Status[0].Name
        // const status = item.Status.find(o => o.PK_TestbedStatus === item.History[0].FK_TestbedStatus).DisplayName
        const activeStatuses = item.History.filter(o => o.EndDate == null)

        const testbed_id = item.PK_Testbed
        data.push({
          location: location,
          code: code,
          status: status,
          testbed_id: testbed_id,
          activeStatuses: activeStatuses,
        })
      }
      setData(data)
      setFetching(false)
      console.log(data)
    }
    getData()
  }, [idToken])

  // Data from API
  let rows = data

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClick = (event, row) => {
    Store.assetTracking.resetRowData()
    Store.assetTracking.setRowData(row)
    history.push('/app/testbed/id')
  }

  const columns = [
    { id: 'location', label: t('Location'), minWidth: 'max-content' },
    { id: 'code', label: t('Testbed'), minWidth: 'max-content' },
    {
      id: 'status',
      label: t('Status'),
      minWidth: 'max-content',
    },
  ]

  const getStatusColor = value => {
    if (globalStatus.warning.values.testbed.includes(value)) {
      return globalStatus.warning.themeColor
    }
    if (globalStatus.caution.values.testbed.includes(value)) {
      return globalStatus.caution.themeColor
    }
    if (globalStatus.info.values.testbed.includes(value)) {
      return globalStatus.info.themeColor
    }
    if (globalStatus.success.values.testbed.includes(value)) {
      return globalStatus.success.themeColor
    }
    return 'black'
  }

  const setTextBold = column => {
    if (column === 'status') {
      return 'bold'
    } else {
      return 'normal'
    }
  }

  const onFilterChanged = name => event => {
    setFilters({ ...filters, [name]: event.target.checked })
  }

  const filterArray = Object.keys(filters)
  const filteredRows = rows.filter(row => {
    const filterKeyTrue = filterArray.some(filterKey => {
      return filterKey === row.status && filters[filterKey] === true
    })
    if (filterKeyTrue) {
      return row
    } else {
      return null
    }
  })

  if (filteredRows.length > 0) {
    rows = filteredRows
  }

  // Search
  function handleSearchUpdate(value) {
    setSearch(value)
  }

  rows = rows.filter(row => {
    if (search === '') {
      return row
    }
    return (
      row.status.toLowerCase().includes(search.toLowerCase()) ||
      row.location.toLowerCase().includes(search.toLowerCase()) ||
      row.code.toLowerCase().includes(search.toLowerCase())
    )
  })

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const filterNames = ['under_test', 'ready', 'needs_cleaning', 'needs_repair']
  const filterList = filterNames.map(item => {
    return (
      <FormControlLabel
        key={`formControlLabel-${item}`}
        value="end"
        control={<Checkbox checked={filters[item]} onChange={onFilterChanged(item)} value={item} />}
        label={t(item)}
        labelPlacement="end"
      />
    )
  })

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      let statusColor = ''
      let boldText = ''
      return (
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={`testbed-${index}`}
          onClick={event => handleClick(event, row)}
          sx={{ cursor: 'pointer' }}
        >
          {columns.map(column => {
            const value = row[column.id]
            statusColor = getStatusColor(value)
            boldText = setTextBold(column.id)
            return (
              <TableCell key={column.id} sx={{ color: `${statusColor}`, fontWeight: `${boldText}` }}>
                {t(value)}
              </TableCell>
            )
          })}
        </TableRow>
      )
    })

  return (
    <Box>
      <Paper sx={{ p: 2 }}>
        {/* Toolbar */}
        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mb: 1, ml: 2 }}>
          <Stack direction="row" alignItems="center" sx={{ mx: 2 }}>
            <Typography variant="modal2" sx={{ mr: 2 }}>
              {t('Filterbystatus')}:
            </Typography>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                {filterList}
              </FormGroup>
            </FormControl>
          </Stack>
          {/* Search */}
          <Box sx={{ minWidth: '20em', flexGrow: '2' }}>
            <CustomSearch search={search} handleSearchUpdate={handleSearchUpdate} />
          </Box>
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    sortDirection={orderBy === column.id ? order : false}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
})

export default TrackTable
