import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Route, useHistory } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { Slide } from '@mui/material'

import AddBattery from './Protected App/Onboarding/AddBattery'
import SwapBattery from './Protected App/Testing/SwapBattery'
import AddOnTests from './Protected App/Onboarding/AddOnTests'
import AddRobot from './Protected App/Onboarding/AddRobot'
import AppHome from './Protected App/AppHome'
import BatteryOnboardSuccess from './Protected App/Onboarding/Notification Screens/BatteryOnboardSuccess'
import EditBatteryVariant from './Protected App/Admin/EditBatteryVariant'
import EditTestbed from './Protected App/Admin/EditTestbed'
import EndTest from './Protected App/Testing/EndTest'
import ErrorDetails from './Protected App/Testing/ErrorDetails'
import ErrorReport from './Protected App/Testing/Reports/ErrorReport'
import Footer from './Footer/Footer'
import Home from './Custom Okta Login/Home'
import InspectRUT from './Protected App/Testing/InspectRUT'
import Login from './Custom Okta Login/LoginWidget'
import NavBar from './Navigation/NavBar'
import PrintLabels from './Protected App/Onboarding/PrintLabels'
import Profile from './Protected App/Profile/Profile'
import Provisioning from './Protected App/Provisioning/Provisioning'
import ProvisioningSuccess from './Protected App/Provisioning/ProvisioningSuccess'
import React from 'react'
import Repairs from './Protected App/Repairs/Repairs'
import RepairDetails from './Protected App/Testing/RepairDetails'
import RepairRobot from './Protected App/Repairs/RepairRobot'
import RetireRobot from './Protected App/Testing/RetireRobot'
import RobotDetail from './Protected App/Asset Tracking/RobotDetail'
import RobotOnboardSuccess from './Protected App/Onboarding/Notification Screens/RobotOnboardSuccess'
// import RobotAlreadyOnboarded from './Protected App/Onboarding/Notification Screens/RobotAlreadyOnboarded'
// import RobotNotFound from './Protected App/Onboarding/Notification Screens/RobotNotFound'
import RobotOnboarding from './Protected App/Onboarding/Robot Onboarding/RobotOnboarding'
import Robots from './Protected App/Asset Tracking/Robots'
import Settings from './Protected App/Profile/Settings'
import SpareParts from './Protected App/Asset Tracking/SpareParts'
import StartRobotTest from './Protected App/Testing/StartRobotTest'
import Testbeds from './Protected App/Asset Tracking/Testbeds'
import TestbedDetail from './Protected App/Asset Tracking/TestbedDetail'
import TestModalScreens from './Protected App/Modal/TestModalsScreen'
import Theme from './Theme/Theme'
import UploadBiTResults from './Protected App/Testing/UploadBiTResults'
import WorkstationOverview from './Protected App/Testing/WorkstationOverview'
import WorkstationSelection from './Protected App/Testing/WorkstationSelection'
import { config } from './Custom Okta Login/oktaConfig'
import { observer } from 'mobx-react'

const App = observer(() => {
  const history = useHistory()
  const oktaAuth = new OktaAuth(config)

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login')
  }

  const onAuthResume = async () => {
    history.push('/login')
  }

  // Reliability Growth Testing

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <NavBar />
        <main>
          <div>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              TransitionComponent={Slide}
              maxSnack={5}
              autoHideDuration={5000}
            >
              <Container maxWidth="lg" sx={{ minHeight: '100%' }}>
                {/* Uncomment this for wider content */}
                {/* <Container maxWidth="false" sx={{ minHeight: '100%', maxWidth: '70%' }}> */}
                {/* Or do maxWidth='lg' for width to not be larger than 1200px */}
                <Box mt={4}>
                  <Route path="/" exact={true} component={Home} />
                  <Route path="/login" render={() => <Login />} />
                  <Route
                    path="/login/callback"
                    render={props => <LoginCallback {...props} onAuthResume={onAuthResume} />}
                  />
                  <SecureRoute path="/app" exact={true} component={AppHome} />
                  <SecureRoute path="/app/admin/testbed" exact={true} component={EditTestbed} />
                  <SecureRoute path="/app/admin/battery_variant" exact={true} component={EditBatteryVariant} />
                  <SecureRoute path="/app/error/details" exact={true} component={ErrorDetails} />
                  <SecureRoute path="/app/testbed" exact={true} component={Testbeds} />
                  <SecureRoute path="/app/testbed/id" exact={true} component={TestbedDetail} />
                  <SecureRoute path="/app/robot" exact={true} component={Robots} />
                  <SecureRoute path="/app/robot/id" exact={true} component={RobotDetail} />
                  <SecureRoute path="/app/robot/id/:blid" exact={true} component={RobotDetail} />
                  <SecureRoute path="/app/spare_part" exact={true} component={SpareParts} />
                  <SecureRoute path="/app/onboarding/battery" exact={true} component={AddBattery} />
                  <SecureRoute path="/app/onboarding/battery/success" exact={true} component={BatteryOnboardSuccess} />
                  <SecureRoute path="/app/onboarding/robot" exact={true} component={AddRobot} />
                  <SecureRoute path="/app/modal" exact={true} component={TestModalScreens} />
                  <SecureRoute path="/app/onboarding/robot/success" exact={true} component={RobotOnboardSuccess} />
                  {/* <SecureRoute path="/app/onboarding/robot/rut_exists" exact={true} component={RobotAlreadyOnboarded} /> */}
                  {/* <SecureRoute path="/app/onboarding/robot/not_found" exact={true} component={RobotNotFound} /> */}
                  <SecureRoute path="/app/onboarding/robot/process" exact={true} component={RobotOnboarding} />
                  <SecureRoute path="/app/onboarding/print_label" exact={true} component={PrintLabels} />
                  <SecureRoute path="/app/onboarding/addon_tests" exact={true} component={AddOnTests} />
                  <SecureRoute path="/app/provisioning" exact={true} component={Provisioning} />
                  <SecureRoute path="/app/provisioning/success" exact={true} component={ProvisioningSuccess} />
                  <SecureRoute path="/app/testing" exact={true} component={WorkstationSelection} />
                  <SecureRoute path="/app/testing/workstation" exact={true} component={WorkstationOverview} />
                  <SecureRoute path="/app/testing/inspect_rut" exact={true} component={InspectRUT} />
                  <SecureRoute path="/app/testing/start_test" exact={true} component={StartRobotTest} />
                  <SecureRoute path="/app/testing/swap_battery" exact={true} component={SwapBattery} />
                  <SecureRoute path="/app/testing/upload_bit" exact={true} component={UploadBiTResults} />
                  <SecureRoute path="/app/testing/error" exact={true} component={ErrorReport} />
                  <SecureRoute path="/app/testing/end_test/:blid" exact={true} component={EndTest} />
                  <SecureRoute path="/app/testing/retire_robot/:blid" exact={true} component={RetireRobot} />
                  <SecureRoute path="/app/user" exact={true} component={Profile} />
                  <SecureRoute path="/app/settings" exact={true} component={Settings} />
                  <SecureRoute path="/app/repair/details" exact={true} component={RepairDetails} />
                  <SecureRoute path="/app/repairs" exact={true} component={Repairs} />
                  <SecureRoute path="/app/repair_robot/:robot_name" exact={true} component={RepairRobot} />
                </Box>
              </Container>
            </SnackbarProvider>
          </div>
        </main>
        <Footer />
      </ThemeProvider>
    </Security>
  )
})

export default App
