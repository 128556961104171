import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Backdrop, Modal, Fade, Typography, Box, Button, CircularProgress, Grid } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import './RobotSavingModal.css'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'

const style = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '4px',
}

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    width: '350px',
    height: '400px',
    backgroundColor: 'white',
    boxShadow: '0px 24px 38px #00000024',
    outline: 'none',
  },
}))

const RobotSavingModal = ({ open, handleClose }) => {
  const { authState } = useOktaAuth()
  const classes = useStyles()
  const idToken = authState.idToken.idToken
  const robotData = Store.onboardingStore.robotData
  const { t } = useTranslation()

  //   useEffect(() => {
  //     const getImage = async () => {
  //       let res = await client.get(`/robot/image/${robotData.FK_RobotModel}`, {
  //         headers: {
  //           Authorization: `Bearer ${idToken}`,
  //         },
  //       })
  //       setRobotImage(res.data.image)
  //     }
  //     const getLabel = async () => {
  //       const date = new Date().toISOString()
  //       let res = await client.get(
  //         `/robot/label?blid=${robotData.BLID}&serial_number=${robotData.RobotSN}&robot_model=${robotData.FK_RobotModel}&onboarded_date=${date}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${idToken}`,
  //           },
  //         }
  //       )
  //       setRobotLabel(res.data.url)
  //     }
  //     if (idToken && open) {
  //       getImage()
  //       getLabel()
  //     }
  //   }, [idToken, open, robotData])

  return (
    <div>
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div style={style} className={classes.paper}>
            <div className="x-button">
              {/* <Button onClick={handleClose}>
                <Close />
              </Button> */}
            </div>
            <Typography
              id="transition-modal-title"
              variant="h4"
              align="center"
              style={{ color: '#6CB86A', paddingTop: '5px' }}
            >
              {t('SavingRobotInformation')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                width: 1,
                height: 1,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="success" size="80px" />
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default RobotSavingModal
