import React from 'react'
import { useSnackbar } from 'notistack'
import { Grid, Typography, Box, Button } from '@mui/material'
import { Album, LocationOn, PersonAdd, RateReview, Report, Construction, Cloud, Shield } from '@mui/icons-material'
import { useOktaAuth } from '@okta/okta-react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'

import ItemCard from './Item Card/ItemCard'
import CustomModal from './Modal/CustomModal'
import Store from '../../Store/Store'
import client from '../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const AppHome = observer(() => {
  const { authState } = useOktaAuth()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const idToken = authState.idToken.idToken
  const history = useHistory()
  const { t } = useTranslation()

  if (!authState.isAuthenticated && !authState.isPending) {
    history.push('/login')
  }

  const handleLambda = () => {
    async function test_lambda() {
      let body = {
        robot_id: 1,
        status_id: 4,
        user_id: 2,
      }
      const res = await client
        // .get('/robot', {
        //   headers: {
        //     Authorization: `Bearer ${idToken}`,
        //   },
        // })
        .post('/robot/status/39?remove=true', body, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .catch(error => {
          enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
          throw new Error(error)
        })
      if (res?.status === 200) {
        console.log(res.data)
        return
      }
    }
    test_lambda()
  }

  // To add items to modal, edit ./Modal/ModalItems.js
  // To add a new items without modal, edit ./Item Card/ItemCard.js

  return (
    <Box>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} gutterBottom>
        {t('ReliabilityGrowthTesting')}
      </Typography>
      {/* <Button variant="contained" onClick={handleLambda}>
        Run Lambda
      </Button> */}
      <Grid
        container
        columnSpacing={3}
        rowSpacing={3}
        direction="row"
        sx={{ mt: 8 }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <ItemCard name={t('Admin')} icon={<Shield style={{ color: '#AE72A3' }} />} />
        </Grid>
        <Grid item>
          <ItemCard name={t('AssetTracking')} icon={<LocationOn style={{ color: '#87A28E' }} />} />
        </Grid>
        {/* <Grid item>
          <ItemCard name={t('Provisioning')} provisioningItem={true} icon={<Cloud style={{ color: '#6A768C' }} />} />
        </Grid> */}
        <Grid item>
          <ItemCard name={t('Onboarding')} icon={<PersonAdd style={{ color: '#EBC172' }} />} />
        </Grid>
        <Grid item>
          <ItemCard name={t('Testing')} testingItem={true} icon={<Album style={{ color: '#518A70' }} />} />
        </Grid>
        {/* <Grid item>
          <ItemCard name={t('Repairs')} repairsItem={true} icon={<Construction style={{ color: '#8291AD' }} />} />
        </Grid> */}
        <Grid item style={{ display: 'none' }}>
          <ItemCard name={t('Evaluation')} icon={<RateReview style={{ color: '#AE72A3' }} />} />
        </Grid>
        <Grid item style={{ display: 'none' }}>
          <ItemCard name={t('Reporting')} icon={<Report style={{ color: '#6A768C' }} />} />
        </Grid>
        <CustomModal title={Store.modalTitle} />
      </Grid>
    </Box>
  )
})

export default AppHome
