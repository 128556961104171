import React from 'react'
import { observer } from 'mobx-react'
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material'
import { BatteryCharging20, Build, Cancel, CleaningServices, Error, NightsStay, Verified } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import errorCodeObjects from './ErrorCodeObjects'
import './WorkstationDetailNotifications.css'
import { globalStatus } from '../../../../Constants/global'
import { Stack } from '@mui/system'
import { useHistory } from 'react-router-dom'
import Store from '../../../../Store/Store'

const WorkstationDetailNotifications = observer(props => {
  const { t } = useTranslation()
  const history = useHistory()
  const array = props.array
  const type = props.type
  const error = props.error

  // Make battery notification and error notification a seperate component
  // should get this from the robot
  const batteryInfo = 'Battery SOC is <15%'
  const errorInfo = errorCodeObjects[2]

  if (array == null || array.length == 0) {
    return null
  }

  const hideValues = [
    ...globalStatus.ignore.values.all,
    ...globalStatus.info.values.all,
    ...globalStatus.success.values.all,
  ]

  const handleClick = (type, payload) => {
    if (type == 'robot_error') {
      Store.testingDataStore.setErrorDetails(payload)
      history.push('/app/error/details', { from: '/app/testing/workstation' })
    }
    if (type == 'robot_bit') {
      history.push('/app/testing/upload_bit', { from: '/app/testing/workstation' })
    }
    if (type == 'robot_battery') {
      history.push('/app/testing/swap_battery', { from: '/app/testing/workstation' })
    }
    if (type == 'robot_needs_repair') {
      const robotName = payload
      history.push('/app/repair_robot/' + robotName)
    }
    if (type == 'testbed_needs_repair') {
      Store.testingDataStore.setRepairType('testbed')
      Store.testingDataStore.setRepairNotes(payload)
      history.push('/app/repair/details', { from: '/app/testing/workstation' })
    }
  }

  function notification(key, icon, title, details, name, payload) {
    return (
      <Card
        elevation={0}
        key={key}
        onClick={() => handleClick(name, payload)}
        sx={{
          height: '5rem',
          width: '100%',
          backgroundColor: '#F9ECD4',
          borderRadius: '4px',
        }}
      >
        <CardActionArea sx={{ height: '100%' }}>
          <CardContent>
            <Stack direction="row" spacing={8} alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1} minWidth="30%">
                {icon}
                <Typography variant="modal4">{title}</Typography>
              </Stack>
              {details}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>

      // Try this when CSS Subgrid has more support
      // <Box
      //   key={key}
      //   sx={{
      //     height: '5rem',
      //     width: '100%',
      //     padding: 2,
      //     backgroundColor: '#F9ECD4',
      //     borderRadius: '4px',
      //     display: 'grid',
      //     gridColumn: 'span 2',
      //     gridTemplateColumns: 'subgrid',
      //   }}
      // >
      //   <Stack direction="row" spacing={8} alignItems="center">
      //     <Stack direction="row" alignItems="center" spacing={1}>
      //       {icon}
      //       <Typography variant="modal4">{title}</Typography>
      //     </Stack>
      //     {details}
      //   </Stack>
      // </Box>
    )
  }

  return (
    // Try this when CSS Subgrid has more support
    // <Box
    //   sx={{
    //     display: 'grid',
    //     gridTemplateColumns: 'max-content 1fr',
    //     gap: 2,
    //     width: '100%',
    //     alignItems: 'center',
    //     justifyItems: 'start',
    //     marginBottom: 2,
    //   }}
    // >
    <Stack spacing={2} mb={2}>
      {array
        .filter(item => {
          if (hideValues.includes(item.Name)) {
            return false
          } else {
            return true
          }
        })
        .map((item, index) => {
          if (item.type !== type) {
            return
          }
          let key = `${item.type}-${index}`
          let icon = ''
          let title = item.Name
          let details = ''
          let name = item.type
          let payload = ''

          // testbed notifications
          if (item.type == 'testbed') {
            if (item.Name == 'needs_cleaning') {
              key = `${item.type}-${item.Name}`
              icon = <CleaningServices fontSize="large" />
              title = t('needs_cleaning')
              details = <Typography variant="modal1">{t('TestbedNeedsCleaning')}</Typography>
              name = 'testbed_needs_cleaning'
              payload = ''
            }
            if (item.Name == 'being_cleaned') {
              key = `${item.type}-${item.Name}`
              icon = <CleaningServices fontSize="large" />
              title = t('being_cleaned')
              details = <Typography variant="modal1">{t('TestbedBeingCleaned')}</Typography>
              name = 'testbed_being_cleaned'
              payload = ''
            }
            if (item.Name == 'needs_repair') {
              key = `${item.type}-${item.Name}`
              icon = <Build fontSize="large" />
              title = t('needs_repair')
              if (item.notes) {
                details = (
                  <Stack>
                    <Typography variant="modal1">{`${t('Notes')}:`}</Typography>
                    <Typography noWrap maxWidth="35em">
                      {item.notes}
                    </Typography>
                  </Stack>
                )
              } else {
                details = <Typography variant="modal1">{t('TestbedNeedsRepair')}</Typography>
              }
              name = 'testbed_needs_repair'
              payload = item.notes
            }
            if (item.Name == 'under_repair') {
              key = `${item.type}-${item.Name}`
              icon = <Build fontSize="large" />
              title = t('under_repair')
              details = <Typography variant="modal1">{t('TestbedUnderRepair')}</Typography>
              name = 'testbed_under_repair'
              payload = ''
            }
            if (item.Name == 'retired') {
              key = `${item.type}-${item.Name}`
              icon = <NightsStay fontSize="large" />
              title = t('Retired')
              details = <Typography variant="modal1">{t('TestbedRetired')}</Typography>
              name = 'testbed_retired'
              payload = ''
            }
            return notification(key, icon, title, details, name, payload)
          }

          // robot notifications
          if (item.type == 'robot') {
            if (item.Name == 'error') {
              return error.map((entry, index) => {
                key = `${type}-${item.Name}-${index}`
                icon = <Error fontSize="large" />
                title = `${t('Error')} ${entry.Code ? entry.Code : ''}`
                details = (
                  <Stack>
                    <Typography variant="modal1">{entry.Name ? entry.Name : t('DatabaseMismatch')}</Typography>
                    <Typography noWrap maxWidth="35em">
                      {entry.Description ? entry.Description : t('ErrorDetailsMissing')}
                    </Typography>
                  </Stack>
                )
                name = 'robot_error'
                payload = entry
                return notification(key, icon, title, details, name, payload)
              })
            }
            if (item.Name == 'bit') {
              icon = <Verified fontSize="large" />
              title = t('bit')
              details = <Typography variant="modal1">{t('BitRequired')}</Typography>
              name = 'robot_bit'
              payload = ''
            }
            if (item.Name == 'battery') {
              icon = <BatteryCharging20 fontSize="large" />
              title = t('Lowbattery')
              details = (
                <Stack>
                  <Typography variant="modal1">{batteryInfo}</Typography>
                  <Typography>{t('ChangeBattery')}</Typography>
                </Stack>
              )
              name = 'robot_battery'
              payload = ''
            }
            if (item.Name == 'stopped') {
              icon = <Cancel fontSize="large" />
              title = t('Stopped')
              details = <Typography variant="modal1">{t('StoppedRobot')}</Typography>
              name = 'robot_stopped'
              payload = ''
            }
            if (item.Name == 'needs_repair') {
              key = `${item.type}-${item.Name}`
              icon = <Build fontSize="large" />
              title = t('needs_repair')
              if (item.notes) {
                details = (
                  <Stack>
                    <Typography variant="modal1">{`${t('Notes')}:`}</Typography>
                    <Typography>{item.notes}</Typography>
                  </Stack>
                )
              } else {
                details = <Typography variant="modal1">{t('RobotNeedsRepair')}</Typography>
              }
              name = 'robot_needs_repair'
              payload = Store.testingDataStore.selected.robot.RobotName
            }
            if (item.Name == 'under_repair') {
              key = `${item.type}-${item.Name}`
              icon = <Build fontSize="large" />
              title = t('under_repair')
              details = <Typography variant="modal1">{t('RobotUnderRepair')}</Typography>
              name = 'under_repair'
              payload = ''
            }
            if (item.Name == 'retired') {
              key = `${item.type}-${item.Name}`
              icon = <NightsStay fontSize="large" />
              title = t('Retired')
              details = <Typography variant="modal1">{t('RobotRetired')}</Typography>
              name = 'robot_retired'
              payload = ''
            }
            return notification(key, icon, title, details, name, payload)
          }
        })}
    </Stack>
    // </Box>
  )
})

export default WorkstationDetailNotifications
