import React from 'react'
import { Box } from '@mui/material'

const TabsPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: '10px' }}>{children}</Box>}
    </div>
  )
}

export default TabsPanel
