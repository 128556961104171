import axios from 'axios'
import axiosRetry from 'axios-retry'
import { API_URL } from '../Store/Config'

const client = axios.create({ baseURL: API_URL })
axiosRetry(client, {
  retries: 0,
  retryCondition: error => {
    console.log(error)
    return true
  },
})

export default client
