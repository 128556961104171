import React, { useState, useEffect } from 'react'
import { Box, Typography, Paper, Grid, Button, Stack, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react'
import { useOktaAuth } from '@okta/okta-react'
import Store from '../../../Store/Store'
import client from '../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'
import RequestRepairModal from '../Modal/RequestRepairModal'
import SimpleCheckModal from '../Modal/SimpleCheckModal'
import TestbedStatus from './Tables/TestbedStatus'
import TestbedHistory from './Tables/TestbedHistory'

const TestbedDetail = observer(() => {
  const { authState } = useOktaAuth()
  const rowData = Store.assetTracking.rowData
  const { t } = useTranslation()
  const [robotInfo, setRobotInfo] = useState({})
  const [statusHistory, setStatusHistory] = useState([])
  const [activeStatuses, setActiveStatuses] = useState([])
  const [openRequestRepairModal, setOpenRequestRepairModal] = useState(false)
  const [openCheckModal, setOpenCheckModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalType, setModalType] = useState('')
  const [loading, setLoading] = useState(false)
  const refresh = Store.assetTracking.refresh

  const [fetching, setFetching] = useState(false)

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      const res = await client.get('/testbed/' + rowData.testbed_id, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      // reset requestedCleaning and requestedRepair to false
      // later, we'll set them true if needed
      Store.assetTracking.setRequestedCleaning(false)
      Store.assetTracking.setRequestedRepair(false)
      let history = []
      for (const item of res.data[0].History) {
        const status = item.TestbedStatus[0].Name
        const createdDate = item.CreatedDate
        const createdBy = item.User.find(o => o.PK_User === item.CreatedBy).Name
        const endDate = item.EndDate
        const endedBy = item.EndedBy ? item.User.find(o => o.PK_User === item.EndedBy).Name : ''
        // const createdBy = item.User.find(o => o.PK_User === item.TestbedStatusProfile.CreatedBy).DisplayName || ''
        history.push({
          status: status,
          createdDate: createdDate,
          createdBy: createdBy,
          endDate: endDate,
          endedBy: endedBy,
        })
        if (!endDate) {
          if (status == 'needs_cleaning' || status == 'being_cleaned') {
            Store.assetTracking.setRequestedCleaning(true)
          }
          if (status == 'needs_repair' || status == 'under_repair') {
            Store.assetTracking.setRequestedRepair(true)
          }
        }
      }
      setStatusHistory(history)
      const robot = res.data[0].Robot?.[0]?.Robot
      setRobotInfo(robot)

      const statuses = history
        .filter(status => status.endDate == null)
        .map((item, index) => {
          item.priority = index + 1
          return item
        })
      setActiveStatuses(statuses)
      setFetching(false)
    }
    getData()
  }, [idToken, rowData, refresh])

  // Testbed lifecycle database calls
  async function newTestbedStatus(status_name, notes) {
    setLoading(true)
    const res = await client
      .post(
        '/testbed/status',
        {
          fk_testbed: rowData.testbed_id,
          testbed_status: status_name,
          fk_user: Store.accountInfo.userPK,
          notes: notes,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        console.log(error)
        setLoading(false)
        return false
      })
    if (res?.status === 200) {
      setLoading(false)
      return true
    }
  }

  async function closeTestbedStatus(status_name) {
    setLoading(true)
    const res = await client
      .post(
        '/testbed/status',
        {
          fk_testbed: rowData.testbed_id,
          testbed_status: status_name,
          fk_user: Store.accountInfo.userPK,
          notes: '',
          close: true,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      )
      .catch(error => {
        enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
        console.log(error)
        setLoading(false)
        return false
      })
    if (res?.status === 200) {
      setLoading(false)
      return true
    }
  }

  const handleRequestCleaning = async () => {
    await newTestbedStatus('needs_cleaning', '')
    handleCloseModals()
  }

  const handleCompleteCleaning = async () => {
    await closeTestbedStatus('needs_cleaning')
    await closeTestbedStatus('being_cleaned')
    handleCloseModals()
  }

  const handleRequestRepair = async () => {
    await newTestbedStatus('needs_repair', Store.assetTracking.repairComment)
    handleCloseModals()
  }

  const handleCompleteRepair = async () => {
    await closeTestbedStatus('needs_repair')
    await closeTestbedStatus('under_repair')
    Store.assetTracking.setRepairComment('')
    handleCloseModals()
  }

  const handleOpenRepairModal = () => {
    setOpenRequestRepairModal(true)
  }

  const handleOpenCheckModal = type => {
    setModalType(type)
    if (type === 'repair-complete') {
      setModalTitle(t('CompleteRepair?'))
    }
    if (type === 'cleaning-request') {
      setModalTitle(t('RequestCleaning?'))
    }
    if (type === 'cleaning-complete') {
      setModalTitle(t('CompleteCleaning?'))
    }
    setOpenCheckModal(true)
  }

  const handleCloseModals = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenRequestRepairModal(false)
      setOpenCheckModal(false)
      Store.assetTracking.setRefresh()
    }
  }

  const handleCheck = type => {
    if (type === 'repair-complete') {
      handleCompleteRepair()
    }
    if (type === 'cleaning-request') {
      handleRequestCleaning()
    }
    if (type === 'cleaning-complete') {
      handleCompleteCleaning()
    }
  }

  const fetchingTemplate = (
    <Box sx={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={30} sx={{ m: '2em' }} />
    </Box>
  )

  const rutInfo = (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Name')}:
        </Typography>
        <Typography>{fetching ? '—' : robotInfo?.DisplayName ? robotInfo.DisplayName : '—'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Serial')}:
        </Typography>
        <Typography>{fetching ? '—' : robotInfo?.RobotSN ? robotInfo.RobotSN : '—'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Model')}:
        </Typography>
        <Typography>—</Typography>
      </Stack>
    </Stack>
  )

  return (
    <Stack spacing={4}>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} mb={-2.5}>
        {rowData.location} - {rowData.code}
      </Typography>

      {/* Action Buttons */}
      <Paper sx={{ p: 2 }}>
        <Stack direction="row">
          {/* Cleaning Buttons */}
          <Grid container direction="row" spacing={2} justifyContent="left" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                disabled={Store.assetTracking.requestedCleaning}
                onClick={() => handleOpenCheckModal('cleaning-request')}
              >
                {t('RequestCleaning')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="greyGreen"
                disabled={!Store.assetTracking.requestedCleaning}
                onClick={() => handleOpenCheckModal('cleaning-complete')}
              >
                {t('CompleteCleaning')}
              </Button>
            </Grid>
          </Grid>

          {/* Repair Buttons */}
          <Grid container direction="row" spacing={2} justifyContent="right" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                disabled={Store.assetTracking.requestedRepair}
                onClick={handleOpenRepairModal}
              >
                {t('RequestRepair')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="greyGreen"
                disabled={!Store.assetTracking.requestedRepair}
                onClick={() => handleOpenCheckModal('repair-complete')}
              >
                {t('CompleteRepair')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Stack direction="row" spacing={4} justifyContent="space-evenly">
        {/* Active Statuses */}
        <Paper sx={{ p: 2, pt: 1 }}>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {t('ActiveStatuses')}
          </Typography>
          <TestbedStatus rows={activeStatuses} fetching={fetching} />
        </Paper>

        {/* RUT Info */}
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {t('RutInfo')}
          </Typography>
          {fetching ? fetchingTemplate : rutInfo}
        </Paper>
      </Stack>

      {/* Testbed History */}
      <Paper sx={{ p: 2, pt: 1 }}>
        <Typography variant="h6" align="center" sx={{ mb: 1 }}>
          {t('ActivityHistory')}
        </Typography>
        <TestbedHistory rows={statusHistory} fetching={fetching} />
      </Paper>

      <RequestRepairModal
        open={openRequestRepairModal}
        handleClose={handleCloseModals}
        handleRequestRepair={handleRequestRepair}
        loading={loading}
      />
      <SimpleCheckModal
        open={openCheckModal}
        handleClose={handleCloseModals}
        title={modalTitle}
        type={modalType}
        handleCheck={handleCheck}
        loading={loading}
      />
    </Stack>
  )
})

export default TestbedDetail
