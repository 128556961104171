import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Autocomplete, TextField, CircularProgress } from '@mui/material'
import { observer } from 'mobx-react'
import Store from '../../../../../Store/Store'
import client from '../../../../../Config/axiosClient'

const SupplierDropdown = observer(({ value, handleChange, id, index, currentlyEditing, disable }) => {
  const { authState } = useOktaAuth()
  const [supplierData, setSupplierData] = useState([])
  const [fetchingComponents, setFetchingComponents] = useState(false)
  const [isNotEmpty, setIsNotEmpty] = useState(true)
  const idToken = authState.idToken.idToken
  const suppliersData = Store.onboardingStore.suppliersData

  useEffect(() => {
    const getSubcomponents = async () => {
      setFetchingComponents(true)
      const res = await client.get(`/supplier`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setSupplierData(res.data)
      setFetchingComponents(false)
      Store.onboardingStore.setSuppliersData(res.data)
      if (res.data.length === 0) {
        setIsNotEmpty(false)
      }
    }

    if (suppliersData.length === 0 && idToken && isNotEmpty) {
      getSubcomponents(suppliersData)
    } else {
      setSupplierData(suppliersData)
    }
  }, [idToken, suppliersData, isNotEmpty])

  return (
    <Autocomplete
      disablePortal
      id="supplier-dropdown"
      options={supplierData}
      fullWidth
      disabled={fetchingComponents || disable}
      // onBlur={event => {
      //   if (typeof handleError !== 'undefined') {
      //     handleError(event, 'supplier-select')
      //   }
      // }}
      // onClose={event => {
      //   if (typeof handleError !== 'undefined') {
      //     handleError(event, 'supplier-select')
      //   }
      // }}
      isOptionEqualToValue={(option, value) => option?.DisplayName === value?.DisplayName}
      getOptionLabel={option => option?.DisplayName}
      renderInput={params => {
        if (currentlyEditing) {
          return (
            <TextField
              value={value?.DisplayName}
              // error={error}
              label={value}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>{fetchingComponents ? <CircularProgress size={20} /> : params.InputProps.endAdornment}</>
                ),
              }}
            />
          )
        } else {
          return (
            <TextField
              value={value?.DisplayName}
              // error={error}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>{fetchingComponents ? <CircularProgress size={20} /> : params.InputProps.endAdornment}</>
                ),
              }}
            />
          )
        }
      }}
      onChange={event => {
        handleChange(event, id, index)
      }}
      size="small"
      sx={{ width: '173px' }}
    />
  )
})

export default SupplierDropdown
