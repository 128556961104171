import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
// import { Box } from '@mui/system'
import { useOktaAuth } from '@okta/okta-react'
import client from '../../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'

const CustomToolbar = tableName => {
  const name = tableName.tableName
  return (
    <Toolbar variant="dense" sx={{ backgroundColor: '#6A768C', color: '#FFFFFFDE' }}>
      {/* <Toolbar variant="dense" sx={{ backgroundColor: '#E0E0E0' }}> */}
      <Typography variant="h6" m="auto" id="tableTitle">
        {name}
      </Typography>
    </Toolbar>
  )
}

const StatsRepairsTable = () => {
  const { authState } = useOktaAuth()
  const { t } = useTranslation()
  const [robotCount, setRobotCount] = useState({})
  const [testbedCount, setTestbedCount] = useState({})
  const [robotLoading, setRobotLoading] = useState(true)
  const [testbedLoading, setTestbedLoading] = useState(true)

  // Get idToken from okta
  const idToken = authState.idToken.idToken

  useEffect(() => {
    const getData = async () => {
      const res = await client.get('/robot/count', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setRobotCount(res.data)
      setRobotLoading(false)
    }
    getData()
  }, [idToken])

  useEffect(() => {
    const getData = async () => {
      const res = await client.get('/testbed/count', {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      setTestbedCount(res.data)
      setTestbedLoading(false)
    }
    getData()
  }, [idToken])

  function statsTable(type) {
    let needsRepair = {}
    let underRepair = {}
    if (type === 'robot') {
      needsRepair = robotCount.needs_repair
      underRepair = robotCount.under_repair
    } else if (type === 'testbed') {
      needsRepair = testbedCount.needs_repair
      underRepair = testbedCount.under_repair
    }
    if ((type === 'robot' && robotLoading == true) || (type === 'testbed' && testbedLoading == true)) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress size={30} sx={{ m: '2em' }} />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
    return (
      <TableBody>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ fontWeight: 'bold' }}>{t('NeedsRepair')}</TableCell>
          <TableCell>{needsRepair ? needsRepair : 0}</TableCell>
        </TableRow>
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ fontWeight: 'bold' }}>{t('UnderRepair')}</TableCell>
          <TableCell>{underRepair ? underRepair : 0}</TableCell>
        </TableRow>
      </TableBody>
    )
  }

  return (
    <Stack direction="row" justifyContent="space-evenly" sx={{ my: 2 }}>
      <Paper sx={{ width: 'max-content', overflow: 'hidden', boxShadow: 0, border: '1px solid #E0E0E0' }}>
        <CustomToolbar tableName={t('Robots')} />
        <Table>{statsTable('robot')}</Table>
      </Paper>

      <Paper sx={{ width: 'max-content', overflow: 'hidden', boxShadow: 0, border: '1px solid #E0E0E0' }}>
        <CustomToolbar tableName={t('Testbeds')} />
        <Table>{statsTable('testbed')}</Table>
      </Paper>
    </Stack>
  )
}

export default StatsRepairsTable
