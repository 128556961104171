import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import './LoginWidget.css'

import React, { useEffect, useRef } from 'react'

import OktaSignIn from '@okta/okta-signin-widget'
import Store from '../../Store/Store'
import { config } from './oktaConfig'
import logo from '../../Icons/logo.svg'
import { observer } from 'mobx-react'
import { useOktaAuth } from '@okta/okta-react'
import { useTranslation } from 'react-i18next'

const Login = observer(() => {
  const { oktaAuth } = useOktaAuth()
  const widgetRef = useRef()
  const { i18n } = useTranslation()

  useEffect(() => {
    // If user got logged out, clear onboarding data
    Store.onboardingStore.clearPersistedStore()

    if (!widgetRef.current) {
      return false
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode, pkce } = config
    const widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: issuer.split('/oauth2')[0],
      clientId,
      redirectUri,
      language: i18n.language,
      logo: logo,
      i18n: {
        en: {
          'primaryauth.title': 'Sign In',
        },
      },
      authParams: {
        issuer: issuer, // 'https://irobot.okta.com',
        scopes,
        pkce,
      },
      useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
    })

    widget.renderEl(
      { el: widgetRef.current },
      res => {
        oktaAuth.handleLoginRedirect(res.tokens)
      },
      err => {
        throw err
      }
    )

    return () => widget.remove()
  }, [oktaAuth, i18n.language])

  return (
    <div id="okta-widget">
      <div ref={widgetRef} />
    </div>
  )
})

export default Login
