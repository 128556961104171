import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography, Paper, Grid, Button, Link, Divider, Stack, CircularProgress } from '@mui/material'
import './RobotDetail.css'
import { RobotDetailData } from '../../../Store/MOCK_DATA'
import { MockRobotComponents } from '../../../Store/MOCK_DATA'
import { useOktaAuth } from '@okta/okta-react'
import client from '../../../Config/axiosClient'
import { useTranslation } from 'react-i18next'
import Store from '../../../Store/Store'
import RobotComponents from './Tables/RobotComponents'
import RobotBitResults from './Tables/RobotBitResults'
import RobotBatteries from './Tables/RobotBatteries'
import { globalStatus } from '../../../Constants/global'
import RobotStatus from './Tables/RobotStatus'
import RobotHistory from './Tables/RobotHistory'
import RobotTests from './Tables/RobotTests'
import { useSnackbar } from 'notistack'

const RobotDetail = () => {
  const { authState } = useOktaAuth()
  const idToken = authState.idToken.idToken
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const rowData = Store.assetTracking.rowData
  const [fetching, setFetching] = useState(false)
  const [robotData, setRobotData] = useState([])
  const [statusHistory, setStatusHistory] = useState([])
  const [activeStatuses, setActiveStatuses] = useState([])
  const [tests, setTests] = useState([])
  const [testbedInfo, setTestbedInfo] = useState({})
  const [location, setLocation] = useState({})
  const { blid } = useParams()

  useEffect(() => {
    const getData = async () => {
      setFetching(true)
      let res
      if (blid && !rowData) {
        // If there's a blid but no row data,
        // the user is probably accessing the site using a handheld scanner.
        res = await client
          .get(`/robot/blid/${blid}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .catch(error => {
            enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
            throw new Error(error)
          })
      } else {
        // If there is rowData, the user is coming from the Track Robots view
        res = await client
          .get(`/robot/${rowData.robot_id}`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          })
          .catch(error => {
            enqueueSnackbar(t('DatabaseError'), { variant: 'error' })
            throw new Error(error)
          })
      }
      setRobotData(res.data[0])
      setTestbedInfo(res.data[0].Testbed[0])

      console.log("res.data")
      console.log(res.data)
      let statuses = []
      for (const item of res.data[0].Status) {
        const status = item.RobotStatus.Name
        const createdDate = item.RobotStatusProfile.CreatedDate
        const createdBy = item.User.Name
        statuses.push({
          status: status,
          createdDate: createdDate,
          createdBy: createdBy,
        })
      }
      const activeData = statuses.map((item, index) => {
        item.priority = index + 1
        return item
      })
      setActiveStatuses(activeData)

      let history = []
      for (const item of res.data[0].History) {
        console.log("item")
        console.log(item)
        const status = item.RobotStatus[0].Name
        const createdDate = item.CreatedDate
        let createdBy = ''
        if (item?.User) {
          createdBy = item.User ? item.User.find(o => o.PK_User === item.CreatedBy).Name : ''
        }
        const endDate = item.EndDate
        const endedBy = item.EndedBy ? item.User.find(o => o.PK_User === item.EndedBy).Name : ''
        history.push({
          status: status,
          createdDate: createdDate,
          createdBy: createdBy,
          endDate: endDate,
          endedBy: endedBy,
        })
      }
      setStatusHistory(history)

      let tests = []
      for (const item of res.data[0].Tests) {
        const test = item.Test[0].DisplayName
        const createdDate = item.CreatedDate
        let createdBy = ''
        if (item?.User) {
          createdBy = item.User ? item.User.find(o => o.PK_User === item.CreatedBy).Name : ''
        }
        const endDate = item.EndDate ? item.EndDate : ''
        const endedBy = item.EndedBy ? item.User.find(o => o.PK_User === item.EndedBy).Name : ''
        tests.push({
          test: test,
          createdDate: createdDate,
          createdBy: createdBy,
          endDate: endDate,
          endedBy: endedBy,
        })
      }
      setTests(tests)
      setFetching(false)

      if (res.data[0].Location.length == 0) {
        return
      }
      if (res.data[0].Location.length > 1) {
        enqueueSnackbar(t('RobotMultipleLocations'), { variant: 'error' })
        return
      }
      let location = {}
      location.workstation = res.data[0].Location[0].Workstation[0]
      location.rack = res.data[0].Location[0].Rack[0]
      location.shelf = res.data[0].Location[0].Shelf[0]
      location.name = `${location.workstation.Name}.${location.rack.Name}.${location.shelf.Name}`
      setLocation(location)
    }
    getData()
    console.log("rowData")
    console.log(rowData)
  }, [idToken, rowData, blid, enqueueSnackbar, t])

  // MOCK DATA
  let info = RobotDetailData[0]

  const goSwapBattery = () => {
    history.push('/app/testing/swap_battery', { from: '/app/robot/id' })
  }

  const goBitTest = () => {
    history.push('/app/testing/upload_bit', { from: '/app/robot/id' })
  }

  const goReportError = () => {
    // Error type can be 'robot', 'battery', or 'testbed'
    // Only using 'robot' for now
    const errorType = 'robot'
    Store.reportData.setErrorType(errorType)
    // let row = []
    // errorData.map(error => {
    //   if (error.type === errorType) {
    //     row = {
    //       title: `${error.title}`,
    //       description: `${error.description}`,
    //     }
    //     Store.reportData.setReportData(row)
    //   }
    // })

    history.push('/app/testing/error', { from: '/app/robot/id' })
  }

  const goRepair = robotName => {
    history.push('/app/repair_robot/' + robotName)
  }

  const goRetireRobot = selectedBlid => {
    history.push('/app/testing/retire_robot/' + selectedBlid)
  }

  const getStatusColor = value => {
    if (globalStatus.warning.values.robot.includes(value)) {
      return globalStatus.warning.themeColor
    }
    if (globalStatus.caution.values.robot.includes(value)) {
      return globalStatus.caution.themeColor
    }
    if (globalStatus.info.values.robot.includes(value)) {
      return globalStatus.info.themeColor
    }
    if (globalStatus.success.values.robot.includes(value)) {
      return globalStatus.success.themeColor
    }
    return 'black'
  }

  const fetchingTemplate = (
    <Box sx={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={30} sx={{ m: '2em' }} />
    </Box>
  )

  const testbedInformation = (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Name')}:
        </Typography>
        <Typography>{fetching ? '—' : testbedInfo?.DisplayName ? testbedInfo.DisplayName : '—'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Serial')}:
        </Typography>
        <Typography>{fetching ? '—' : testbedInfo?.TestbedSN ? testbedInfo.TestbedSN : '—'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Location')}:
        </Typography>
        <Typography>{fetching ? '—' : location?.name ? location.name : '—'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mr: '12px' }}>
          {t('Workstation')}:
        </Typography>
        <Typography>
          {fetching ? '—' : location?.workstation?.DisplayName ? location.workstation.DisplayName : '—'}
        </Typography>
      </Stack>
    </Stack>
  )

  return (
    <Stack spacing={4}>
      <Typography variant="h4" align="center" style={{ color: '#6CB86A' }} mb={-2.5}>
        {rowData.robotName}
      </Typography>

      <Paper sx={{ p: 2 }}>
        {/* Action Buttons */}
        <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button variant="contained" onClick={goSwapBattery}>
              {t('SwapBattery')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={goBitTest}>
              {t('BitTest')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={goReportError}>
              {t('ReportError')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => goRepair(rowData.robotName)}>
              {t('Repair')}
            </Button>
          </Grid>
          {/* <Grid item>
            <Button variant="contained" color="greyGreen">
              {t('AddonTests')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="alertPurple" onClick={() => goRetireRobot(rowData.blid)}>
              {t('Retire')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="alertPurple">
              {t('Removefromtestbed')}
            </Button>
          </Grid> */}
        </Grid>

        {/* Stats and Info */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 3,
            maxWidth: '100%',
            height: '120px',
            pl: '38px',
            pr: '10px',
          }}
        >
          <Grid container direction="column" justifyContent="space-around" alignItems="flex-start">
            <div>
              <Grid item sx={{ mr: '12px' }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {t('Serialnumber')}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="detail4">{robotData.RobotSN}</Typography>
              </Grid>
            </div>
            <div>
              <Grid item sx={{ mr: '12px' }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  {t('BLID')}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="detail4">{robotData.BLID}</Typography>
              </Grid>
            </div>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid container direction="column" justifyContent="space-around" alignItems="center">
            <div className="column-container">
              <div className="row-container">
                <Grid item sx={{ width: '52px', mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t('Status')}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" color={getStatusColor(rowData.status)}>
                    {t(rowData.status)}
                  </Typography>
                </Grid>
              </div>
              {/* <div className="row-container">
                <Grid item sx={{ width: '52px', mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t('Battery')}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">{info.battery}</Typography>
                </Grid>
              </div> */}
            </div>
          </Grid>
          {/* <Divider orientation="vertical" variant="middle" flexItem />
          <Grid container direction="column" justifyContent="space-around" alignItems="center">
            <div className="column-container">
              <div className="row-container">
                <Grid item sx={{ width: '69px', mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t('Missions')}:
                  </Typography>
                </Grid>
                <Grid item sx={{ mr: '12px' }}>
                  <Typography variant="subtitle2">{info.missions}</Typography>
                </Grid>
                <Grid item>
                  <Link variant="body2" color="#6CB86A" sx={{ cursor: 'pointer' }}>
                    {t('Missions')}
                  </Link>
                </Grid>
              </div>
              <div className="row-container">
                <Grid item sx={{ width: '69px', mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t('Errors')}:
                  </Typography>
                </Grid>
                <Grid item sx={{ mr: '12px' }}>
                  <Typography variant="subtitle2">{info.errors}</Typography>
                </Grid>
                <Grid item>
                  <Link variant="body2" color="#6CB86A" sx={{ cursor: 'pointer' }}>
                    {t('History')}
                  </Link>
                </Grid>
              </div>
              <div className="row-container">
                <Grid item sx={{ width: '69px', mr: '12px' }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                    {t('Runtime')}:
                  </Typography>
                </Grid>
                <Grid item sx={{ mr: '12px' }}>
                  <Typography variant="subtitle2">{info.runtime}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">{t('hours')}</Typography>
                </Grid>
              </div>
            </div>
          </Grid> */}
        </Box>
      </Paper>

      <Stack direction="row" spacing={4} justifyContent="space-evenly">
        {/* Active Statuses */}
        <Paper sx={{ p: 2, pt: 1 }}>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {t('ActiveStatuses')}
          </Typography>
          <RobotStatus rows={activeStatuses} fetching={fetching} />
        </Paper>

        {/* Tests */}
        <Paper sx={{ p: 2, pt: 1 }}>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {t('Tests')}
          </Typography>
          <RobotTests rows={tests} fetching={fetching} />
        </Paper>
      </Stack>

      {/* Components */}
      {/* <Paper sx={{ p: 2, pt: t }}>
        <Typography variant="h6" align="center" sx={{ mb: 1 }}>
          {t('Components')}
        </Typography>
        <RobotComponents rows={MockRobotComponents} fetching={fetching} />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button variant="contained">{t('Editcomponents')}</Button>
          <Button variant="contained" color="greyGreen">
            {t('AddonTests')}
          </Button>
        </Stack>
      </Paper> */}

      {/* Robot History */}
      <Paper sx={{ p: 2, pt: 1 }}>
        <Typography variant="h6" align="center" sx={{ mb: 1 }}>
          {t('ActivityHistory')}
        </Typography>
        <RobotHistory rows={statusHistory} fetching={fetching} />
      </Paper>

      <Stack direction="row" spacing={4} justifyContent="space-evenly">
        {/* BiT Results */}
        <Paper sx={{ p: 2, pt: 1 }}>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {t('BiTResults')}
          </Typography>
          {fetching ? fetchingTemplate : <RobotBitResults robot={robotData} />}
        </Paper>

        {/* Testbed Info */}
        <Paper sx={{ p: 2, pt: 1 }}>
          <Typography variant="h6" align="center" sx={{ mb: 1 }}>
            {t('TestbedInfo')}
          </Typography>
          {fetching ? fetchingTemplate : testbedInformation}
        </Paper>
      </Stack>

      {/* Battery History */}
      <Paper sx={{ p: 2, pt: 1 }}>
        <Typography variant="h6" align="center" sx={{ mb: 1 }}>
          {t('BatteryHistory')}
        </Typography>
        <RobotBatteries robot={robotData} />
      </Paper>
    </Stack>
  )
}

export default RobotDetail
