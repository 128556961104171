import { useState } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

const RobotComponents = observer(props => {
  const { t } = useTranslation()
  let rows = props.rows
  const fetching = props.fetching
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('')

  const columns = [
    {
      id: 'module',
      label: t('Module'),
      minWidth: 'max-content',
    },
    {
      id: 'component',
      label: t('Component'),
      minWidth: 'max-content',
    },
    {
      id: 'supplier',
      label: t('Supplier'),
      minWidth: 'max-content',
    },
    {
      id: 'serial',
      label: t('BuildSerialLot'),
      minWidth: 'max-content',
    },
  ]

  // Page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  // SORTING
  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const descendingComparator = (a, b, orderBy) => {
    if (a[orderBy] == b[orderBy]) {
      return 0
    }
    if (a[orderBy] == null) {
      return -1
    }
    if (b[orderBy] == null) {
      return 1
    }
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const fetchingTemplate = (
    <TableRow>
      <TableCell colSpan={columns.length}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={30} sx={{ m: '2em' }} />
        </Box>
      </TableCell>
    </TableRow>
  )

  const tableData = rows
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={`components-${index}`}>
          {columns.map(column => {
            let value = row[column.id]
            return <TableCell key={column.id}>{t(value)}</TableCell>
          })}
        </TableRow>
      )
    })

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  sortDirection={orderBy === column.id ? order : false}
                  key={`components-${column.id}`}
                  style={{ minWidth: column.minWidth }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{fetching ? fetchingTemplate : tableData}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
})

export default RobotComponents
